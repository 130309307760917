import { OpenClosedStates } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import React, { useContext } from 'react';
import { ToastContext, ToastTypes } from '../../../../contexts/toast.context';
import useFacilities from '../../../../hooks/useFacilities';
import Button, { ButtonColors } from '../../../common/Button';
import Modal from '../../../common/Modal';
import HealthcareProviderGeneralFormElements from './detail/HealthcareProviderGeneralFormElements';
import { useQueryClient } from 'react-query';
import { HealthcareProvider } from '@chiroup/core/types/HealthcareProvider.type';

const validation = {
  fname: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { fname, facility } = value;
        if (!fname && !facility) {
          return 'First name is required.';
        }
        return false;
      },
    },
  },
  lname: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { lname, facility } = value;
        if (!lname && !facility) {
          return 'Last name is required.';
        }
        return false;
      },
    },
  },
  zip: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { zip, country } = value;
        //Zip is not a required field here right now so...
        // if (!zip)
        //   return country === 'USA'
        //     ? 'Zip code is required.'
        //     : 'Postal code is required.';
        if (country === 'USA' && !!zip) {
          if (zip.length !== 5 && zip.length !== 10) {
            return 'Zip code must be 5 or 9 digits.';
          }
        }
        return false;
      },
    },
  },
  facilityName: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { facilityName, facility } = value;
        if (!facilityName && facility) {
          return 'Facility name is required.';
        }
        return false;
      },
    },
  },
  phone: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { phone } = value;
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
  fax: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { fax } = value;
        if (
          fax?.[1] === '1' &&
          fax?.split(' ')?.[0]?.length === 6 &&
          fax?.length < 13
        ) {
          return 'Fax number must be 7 digits.';
        } else if (fax?.[1] === '1' && fax?.length < 13) {
          return 'Fax number must be 10 digits.';
        }
        return false;
      },
    },
  },
  email: {
    function: {
      value: (value: Partial<HealthcareProvider>) => {
        const { email } = value;
        if (email && !email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
          return 'Email is invalid.';
        }
        return false;
      },
    },
  },
};

type Props = {
  state: OpenClosedStates;
  close: (val?: Partial<HealthcareProvider>) => void;
  onCreate?: () => void;
  providerOnly?: boolean;
};

const HealthcareProviderCreateModal: React.FC<Props> = ({
  state,
  close,
  onCreate,
  providerOnly = false,
}) => {
  const {
    value,
    registerSubmit,
    isDirty,
    errors,
    onChange,
    patchValue,
    reset,
    isSubmitting,
  } = useForm<HealthcareProvider>(
    { title: 'Dr', fname: '', lname: '' },
    validation,
  );
  const queryClient = useQueryClient();
  const { createToast } = useContext(ToastContext);
  const { createProvider } = useFacilities({ disableFetch: true });

  const onSubmit = async (provider: Partial<HealthcareProvider>) => {
    await createProvider(provider);
    if (!provider.facility) {
      queryClient.invalidateQueries('healthcareProviders');
    }
    return;
  };

  const onSuccess = (val: Partial<HealthcareProvider>) => {
    createToast({
      title: 'Success!',
      description: <>Successfully created healthcare provider/facility!</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
    reset();
    close(val);
    onCreate?.();
  };

  const onFail = () => {
    createToast({
      title: 'Error!',
      description: <>Failed created healthcare provider/facility!</>,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  return (
    <Modal isOpen={state === OpenClosedStates.Open} close={close}>
      <div className="w-full">
        <div className="flex w-full items-end justify-center text-center sm:block sm:p-0">
          <div
            className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom transition-all dark:bg-darkGray-800 sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 dark:bg-darkGray-600">
                <svg
                  className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                  id="modal-headline"
                >
                  {`Add healthcare provider${providerOnly ? `` : `/facility`}`}
                </h3>
                <div className="mt-2 text-left">
                  <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                    <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:py-5">
                      <HealthcareProviderGeneralFormElements
                        fieldErrors={errors.fieldErrors}
                        value={value}
                        onChange={onChange}
                        patchValue={patchValue}
                        formErrors={errors.form}
                        providerOnly={providerOnly}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <span className="flex w-full gap-x-4">
                <Button
                  text="Close"
                  onClick={() => close()}
                  fullWidth
                  color={ButtonColors.plain}
                  className="border border-gray-300 dark:border-darkGray-600"
                />
                <Button
                  text="Create"
                  onClick={registerSubmit(onSubmit, {
                    onSuccess,
                    onFail,
                  })}
                  fullWidth
                  loading={isSubmitting}
                  disabled={!isDirty}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HealthcareProviderCreateModal;
