import { PrinterIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import { classNames } from '@chiroup/core/functions/classNames';
import { UserRoles, HasRoleOptions } from '@chiroup/core/types/User.type';

type Props = {
  days: {
    day: string;
    dayName: string;
    fullDate: string;
    prop: string;
  }[];
  users: {
    id: string;
    name: string;
    fname: string;
    lname: string;
    profileImage: string | null;
    color?: string;
  }[];
  width: number;
  viewOneDay: (
    { id, name }: { id: string; name: string },
    {
      day,
      dayName,
      fullDate,
      prop,
    }: { day: string; dayName: string; fullDate: string; prop: string },
  ) => void;
  usersAndRooms: {
    id: string;
    name: string;
    fname: string;
    lname: string;
    profileImage: string | null;
    color?: string;
    isRoom?: boolean;
  }[];
};

const SchedulePaneDays: React.FC<Props> = ({
  days,
  width,
  viewOneDay,
  usersAndRooms,
}) => {
  const { me, hasRole } = useContext(MeContext);

  const userInitials = (name: string) => {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  };

  return (
    <div className="flex flex-auto border-b border-gray-300 sticky top-0 shadow bg-white z-20 dark:bg-darkGray-700">
      <div className="ring-1 ring-gray-300">
        <div className="w-14" />
      </div>
      {days.map((day) => (
        <div
          key={`day-${day.day}`}
          className="flex items-center justify-center flex-col"
        >
          <span className="flex border-r border-gray-300 w-full h-full items-center justify-center py-3">
            {day.dayName}
            <span
              className={
                dayjs().format('YYYY-MM-DD') === day.fullDate
                  ? 'ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white dark:text-gray-100'
                  : 'ml-1 flex h-8 items-center justify-center rounded-full bg-transparent font-semibold text-gray-900 dark:text-gray-100'
              }
            >
              {day.day}
            </span>
          </span>
          <div
            className={`flex flex-row flex-wrap border-t border-gray-300 text-sm leading-6 text-gray-500 w-full`}
          >
            {usersAndRooms.map((user, i) => {
              const canPrintDay =
                hasRole([
                  UserRoles.Admin,
                  UserRoles.Provider,
                  UserRoles.Staff,
                  UserRoles.ClinicalAssistant,
                ]) &&
                !(
                  hasRole([UserRoles.Provider], HasRoleOptions.HasSingleRole) &&
                  me?.ID !== user.id
                );
              const profileImage =
                user?.color || !user?.profileImage
                  ? undefined
                  : user.profileImage?.includes('https://')
                    ? user.profileImage
                    : `${import.meta.env.VITE_S3_ASSETS_URL}/${
                        user.profileImage
                      }`;

              const roomColor = user?.color;

              return (
                <div
                  className="relative"
                  key={`${user?.color ? 'room' : 'user'}-${user.id}`}
                >
                  <div
                    className={classNames(
                      'flex justify-center py-1 border-gray-300 text-left',
                      usersAndRooms.length - 1 === i ? 'border-r' : 'border-r',
                    )}
                    style={{
                      width,
                    }}
                  >
                    <div className="flex flex-row gap-2 items-center mx-1">
                      {profileImage ? (
                        <img
                          className={classNames(
                            'flex-none inline-block h-6 w-6 rounded-full ring-2 ring-gray-50 cursor-pointer',
                          )}
                          src={profileImage}
                          alt={user.name}
                        />
                      ) : roomColor ? (
                        <div
                          className={classNames(`bg-${roomColor}-400
                            ' flex-none inline-block h-6 w-6 rounded-full ring-2 ring-gray-50 cursor-pointer',
                          `)}
                        >
                          <div className="w-4 h-4 text-white rounded-full items-center flex justify-center text-xs"></div>
                        </div>
                      ) : (
                        <div className="flex-none bg-gray-300 rounded-full h-6 w-6 flex items-center justify-center">
                          <div className="w-4 h-4 text-white rounded-full items-center flex justify-center text-xs">
                            {userInitials(`${user.fname} ${user.lname}`)}
                          </div>
                        </div>
                      )}
                      <span className="text-xs dark:text-gray-300 leading-4">
                        {user.name}
                      </span>
                    </div>
                  </div>
                  {canPrintDay && (
                    <PrinterIcon
                      className="w-3 h-3 text-gray-300 hover:text-gray-900 absolute bottom-1 right-1 cursor-pointer"
                      onClick={() => {
                        viewOneDay(user, day);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SchedulePaneDays;
