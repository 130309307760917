import Button, { ButtonColors } from '../../common/Button';
import React from 'react';
import Modal from '../../common/Modal';
import { Checkbox } from '@chiroup/components';
import { formatPhone } from '@chiroup/core/functions/format';

type Props = {
  isOpen: boolean;
  close: () => void;
  existing?: any[];
  //   value: Partial<any>;
  //   onChange: (val: keyof any) => any;
};

const PatientMergeConfirmationModal: React.FC<Props> = ({
  isOpen,
  close,
  existing,
  //   value,
  //   onChange,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      disableClickOutsideClose
      className="inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
    >
      <div className="mt-3 text-center">
        <h3
          className="text-lg font-semibold pb-4 text-gray-900 dark:text-darkGray-100"
          id="modal-headline"
        >
          Merge Confirmation
        </h3>
      </div>
      <div>
        {existing?.map((patient, i) => (
          <div className="my-5 flex items-center gap-4" key={i}>
            {patient.$avatarBase64 ? (
              <img
                className="rounded-full h-14 w-14"
                src={`data:image/jpg;base64, ${patient.$avatarBase64}`}
                alt="avatar"
              />
            ) : (
              <svg
                className="flex-shrink-0 h-14 w-14 text-gray-300 dark:text-darkGray-200 group-hover:text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 16 16"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <div className="text-sm text-gray-500">
              <p className="font-medium text-gray-900">
                {patient.lname}, {patient.fname}
                <span className="font-light text-xs">
                  {patient.dateOfBirth ? ` (${patient.dateOfBirth})` : ''}
                </span>
              </p>
              <p>{patient.birthday}</p>
              <p>{patient.email}</p>
              <p>{formatPhone(patient.phone)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-flow-col-dense my-6 p-3">
        <Checkbox
          name="patientDemoSurveyCheckbox"
          className="pt-1 pr-0 mr-0"
          //   value={}
          //   onChange={}
        />
        <p>Send a Patient demographics survey to this patient?</p>
      </div>
      <div className="mt-6 grid gap-3 grid-flow-col-dense">
        <Button
          text="Cancel"
          onClick={close}
          fullWidth
          color={ButtonColors.plain}
          className={'border border-gray-300 dark:border-darkGray-600'}
        />
        <Button
          text="Confirm"
          //   onClick={}
          fullWidth
          color={ButtonColors.primary}
        />
      </div>
    </Modal>
  );
};

export default PatientMergeConfirmationModal;
