import {
  AddCodingDetails,
  Button,
  ButtonColors,
  Icon,
  Icons,
  Input,
} from '@chiroup/components';
import { useSortable } from '@dnd-kit/sortable';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import { TransactionContext } from '../../../../../../contexts/transaction.context';
import IconButton from '../../../../../common/IconButton';
import useDatabaseItem from '../../../../../settings/database/useDatabaseItem';
import { Tooltip } from 'react-tooltip';
import { TidyInputValue } from '@chiroup/core/constants/TidyInputValue';
import { classNames } from '@chiroup/core/functions/classNames';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';
import { PatientTransactionItemType } from '@chiroup/core/types/PatientTransaction.type';
import { ReferenceCodeDisplay } from '@chiroup/core/types/ReferenceCode.type';

type Props = {
  service: Partial<PatientTransactionItemType> & { replacementCode?: string };
  onChangeValue?: (
    index: number,
    val: PatientTransactionItemType | null,
  ) => void;
  index: number;
  readonly?: boolean;
  isBillingStarted?: boolean;
  isDuplicate?: boolean;
  onPromote: (
    e: ReferenceCodeDisplay,
    getRidOfService?: string,
  ) => Promise<void>;
  onChangeBillableStatus?: ({
    code,
    status,
  }: {
    code: string;
    status: boolean;
  }) => void;
  onChangeBilledAmount?: ({
    code,
    amount,
  }: {
    code: string;
    amount: string;
  }) => void;
};

const CodeEncounterService: React.FC<Props> = ({
  service,
  onChangeValue,
  index,
  readonly = false,
  isDuplicate,
  isBillingStarted = false,
  onChangeBillableStatus,
  onPromote,
  onChangeBilledAmount,
}) => {
  const uniqueComponentKey = useId();
  const { me } = useContext(MeContext);
  const { payors, insurances } = useContext(TransactionContext);
  const { attributes, listeners, setNodeRef, isDragging, isOver, active } =
    useSortable({
      id: String(service.code),
      data: {
        type: 'service',
      },
      transition: {
        duration: 150,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
    });
  const { isSaving, save } = useDatabaseItem({
    instanceKey: 'billing-codes',
    id: 'add',
  });
  const [amountThere, setAmountThere] = React.useState<boolean>(
    Boolean(
      service &&
        (service.id || (service as any).ID) &&
        service.amount &&
        !service.variableBilledAmount,
    ),
  );

  const modifierInputClassName = 'px-2 py-1',
    modifierClassName = 'w-12';

  useEffect(() => {
    if (!readonly) return;
    setAmountThere(
      Boolean(
        service &&
          !service.variableBilledAmount &&
          (service.id || (service as any).ID) &&
          service.amount !== '0.00',
      ),
    );
  }, [service, readonly]);

  const onPromoteDiag = (e: any) => {
    const diagnoses = [...(service.diagnoses || [])];
    diagnoses.push(e);
    const nobj: any = { ...service };
    nobj.diagnoses = diagnoses;
    onChangeValue?.(index, nobj);
  };

  const updateValue = (e: any, prop: string) => {
    const nobj: any = { ...service };
    if (prop === 'diagnoses') {
      nobj[prop] = e;
    } else if (prop === 'amount') {
      nobj[prop] = TidyInputValue.Currency(e);
    } else if (prop === 'units') {
      nobj[prop] = Number.parseInt(e);
    } else if (prop === 'insuranceBillable') {
      nobj[prop] = e;
    } else {
      nobj[prop] = TidyInputValue.BillingCodeModifier(e);
    }
    onChangeValue?.(index, nobj);
  };

  /**
   * Making this a string is more user-friendly.
   */
  if (typeof service.amount === 'number') {
    service.amount = '' + service.amount.toFixed(2);
  }
  if (typeof service.units !== 'number') {
    service.units = 1;
  }

  const handleDeleteService = useCallback(() => {
    if (readonly) return;
    return onChangeValue?.(index, null);
  }, [onChangeValue, index, readonly]);

  const rememberService = async () => {
    let resp: any = null;
    try {
      resp = await save({
        codeSet: service.codeSet,
        code: service.code,
        codeShortcut: null,
        description: service.description,
        billedAmount: service.amount,
        defaultModifiers: [
          service.modifier1,
          service.modifier2,
          service.modifier3,
          service.modifier4,
        ],
        insuranceBillable: service.insuranceBillable || false,
      });
      const newObj: any = { ...service };
      ['ID', 'clinicID', 'codeSet', 'code', 'description'].forEach((prop) => {
        newObj[prop] = resp[prop];
      });
      newObj.amount = resp.billedAmount;
      newObj.modifier1 = resp.defaultModifiers?.[0] || null;
      newObj.modifier2 = resp.defaultModifiers?.[1] || null;
      newObj.modifier3 = resp.defaultModifiers?.[2] || null;
      newObj.modifier4 = resp.defaultModifiers?.[3] || null;
      newObj.insuranceBillable = service.insuranceBillable || false;

      onChangeValue?.(index, newObj);
    } catch (e) {
      console.error({ e });
    }
  };
  const [isShowing, setIsShowing] = useState(false);

  const insurancesWithReplacementCodes = useMemo(() => {
    if (!insurances?.length || !payors?.length) return [];
    const replacementCodeInfo = payors.filter((payor) => {
      return (
        service.code === payor.code &&
        payor.replacementCode &&
        payor.code !== payor.replacementCode
      );
    });
    if (!replacementCodeInfo.length) return [];
    const replacementCodeObj = replacementCodeInfo.reduce((obj, payor) => {
      const payorId = payor.payorId || payor.payorID;
      const insurance = insurances.find(
        (insurance) => insurance.payorID === payorId,
      );
      if (insurance?.insuranceName) {
        obj[`${payorId}|${payor.code}`] = {
          payorId,
          replacementCode: payor.replacementCode,
          payorName: insurance?.insuranceName,
        };
      }
      return obj;
    }, {});
    return Object.values(replacementCodeObj) as {
      payorId: number;
      replacementCode: string;
      payorName: string;
    }[];
  }, [insurances, payors, service]);

  const replaceCode = useCallback(
    async ({ newCode }: { newCode: string }) => {
      await onPromote(
        {
          codeSet: service.codeSet as CodeSets,
          code: newCode,
          description: '' as string,
        },
        service.code || '',
      );
    },
    [onPromote, service.codeSet, service.code],
  );

  return (
    <div
      key={uniqueComponentKey}
      className={classNames(
        readonly || service?.locked ? 'cursor-not-allowed' : '',
        'relative rounded-md border border-gray-300 ring-4 ring-offset-1 bg-white mb-3',
        isDragging ? 'opacity-50' : '',
        isOver && active?.data.current?.type === 'diagnosis'
          ? isBillingStarted
            ? 'ring-accent-600'
            : 'ring-primary-600'
          : 'ring-transparent',
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      onClick={
        readonly
          ? () => {
              setIsShowing(true);
              setTimeout(() => {
                setIsShowing(false);
              }, 300);
            }
          : undefined
      }
    >
      {readonly && (
        <Transition
          className="absolute bottom-1 right-2 text-gray-300 font-medium text-2xl"
          show={isShowing}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {`Service is ${
            service?.locked ? 'locked' : readonly ? 'readonly' : ''
          }.`}
        </Transition>
      )}
      <div className="rounded-t-md px-4 sm:grid sm:grid-cols-6 sm:gap-x-2 sm:px-0 bg-white text-gray-900 pt-2 border-b border-gray-300">
        <div className="ml-2 text-sm font-medium leading-6 sm:col-span-1">
          <div className="flex flex-row">
            {service.code?.trim()}
            {!isDuplicate ? (
              <IconButton
                className={classNames(
                  'ml-0.5 h-5 w-5',
                  service.insuranceBillable
                    ? 'text-primary-500'
                    : 'text-gray-400',
                  readonly ? 'cursor-not-allowed' : 'cursor-pointer',
                )}
                icon={<CheckCircleIcon />}
                disabled={readonly}
                tooltip={[
                  service.insuranceBillable
                    ? `This service is billable to insurance.`
                    : `This service is not billable to insurance.`,
                  readonly ? '' : 'Click to toggle.',
                ].join(' ')}
                onClick={() => {
                  if (readonly) return;
                  onChangeBillableStatus?.({
                    code: service.code as string,
                    status: !service.insuranceBillable as boolean,
                  });
                }}
              />
            ) : (
              //Need this to update all duplicates as well
              <IconButton
                className={classNames(
                  'ml-0.5 h-5 w-5',
                  service.insuranceBillable
                    ? 'text-primary-500'
                    : 'text-gray-400',
                  'cursor-not-allowed',
                )}
                icon={<CheckCircleIcon />}
                disabled={true}
                tooltip={
                  'This service is duplicated and will have the same billable status as the original.'
                }
              />
            )}
          </div>
          {isDuplicate && (
            <ExclamationTriangleIcon
              className="h-5 w-5 text-accent-600"
              data-tooltip-content={
                'This code is duplicated in the transaction.'
              }
              data-tooltip-id="tt-duplicate"
              data-tooltip-class-name="text-xs"
            />
          )}
        </div>
        <div className="ml-4 mt-1 flex text-sm leading-6 sm:col-span-5 sm:mt-0 mr-4 items-center pb-2">
          <span className="flex-grow">{service.description}</span>
          <span className="ml-4 flex-shrink-0 text-gray-900">
            <Input
              name="units"
              label=""
              value={service.units}
              onChange={(e) => {
                updateValue(e, 'units');
              }}
              type="number"
              min="1"
              max="100"
              inputTitle="The number of units of this service performed."
              disabled={readonly}
              inputClassName="p-0 pl-4"
            />
          </span>
          {!readonly && (
            <span
              className="ml-4 flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-900"
              onClick={handleDeleteService}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </span>
          )}
        </div>
        {!!insurancesWithReplacementCodes?.length && (
          <div className="sm:col-span-6 border-t border-gray-300 bg-accent-100 divide-y divide-gray-300 text-sm">
            {insurancesWithReplacementCodes.map((info, idx) => (
              <div
                className="flex flex-row justify-between items-center p-2"
                key={`iwrc-${idx}`}
              >
                <span>
                  A replacement code for {info.payorName} is set (
                  {info.replacementCode}).
                </span>
                {!readonly && (
                  <Icon
                    icon={Icons.switch}
                    className="text-gray-700 hover:text-gray-500"
                    onClick={(e) =>
                      replaceCode({
                        newCode: info.replacementCode,
                      })
                    }
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-row mx-2">
        <div>
          <Input
            name="amount"
            label="Billed Amount"
            value={service.amount}
            onChange={(e) => {
              onChangeBilledAmount?.({
                code: service.code as string,
                amount: e,
              });
            }}
            disabled={readonly || amountThere || isDuplicate}
            inputTitle={
              isDuplicate
                ? `This is a duplicated service. The amount is set by the original service.`
                : `The amount billed for this service. A value is required.`
            }
            className="w-24"
            inputClassName="px-2 py-1"
          />
        </div>
        <div className="ml-4 mt-2 text-sm">
          <div>Modifiers</div>
          <div className="flex flex-row space-x-2">
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier1"
                value={service.modifier1}
                onChange={(e) => {
                  updateValue(e, 'modifier1');
                }}
                disabled={readonly}
              />
            </div>
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier2"
                value={service.modifier2}
                onChange={(e) => {
                  updateValue(e, 'modifier2');
                }}
                disabled={readonly}
              />
            </div>
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier3"
                value={service.modifier3}
                onChange={(e) => {
                  updateValue(e, 'modifier3');
                }}
                disabled={readonly}
              />
            </div>
            <div>
              <Input
                label=""
                className={modifierClassName}
                inputClassName={modifierInputClassName}
                name="modifier4"
                onChange={(e) => {
                  updateValue(e, 'modifier4');
                }}
                value={service.modifier4}
                disabled={readonly}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 px-2">
        {service?.diagnoses?.length === 0 && readonly ? (
          <div className="text-sm text-gray-400">
            <cite>No assigned diagnoses!</cite>
          </div>
        ) : (
          <h3
            className={
              isBillingStarted ? 'text-accent-600' : 'text-primary-600'
            }
          >
            {(service?.diagnoses?.length || 0) === 1
              ? `Diagnosis`
              : `Diagnoses`}
          </h3>
        )}
      </div>{' '}
      <div className="px-2 cursor-default">
        {service &&
          service.diagnoses &&
          service.diagnoses.length > 0 &&
          service.diagnoses.map((diag, i) => (
            <div key={i} className="">
              <dl className="divide-y divide-gray-100">
                <div className="px-0 sm:grid sm:grid-cols-12 sm:gap-1 sm:px-0">
                  <dt className="cursor-default ml-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">
                    {diag.code}
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-9 sm:mt-0 mr-2">
                    <span className="flex-grow">{diag.description}</span>
                    {!readonly && (
                      <span
                        className=" cursor-pointer ml-4 flex-shrink-0"
                        onClick={(e: any) => {
                          if (readonly) return;
                          const items = [...(service.diagnoses || [])];
                          items.splice(i, 1);
                          updateValue(items, 'diagnoses');
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.0}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </span>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          ))}
      </div>
      {!readonly && (service?.diagnoses?.length || 0) < 4 ? (
        <div>
          <AddCodingDetails
            value={service}
            valueProp="diagnoses"
            codeSet={[CodeSets.DIAGNOSTIC, CodeSets.ICD10CM]}
            noCodesMessage="No diagnoses have been associated with this service."
            noneText="Type to search for diagnostic codes..."
            onPromote={onPromoteDiag}
            promoteTitle="Add this diagnosis to the service."
            repeatTitle="This diagnosis is already associated with the service."
            clinicId={me?.selectedClinic?.ID as number}
            isBillingStarted={isBillingStarted}
          />
        </div>
      ) : (
        <div className="p-0 -m-2">&nbsp;</div>
      )}
      {/* <div>
        <pre>
          {JSON.stringify(
            {
              service,
              isSaving,
              editBilledAmount,
            },
            null,
            2
          )}
        </pre>
      </div> */}
      {readonly && <br />}
      {!amountThere &&
        service.amount &&
        !service.variableBilledAmount &&
        !readonly && (
          <div className="flex justify-end m-4 cursor-pointer">
            {' '}
            <Button
              text="Remember"
              onClick={rememberService}
              tooltip="This service code is not currently saved in your clinic-specific billing codes. Click the button to save this as a new custom code."
              tooltipClassName="w-96 text-left text-sm"
              loading={isSaving}
              color={
                isBillingStarted ? ButtonColors.accent : ButtonColors.primary
              }
            />
          </div>
        )}
      {/* <pre>{ChiroUpJSON.pretty(service)}</pre> */}
      <Tooltip id={'tt-duplicate'} />
    </div>
  );
};

export default CodeEncounterService;
