import {
  Checkbox,
  Input,
  InputMasked,
  Select,
  ZipCodeInput,
} from '@chiroup/components';
import { SuffixOptions } from '@chiroup/core/constants/Suffixes';
import {
  CANADIAN_PROVINCES,
  STATES,
} from '@chiroup/core/constants/UsStatesAndTerritories';
import { enumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import {
  Insurance,
  InsuranceSexTypes,
} from '@chiroup/core/types/PatientInsurance.type';
import React from 'react';
import { COUNTRIES } from '../../../../../constants';
import SectionContainer from '../../../../layout/SectionContainer';
import SectionHeader from '../../../../layout/SectionHeader';

type Props = {
  value: Partial<Insurance>;
  patchValue: (values: Partial<Insurance>) => void;
};

const PrimaryInsuredFormFields: React.FC<Props> = ({ value, patchValue }) => {
  const setNestedValue = (
    originalValue: Partial<Insurance>,
    keyPath: string,
    newValue: any,
  ): Partial<Insurance> => {
    const keys = keyPath.split('.');
    const obj: Partial<Insurance> = JSON.parse(JSON.stringify(originalValue));
    let current: Partial<Insurance> | any = obj;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i === keys.length - 1) {
        current[key] = newValue;
      } else {
        current[key] = current[key] || {};
      }
      current = current[key];
    }

    return obj;
  };
  const handleSameasPatientChange = (e: boolean) => {
    let updatedValue = { ...value };

    if (e) {
      const fieldsToUpdate = [
        'streetAddress',
        'city',
        'state',
        'postalCode',
        'country',
      ];
      updatedValue = fieldsToUpdate.reduce((acc, key) => {
        return setNestedValue(acc, `primaryInsured.${key}`, '');
      }, updatedValue);
    }

    updatedValue = setNestedValue(
      updatedValue,
      `primaryInsured.addressSameAsPatient`,
      e,
    );

    patchValue(updatedValue);
  };

  const handleNestedChange = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement> | boolean | string,
  ) => {
    const updatedValue = setNestedValue(value, `primaryInsured.${key}`, e);
    patchValue(updatedValue);
  };
  return (
    <div className="col-span-4 border rounded-md">
      <SectionContainer parentClassName="P-2">
        <SectionHeader
          title={
            <p className="text-base font-light text-gray-600">
              Primary Insured
            </p>
          }
        />
        <div className="p-4 space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
          <Input
            name="primaryInsuredFname"
            className="col-span-2"
            label="First Name"
            value={value?.primaryInsured?.fname}
            onChange={(e) => handleNestedChange('fname', e)}
          />
          <Input
            name="primaryInsuredMname"
            className="col-span-2"
            label="Middle Name"
            value={value?.primaryInsured?.mname}
            onChange={(e) => handleNestedChange('mname', e)}
          />
          <Input
            name="pimaryInsuredLname"
            className="col-span-2"
            label="Last Name"
            value={value?.primaryInsured?.lname}
            onChange={(e) => handleNestedChange('lname', e)}
          />
          <Select
            name="suffix"
            className="col-span-2"
            label="Suffix"
            options={SuffixOptions}
            onChange={(e) => handleNestedChange('suffix', e)}
            value={value?.primaryInsured?.suffix}
            limit={1}
          />
          <InputMasked
            className="col-span-2"
            value={value?.primaryInsured?.dateOfBirth}
            onChange={(e) => handleNestedChange('dateOfBirth', e)}
            name="dateOfBirth"
            label="Date of birth"
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
          <Select
            name="sex"
            label="Sex"
            className="col-span-2"
            value={value?.primaryInsured?.sex}
            options={enumToArrayOfOptions(InsuranceSexTypes)}
            limit={1}
            onChange={(e) => handleNestedChange('sex', e)}
          />
          <Checkbox
            name="addressSameAsPatient"
            label="Address same as patient"
            className="col-span-4 pt-2"
            value={value?.primaryInsured?.addressSameAsPatient}
            onChange={handleSameasPatientChange}
          />
          {!value?.primaryInsured?.addressSameAsPatient && (
            <>
              <Input
                name="streetAddress"
                className="col-span-2"
                label="Street Address"
                value={value?.primaryInsured?.streetAddress}
                onChange={(e) => handleNestedChange('streetAddress', e)}
              />
              <Input
                name="address2"
                className="col-span-2"
                label="Address cont."
                value={value?.primaryInsured?.address2}
                onChange={(e) => handleNestedChange('address2', e)}
              />
              <Input
                name="city"
                label="City"
                value={value?.primaryInsured?.city}
                onChange={(e) => handleNestedChange('city', e)}
                className="col-span-2"
              />
              {/* <Input
                name="state"
                label="State"
                value={value?.primaryInsured?.state}
                onChange={(e) => handleNestedChange('state', e)}
                className="col-span-2"
              /> */}
              <Select
                name="state"
                className="col-span-2"
                label={`${
                  value?.primaryInsured?.state === 'CAN' ? 'Province' : 'State'
                }`}
                value={value?.primaryInsured?.state}
                onChange={(e) => handleNestedChange('state', e)}
                options={
                  value?.primaryInsured?.state === 'CAN'
                    ? CANADIAN_PROVINCES
                    : STATES
                }
                limit={1}
                autocomplete
              />
              <ZipCodeInput
                name="zip"
                label="Zip/Postal Code"
                className="col-span-2"
                value={value?.primaryInsured?.postalCode}
                onChange={(e) => handleNestedChange('postalCode', e)}
                country={value?.primaryInsured?.country ?? ''}
              />
              <Select
                name="country"
                label="Country"
                className="col-span-2"
                value={value?.primaryInsured?.country ?? ''}
                onChange={(e) => handleNestedChange('country', e)}
                options={COUNTRIES}
                limit={1}
              />
            </>
          )}
        </div>
      </SectionContainer>
    </div>
  );
};

export default PrimaryInsuredFormFields;
