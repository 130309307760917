import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { signOut } from '@aws-amplify/auth';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { classNames } from '@chiroup/core/functions/classNames';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { UserRoles } from '@chiroup/core/types/User.type';

/**
 * Create pretty location display for a location (by ID) given that we want to display only the city and state
 * if the city/state is unique for the given list of locations, but also include address1 and address2 where
 * necessary to correctly identify the location that is selected.
 **/
export const prettyLocationDisplay = ({
  ID,
  name,
  address1,
  address2,
  city,
  state,
  allLocations,
  textOnly = false,
}: {
  ID?: number;
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  allLocations?: ClinicLocation[];
  textOnly?: boolean;
}) => {
  if (!name && !city && !state) {
    return '';
  }
  const cityState = `${city}, ${state}`;
  const otherLocationsWithCityState = allLocations?.filter(
    (loc) => loc.city === city && loc.state === state && loc.ID !== ID,
  );

  if (otherLocationsWithCityState?.length) {
    return textOnly ? (
      `
      ${name ? `${name} ` : ''}${address1 || ''} ${address2 || ''} ${cityState}
    `
    ) : (
      <>
        {name ? (
          <>
            {name}
            <br />
          </>
        ) : (
          ''
        )}
        {address1 || ''} {address2 || ''}
        <br />
        {cityState}
      </>
    );
  }

  return name || cityState;
};

type Props = {
  selectedClinic?: string;
  multiClinic: boolean;
  unselectClinic: () => void;
  close: () => void;
  switchLocations: () => void;
};

const UserDropdownList: React.FC<Props> = ({
  selectedClinic,
  multiClinic,
  unselectClinic,
  close,
  switchLocations,
}) => {
  const { activeLocations, selectedLocationFull, hasAccess, hasRole } =
    useContext(MeContext);
  const logout = () => {
    signOut();
  };

  /**
   * Broke this out so it was not duplicated so many places.
   */
  const routeLinkClasses =
    'block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 dark:hover:bg-darkGray-800 dark:text-darkGray-200 dark:hover:text-darkGray-50 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out';

  return (
    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
      <div
        className="py-1 rounded-md bg-white dark:bg-darkGray-700 ring-1 ring-black ring-opacity-5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        <div className="px-4 py-3 flex justify-between">
          <div className="flex-initial w-40 text-sm">
            <div
              className="absolute left-0 top-0 w-2 h-2 cursor-pointer"
              onClick={() => {
                throw new Error('test14');
              }}
            />
            <p>Signed in to</p>
            <p className="font-medium text-gray-900 dark:text-darkGray-50 truncate flex flex-col">
              {prettyLocationDisplay(selectedLocationFull)}
              {activeLocations?.length > 1 && (
                <div
                  onClick={switchLocations}
                  className="text-primary-600 hover:text-primary-400 dark:text-darkGray-400 dark:hover:text-darkGray-50  cursor-pointer leading-4 text-xs"
                >
                  Change location
                </div>
              )}
            </p>
          </div>
          {multiClinic && (
            <div className="flex-initial h-full">
              <svg
                className="text-primary-600 hover:text-primary-400 h-4 w-4 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                onClick={unselectClinic}
              >
                <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
              </svg>
            </div>
          )}
        </div>
        <div className="border-t border-gray-100 dark:border-darkGray-600" />
        {hasRole([UserRoles.Admin, UserRoles.BusinessManager]) && (
          <Link
            to="/settings/subscription"
            className={routeLinkClasses}
            role="menuitem"
            onClick={close}
          >
            Subscription
          </Link>
        )}
        <Link
          to="/settings/user"
          className={routeLinkClasses}
          role="menuitem"
          onClick={close}
        >
          User settings
        </Link>
        {hasRole([
          UserRoles.Admin,
          UserRoles.Staff,
          UserRoles.ContentDatabaseEditor,
        ]) && (
          <Link
            to="/settings/clinic"
            className={routeLinkClasses}
            role="menuitem"
            onClick={close}
          >
            Clinic settings
          </Link>
        )}
        {hasAccess(FeatureFlags.clinicDatabases) &&
          hasRole([
            UserRoles.Admin,
            UserRoles.Provider,
            UserRoles.Biller,
            UserRoles.ContentDatabaseEditor,
          ]) && (
            <Link
              to="/settings/database"
              className={routeLinkClasses}
              role="menuitem"
              onClick={close}
            >
              Databases
            </Link>
          )}

        <div className="border-t border-gray-100 dark:border-darkGray-600" />
        <div
          onClick={() => {
            close();
            logout();
          }}
          className={classNames(
            routeLinkClasses,
            'cursor-pointer flex flex-row justify-between',
          )}
          role="menuitem"
        >
          <div>Logout</div>
          <div className="text-gray-300 text-xs">
            {import.meta.env.VITE_VERSION}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDropdownList;
