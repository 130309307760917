import { InputMasked } from '@chiroup/components';
import { PatientTransactionItemType } from '@chiroup/core/types/PatientTransaction.type';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  service: PatientTransactionItemType;
  selectedInsuranceServiceAmount?: number;
  onChangeSelectedInsuranceServiceAmount: (
    amount: number,
    service: PatientTransactionItemType,
  ) => void;
  readonly: boolean;
};

export const formatCurrency = (value: any) => {
  const numberValue = Number(value);
  return isNaN(numberValue) ? '0.00' : numberValue.toFixed(2);
};

const EncounterInsuranceService: React.FC<Props> = ({
  service,
  selectedInsuranceServiceAmount,
  onChangeSelectedInsuranceServiceAmount,
  readonly,
}) => {
  const calculateContractualAdjustment = (
    allowed: number,
    billed: number,
    units: number,
  ) => (allowed > 0 && billed > allowed ? (billed - allowed) * units : 0);

  const calculateBilledAmount = useCallback(
    (allowedAmount = 0, service: PatientTransactionItemType) => {
      const allowed = Number(allowedAmount);
      const billed = Number(service?.amount ?? 0);
      const units = Number(service?.units ?? 0);

      const total = allowed > 0 ? allowed * units : billed * units;
      const contractualAdjustment = calculateContractualAdjustment(
        allowed,
        billed,
        units,
      );

      return {
        total: formatCurrency(total),
        contractualAdjustment: formatCurrency(contractualAdjustment),
      };
    },
    [],
  );

  const [billedAmount, setBilledAmount] = useState(() =>
    calculateBilledAmount(selectedInsuranceServiceAmount, service),
  );

  useEffect(() => {
    if (service) {
      setBilledAmount(
        calculateBilledAmount(selectedInsuranceServiceAmount, service),
      );
    }
  }, [calculateBilledAmount, service, selectedInsuranceServiceAmount]);

  const handleAllowedAmountChange = (e: string) => {
    const amount = Number(e);
    if (amount > Number(service?.amount ?? 0)) {
      onChangeSelectedInsuranceServiceAmount(
        Number(service?.amount ?? 0),
        service,
      );
      const newBilledAmount = calculateBilledAmount(
        Number(service?.amount ?? 0),
        service,
      );
      setBilledAmount(newBilledAmount);
    } else {
      onChangeSelectedInsuranceServiceAmount(amount, service);
      const newBilledAmount = calculateBilledAmount(amount, service);
      setBilledAmount(newBilledAmount);
    }
  };

  return (
    <div className="text-sm grid grid-cols-2 gap-6">
      <h3 className="col-span-2 font-medium">{`Description: ${service.description}`}</h3>
      <div className="flex flex-col w-full col-span-1">
        <p className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2">
          Billed Amount Per Unit:
        </p>
        <p>{`$${formatCurrency(Number(service?.amount ?? 0))}`}</p>
        <InputMasked
          label="Allowed Amount Per Unit:"
          name="allowed"
          placeholder="###.##"
          value={selectedInsuranceServiceAmount}
          onChange={handleAllowedAmountChange}
          numericOptions={{
            decimalScale: 2,
            fixedDecimalScale: true,
          }}
          disabled={readonly || !service.amount || service.amount === '0.00'}
          hint="Must not be greater than billed amount."
        />
      </div>
      <div className="flex flex-col w-full col-span-1">
        <p className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2">
          Total Billed:
        </p>
        <p>{`$${billedAmount.total}`}</p>
      </div>
      <div className="flex flex-col w-full col-span-1">
        <p className="block text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2">
          Contractual Adjustment:
        </p>
        <p>{`$${billedAmount.contractualAdjustment}`}</p>
      </div>
    </div>
  );
};

export default EncounterInsuranceService;
