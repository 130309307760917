import { Room } from '@chiroup/core/types/Room.type';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { MeContext } from '../contexts/me.context';
import clinicService from '../services/clinic.service';

const getQuery = (
  clinicId?: number,
  locationId?: number,
  hasAccess?: boolean,
) => {
  return () => {
    if (!clinicId || !hasAccess) {
      return [];
    }

    return clinicService.getLocationRooms(clinicId, locationId);
  };
};

type Props = {
  clinicId?: number;
  locationId?: number;
};

const useLocationRooms = ({ clinicId, locationId }: Props) => {
  const { hasAccess } = useContext(MeContext);
  const { data, isFetching, refetch } = useQuery<Room[]>(
    ['locationRoom', clinicId, locationId],
    getQuery(clinicId, locationId, hasAccess('scheduling')),
    {
      refetchOnWindowFocus: false,
    },
  );

  const save = async (room: Partial<Room>) => {
    const res = await clinicService.updateLocationRoom({
      clinicId: clinicId as number,
      locationId: locationId as number,
      room,
    });
    refetch();
    return res;
  };

  return {
    isFetching,
    rooms: data,
    save,
  };
};

export default useLocationRooms;
