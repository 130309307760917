import { OpenClosedStates, Select, SelectOption } from '@chiroup/components';
import useHealthcareProvidersAll from '../../../../hooks/useHealthcareProvidersAll';
import React, { useEffect, useState } from 'react';
import { Portal } from '../../Portal';
import HealthcareProviderCreateModal from '../../../settings/database/healthcare-providers/HealthcareProviderCreateModal';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { HealthcareProvider } from '@chiroup/core/types/HealthcareProvider.type';

type Props = {
  name: string;
  value?: number | null;
  onChange: (val: number) => void;
  onChangeFull?: (val: any) => void;
  errors?: FormError;
  className?: string;
  label?: string;
  showLabel?: boolean;
  disabled?: boolean;
  hint?: string;
  disableAdd?: boolean;
};

const HealthcareProviderSelect: React.FC<Props> = ({
  name,
  value,
  onChange,
  onChangeFull,
  errors,
  className = 'col-span-2 sm:col-span-1',
  label = 'Healthcare provider',
  showLabel = true,
  disabled,
  hint,
  disableAdd,
}) => {
  const { data, addProvider } = useHealthcareProvidersAll();
  const [state, setState] = useState<OpenClosedStates>(OpenClosedStates.Closed);
  const add = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (!disabled) {
      event.stopPropagation();
      setState(OpenClosedStates.Open);
    }
  };
  const [existingProviderValue, setExistingProviderValue] = useState<
    number | null
  >();
  const [searchOptions, setSearchOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const options = [
      {
        text: 'None',
        value: null,
      },
      ...(data?.data || []).map((prov: HealthcareProvider) => ({
        value: prov.ID,
        text: prov?.lastFirst || '',
      })),
    ];
    setSearchOptions(options);
  }, [data]);

  useEffect(() => {
    const fullProvider = data?.data?.find((item) => item.ID === value);
    if (onChangeFull) {
      onChangeFull(fullProvider);
    }
    if (fullProvider?.fname) {
      setExistingProviderValue(value);
    } else {
      setExistingProviderValue(undefined);
    }
  }, [value, data, onChangeFull]);

  const close = (healthcareProvider?: Partial<HealthcareProvider>) => {
    if (healthcareProvider?.ID) {
      addProvider(healthcareProvider);
      onChange(healthcareProvider.ID);
    }
    setState(OpenClosedStates.Closed);
  };

  return (
    <>
      <Select
        name={name}
        className={className}
        showLabel={showLabel}
        label={label}
        value={existingProviderValue}
        onChange={onChange}
        errors={errors}
        options={searchOptions}
        limit={1}
        add={disableAdd ? undefined : add}
        autocomplete
        disabled={disabled}
        hint={hint}
      />
      {!disableAdd && (
        <Portal>
          <HealthcareProviderCreateModal
            state={state}
            close={close}
            providerOnly
          />
        </Portal>
      )}
    </>
  );
};

export default HealthcareProviderSelect;
