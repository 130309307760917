import React from 'react';
import ScheduleEventsUser from './ScheduleEventsUser';
import {
  Appointments,
  AvailableSlotsResponse,
  AppointmentForUI,
} from '@chiroup/core/types/Appointment.type';
import { DisciplineTreatment } from '@chiroup/core/types/Discipline.type';

type Props = {
  appointments: Appointments;
  days: {
    day: string;
    dayName: string;
    fullDate: string;
    prop: string;
  }[];
  dragging: boolean;
  width: number;
  availableSlots: AvailableSlotsResponse | null;
  selectedTreatment: DisciplineTreatment | null;
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  scheduleApptFromDoubleClick: (
    clinician: string,
    startTime: number,
    date: string,
  ) => void;
  usersAndRooms: {
    id: string;
    name: string;
    fname: string;
    lname: string;
    profileImage: string | null;
    color?: string;
    isRoom?: boolean;
  }[];
};

const ScheduleEvents: React.FC<Props> = ({
  days,
  appointments,
  dragging,
  width,
  availableSlots,
  selectedTreatment,
  minMaxTime,
  scheduleApptFromDoubleClick,
  usersAndRooms,
}) => {
  return (
    <div className="flex w-full">
      {days.map((day, dayIndex) => (
        <div key={`day-${dayIndex}`} className="flex flex-row">
          {usersAndRooms.map(
            (
              user: { id: string; name: string; isRoom?: boolean },
              userIndex,
            ) => (
              <ScheduleEventsUser
                key={`user-${userIndex}`}
                day={day.prop}
                userId={user.id}
                appointments={
                  appointments[day.prop]?.[user.id as any]
                    ?.appointments as AppointmentForUI[]
                }
                available={appointments?.[day.prop]?.[user.id]?.hours}
                dragging={dragging}
                width={width}
                availableSlots={
                  availableSlots?.[day.fullDate]?.[user.id] || null
                }
                selectedTreatment={selectedTreatment}
                disableEditing={!!availableSlots}
                minMaxTime={minMaxTime}
                scheduleApptFromDoubleClick={scheduleApptFromDoubleClick}
                isRoom={user?.isRoom || false}
              />
            ),
          )}
        </div>
      ))}
    </div>
  );
};

export default ScheduleEvents;
