import { useDroppable } from '@dnd-kit/core';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { classNames } from '@chiroup/core/functions/classNames';
import { UserRoles } from '@chiroup/core/types/User.type';

type Props = {
  id: string;
  day: string;
  userId: string;
  rowIndex: number;
  priorDay: boolean;
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  scheduleApptFromDoubleClick: (
    clinician: string,
    startTime: number,
    date: string,
  ) => void;
  available?: { start: number; end: number }[];
  isRoom?: boolean;
};

const ScheduleTimeSlot: React.FC<Props> = ({
  id,
  day,
  userId,
  rowIndex,
  priorDay,
  minMaxTime,
  scheduleApptFromDoubleClick,
  available,
  isRoom,
}) => {
  const { zoom } = useContext(AppointmentsContext);
  const { hasRole, selectedLocationFull } = useContext(MeContext);
  const { setNodeRef } = useDroppable({
    id,
    data: {
      day,
      userId,
      time: rowIndex * 15,
      isRoom,
    },
  });

  const doubleClickAppt = () => {
    if (
      !hasRole([
        UserRoles.Admin,
        UserRoles.Provider,
        UserRoles.Staff,
        UserRoles.ClinicalAssistant,
      ])
    ) {
      return;
    }
    const fifthteenMinuteIncrementsFromMin = rowIndex * 15;
    const minStartTime = minMaxTime.minTime !== 0 ? minMaxTime.minTime * 60 : 0;
    const totalMins = minStartTime + fifthteenMinuteIncrementsFromMin;
    const hours = Math.floor(totalMins / 60);
    const minutes = totalMins % 60 === 0 ? '00' : totalMins % 60;
    const time = `${hours}:${minutes}`;
    const startTime = dayjs.tz(`${day} ${time}`, selectedLocationFull.timezone);

    scheduleApptFromDoubleClick(
      userId,
      startTime.valueOf(),
      startTime.format('YYYY-MM-DD'),
    );
  };

  const fifthteenMinuteIncrementsFromMin = rowIndex * 15;
  const minStartTime = minMaxTime.minTime !== 0 ? minMaxTime.minTime * 60 : 0;
  const totalMins = minStartTime + fifthteenMinuteIncrementsFromMin;
  const hours = Math.floor(totalMins / 60);
  const minutes = totalMins % 60 === 0 ? '00' : totalMins % 60;
  const time = `${hours}:${minutes}`;
  const startTime = dayjs
    .tz(`${day} ${time}`, selectedLocationFull.timezone)
    .unix();

  const isAvailable = available?.length
    ? available?.some((slot) => {
        return slot.start <= startTime && slot.end > startTime;
      })
    : false;

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        'border-r border-gray-200',
        priorDay
          ? 'bg-gray-100 dark:bg-darkGray-500'
          : isAvailable
            ? 'bg-white dark:bg-darkGray-500'
            : 'bg-gray-100 dark:bg-darkGray-600',
        rowIndex % 2 ? 'border-b' : '',
      )}
      style={{
        height: 15 * zoom,
      }}
      onDoubleClickCapture={() => (!isRoom ? doubleClickAppt() : null)}
    />
  );
};

export default ScheduleTimeSlot;
