import { Select } from '@chiroup/components';
import React, { useContext, useEffect } from 'react';
import { MeContext } from '../../../../contexts/me.context';
import { prettyLocationDisplay } from '../../../layout/UserDropdownList';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';

type Props = {
  name: string;
  value?: any;
  onChange: (val: any) => void;
  errors?: FormError;
  className?: string;
  label?: string;
  disabled?: boolean;
  hint?: string;
  style?: React.CSSProperties;
  clearable?: boolean;
  onClear?: () => void;
  autoSelectLocation?: boolean;
  limit?: number;
};

const LocationSelect: React.FC<Props> = ({
  name,
  value,
  onChange,
  errors,
  className = 'col-span-2 sm:col-span-1',
  label = 'Location',
  disabled,
  hint,
  style = {},
  clearable = false,
  onClear,
  autoSelectLocation = true,
  limit,
}) => {
  const { activeLocations, me } = useContext(MeContext);

  useEffect(() => {
    if (autoSelectLocation && !value && me.selectedLocation) {
      onChange(me.selectedLocation);
    }
  }, [value, me?.selectedLocation, onChange, autoSelectLocation]);

  if (!activeLocations?.length || activeLocations?.length < 2) {
    return null;
  }

  return (
    <Select
      name={name}
      className={className}
      label={label}
      value={value}
      onChange={onChange}
      errors={errors}
      options={
        activeLocations.map((loc: ClinicLocation) => ({
          value: loc.ID,
          text: prettyLocationDisplay({
            ...loc,
            allLocations: activeLocations,
            name: '',
            textOnly: true,
          }),
        })) || []
      }
      limit={limit}
      autocomplete
      disabled={disabled}
      hint={hint}
      style={style}
      clearable={clearable}
      onClear={onClear}
    />
  );
};

export default LocationSelect;
