import Modal from '../common/Modal';
import { useContext, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import { PrinterIcon } from '@heroicons/react/24/solid';
import IconButton from '../common/IconButton';
import SectionHeader from '../layout/SectionHeader';
import ScheduleHoursForModal from './ScheduleHoursForModal';
import AppointmentViewForModal from './AppointmentViewForModal';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AppointmentForUI } from '@chiroup/core/types/Appointment.type';
import { TailwindColor } from '@chiroup/core/types/Color.type';

dayjs.extend(utc);
dayjs.extend(tz);

const numberOfNumbersNotInArr = (numbers: number[], maxNumber: number) => {
  // Numbers not in array that should be, based on the first and last numbers in the array... ie [1, 4, 7] should yeild 4 - unless maxNumber is 6, then it should yield 3
  const sortedNumbers = numbers.sort((a, b) => a - b);
  const firstNumber = sortedNumbers[0];
  const lastNumber = sortedNumbers[sortedNumbers.length - 1];
  const numbersNotInArr = [];
  for (let i = firstNumber; i <= lastNumber; i++) {
    if (!sortedNumbers.includes(i) && i <= maxNumber) {
      numbersNotInArr.push(i);
    }
  }
  return numbersNotInArr.length;
};

type Props = {
  close: () => void;
  isOpen: boolean;
  appointments?: AppointmentForUI[];
  dayData: {
    id?: string;
    name?: string;
    day?: string;
    dayName?: string;
    fullDate?: string;
    prop?: string;
  };
};

const pixelsPerMinute = 1.5;

export const minutesToPixels = (minutes: number) => {
  return minutes * pixelsPerMinute;
};

const addDetailsToAppointments = (
  appointments: AppointmentForUI[],
  timezone: string,
  hourOffset: number,
) => {
  const skippedHoursPx = 30;
  const skippedHoursDiff = 90 - skippedHoursPx;
  const firstHourWithAppointment = appointments?.[0]?.startTime.hour();
  const hoursWithAppointments = [firstHourWithAppointment];
  return appointments
    .sort((e1, e2) => e1.startTime.valueOf() - e2.startTime.valueOf())
    .map((appointment) => {
      const start = appointment.startTime;
      const startHour = start.hour();
      const endHour = appointment.startTime
        .add(appointment.duration, 'minute')
        .subtract(1, 'minute')
        .hour();
      const spansHoursArr = [];
      for (let i = startHour; i <= endHour; i++) {
        spansHoursArr.push(i);
      }
      spansHoursArr.forEach((hour) => {
        if (!hoursWithAppointments.includes(hour)) {
          hoursWithAppointments.push(hour);
        }
      });
      const hoursSkippedSoFar = numberOfNumbersNotInArr(
        hoursWithAppointments,
        startHour,
      );
      const hoursSkippedPx = hoursSkippedSoFar
        ? hoursSkippedSoFar * skippedHoursDiff
        : 0;
      const startHourMinutes = (startHour - hourOffset) * 60;
      const startMinutes = start.minute() + startHourMinutes;
      const top = minutesToPixels(startMinutes) - hoursSkippedPx;
      const height = minutesToPixels(appointment.duration) - 1;
      return {
        ...appointment,
        height,
        top: top,
        bottom: top + height,
        width: 100,
      };
    })
    .sort(
      (e1, e2) =>
        e1.top - e2.top || e1.bottom - e2.bottom || e1.height - e2.height,
    );
};

function findFirstAvailableColumn(
  event: AppointmentForUI,
  columns: AppointmentForUI[][],
): number {
  for (let i = 0; i < columns.length; i++) {
    const lastEvent = columns[i][columns[i].length - 1];
    if (!collidesWith(lastEvent, event)) {
      return i;
    }
  }
  return columns.length;
}

function updateEventDimensions(
  event: AppointmentForUI,
  columnWidth: number,
  columnLeft: number,
): void {
  event.width = columnWidth;
  event.left = columnLeft;
}

export function processAppointments(
  appointments: AppointmentForUI[],
  timezone: string,
  hourOffset = 0,
): AppointmentForUI[][] {
  let columns: AppointmentForUI[][] = [];
  let completedColumns: AppointmentForUI[][] = [];
  let lastEventEnding: number | null = null;

  const events = addDetailsToAppointments(appointments, timezone, hourOffset);

  events.forEach((event) => {
    if (lastEventEnding !== null && event.top > lastEventEnding) {
      completedColumns = completedColumns.concat(packEvents(columns));
      columns = [];
      lastEventEnding = null;
    }

    const columnIndex = findFirstAvailableColumn(event, columns);
    if (columns[columnIndex] === undefined) {
      columns[columnIndex] = [event];
    } else {
      columns[columnIndex].push(event);
    }

    if (lastEventEnding === null || event.bottom > lastEventEnding) {
      lastEventEnding = event.bottom;
    }
  });

  if (columns.length > 0) {
    columns = packEvents(columns);
  }
  return [...completedColumns, ...columns];
}

function packEvents(columns: AppointmentForUI[][]) {
  const columnWidth = 100 / columns.length;

  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    const columnLeft = columnWidth * colIndex;
    columns[colIndex].forEach((event) =>
      updateEventDimensions(event, columnWidth, columnLeft),
    );
  }

  return extendEventsToOtherColumnsIfPossible(columns);
}

const extendEventsToOtherColumnsIfPossible = (
  columns: AppointmentForUI[][],
): AppointmentForUI[][] => {
  const columnWidth = 100 / columns.length;
  return columns.map((col, colIndex) => {
    return col.map((event) => {
      let lastColumnWithNoCollisions = colIndex;

      for (let i = colIndex + 1; i < columns.length; i++) {
        let hasCollision = false;

        for (const otherEvent of columns[i]) {
          if (collidesWith(event, otherEvent)) {
            hasCollision = true;
            break;
          }
        }

        if (hasCollision) {
          break;
        } else {
          lastColumnWithNoCollisions = i;
        }
      }

      if (lastColumnWithNoCollisions > colIndex) {
        event.width = columnWidth * (lastColumnWithNoCollisions - colIndex + 1);
      }
      return event;
    });
  });
};

function collidesWith(a: AppointmentForUI, b: AppointmentForUI) {
  if (!a || !b) return false;
  if ((a.bottom || 0) <= (b.top || 0) || (b.bottom || 0) <= (a.top || 0)) {
    return false;
  }
  return true;
}

const ViewDayModal: React.FC<Props> = ({
  close,
  isOpen,
  appointments,
  dayData,
}) => {
  const { selectedLocationFull } = useContext(MeContext);
  const { disciplines } = useContext(ScheduleContext);

  const minMaxTime = useMemo(() => {
    let earliestTime: undefined | Dayjs;
    let latestTime: undefined | Dayjs;
    appointments?.forEach((appt) => {
      if (!earliestTime || appt?.startTime.isBefore(earliestTime)) {
        earliestTime = appt?.startTime;
      }
      if (!latestTime || appt?.startTime.isAfter(latestTime)) {
        latestTime = appt?.startTime;
      }
    });

    const localEarliestHour = earliestTime ? earliestTime.hour() : 0;
    const localLatestHour = latestTime ? latestTime.hour() : 23;

    return {
      minTime: localEarliestHour,
      maxTime: localLatestHour + 2,
    };
  }, [appointments]);

  const doesHourHaveAppt = useMemo(() => {
    return appointments?.reduce((obj: { [key in number]: boolean }, appt) => {
      const startHour = appt?.startTime?.hour();
      const duration = appt?.duration || 0;
      const endHour = appt?.startTime?.add(duration, 'minute')?.hour();

      const endMinute = appt?.startTime?.add(duration, 'minute')?.minute();
      obj[startHour] = true;
      if (endMinute !== 0 && startHour !== endHour) {
        obj[endHour] = true;
      }
      if (duration === 120 && endMinute === 0) {
        obj[endHour - 1] = true;
      }
      return obj;
    }, {});
  }, [appointments]);

  const appointmentsViewData = useMemo(() => {
    return processAppointments(
      appointments || [],
      selectedLocationFull.timezone || '',
      minMaxTime.minTime,
    ).flat();
  }, [appointments, selectedLocationFull.timezone, minMaxTime.minTime]);

  const treatmentColors = useMemo(() => {
    return disciplines.reduce(
      (obj: { [key in number]: TailwindColor }, discipline) => {
        if (discipline?.treatments?.length) {
          discipline?.treatments.map((item) => {
            return (obj[item.ID] = item.color || 'gray');
          });
        }
        return obj;
      },
      {},
    );
  }, [disciplines]);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      className="inline-block relative bg-white rounded-lg px-4 print:pt-0 pb-12 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl w-full sm:pl-6 pt-2 sm:pb-6 sm:pr-6 print:shadow-none print:overflow-visible"
    >
      <div className="print:w-full print:min-h-[800px]">
        <SectionHeader
          backgroundColor="bg-white"
          noDark
          title={
            <div className="flex flex-col">
              <span>{dayData?.name}</span>
              <span className="text-sm text-gray-700">
                {dayData?.dayName}{' '}
                {dayjs(dayData?.fullDate)
                  .locale('en')
                  .format('MMMM D, YYYY')}
              </span>
            </div>
          }
          className="!py-0 pb-4"
          rightSide={
            <div className="z-20 ml-4 flex print:hidden flex-row items-center gap-4">
              <IconButton
                className="h-8 w-8 text-gray-400 hover:text-gray-500"
                icon={<PrinterIcon />}
                tooltip="Print Day"
                onClick={() => window.print()}
              />
            </div>
          }
        />
        <div className="mt-4 ml-4 print:ml-8 print:mt-4 print:h-full relative">
          <ScheduleHoursForModal
            minMaxTime={minMaxTime}
            doesHourHaveAppt={doesHourHaveAppt}
          />
          <div className="absolute left-0 top-0 h-full w-full">
            {appointmentsViewData?.map((appointment) => {
              return (
                <div
                  key={appointment.id}
                  className="absolute"
                  style={{
                    top: appointment.top,
                    left: `${appointment.left}%`,
                    height:
                      //i dont want collisions just a little larger height for the 15 min appt.
                      appointment.duration === 15 && appointment.height
                        ? appointment.height + 8
                        : appointment.height,
                    width: `${appointment.width}%`,
                  }}
                >
                  <AppointmentViewForModal
                    patientName={`${appointment.displayValues?.patientName}`}
                    start={appointment.startTime}
                    isDragging={false}
                    width={`${appointment.width || 100}%`}
                    timezone={selectedLocationFull.timezone || ''}
                    duration={appointment.duration}
                    color={treatmentColors[appointment?.treatmentId] || 'gray'}
                    appointment={appointment}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewDayModal;
