import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { useContext, useId, useMemo, useState } from 'react';
import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
} from 'react-query';
import { MeContext } from '../contexts/me.context';
import { SelectOption } from '@chiroup/components';
import { databaseService } from '../components/settings/database/database.service';
import {
  itemToOptionText,
  itemToDataDescription,
} from '@chiroup/core/constants/DatabaseFeatureCommon';
import { useDebounce } from '@chiroup/hooks';

const query = (
  instanceKey: string,
  value: number,
  getInitialOptions: boolean,
  initialDisplayValue?: string,
) => {
  return async (context: QueryFunctionContext) => {
    const clinicId = Number.parseInt(context.queryKey[1] as string);
    const search = context.queryKey[3];

    if (!search) {
      /**
       * Use case: No search term and an initial value.
       */
      if (value) {
        if (initialDisplayValue) {
          return {
            data: [
              {
                ID: value,
                legalName: initialDisplayValue,
              },
            ],
            page: context.pageParam || 1,
            count: 1,
          };
        }

        const resp = (await databaseService.get({
          clinicId,
          database: instanceKey,
          id: value,
        })) as any;

        if (resp) {
          /**
           * This should only run _once_ as the user will always give a
           * search term after the initial value. But, setting this to
           * zero adds another layer to keep the REST from happening.
           */
          value = 0;
          return {
            data: [
              {
                ID: resp.ID,
                legalName: resp.legalName,
                payorID: resp.payorID,
              },
            ],
            page: context.pageParam || 1,
            count: 1,
          };
        }
      }
      if (!getInitialOptions) {
        return { count: -1, data: [], page: -1 };
      }
    }

    const searchResp = await ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/database/${instanceKey}`,
      {
        queryParams: {
          limit: 10,
          search,
          page: context.pageParam || 1,
        },
      },
    );
    return searchResp;
  };
};

type InstanceReferenceType = {
  [key: string]: any;
  ID: number;
};

const useDatabaseAutocomplete = (
  instanceKey: string,
  value: number,
  getInitialOptions: boolean,
  initialDisplayValue?: string,
) => {
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 750);
  const meContext = useContext(MeContext);
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery<{
    data: InstanceReferenceType[];
    count: number;
    page: number;
  }>(
    [useId(), meContext?.me.selectedClinic?.ID, instanceKey, searchDebounced],
    query(instanceKey, value, getInitialOptions, initialDisplayValue),
    {
      getNextPageParam: (lastGroup) =>
        lastGroup.count < 10 ? null : lastGroup.page + 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      placeholderData: {
        count: 0,
        page: 0,
        data: [] as InstanceReferenceType[],
        pageParams: [],
        pages: [],
      } as InfiniteData<any>,
    },
  );

  const options: SelectOption[] = useMemo(() => {
    if (!data) {
      return [];
    }
    const res = data.pages
      .reduce((acc: any, curr) => {
        return [...acc, ...curr.data];
      }, [] as any[])
      .map((row: any) => ({
        text: itemToOptionText(instanceKey, row),
        value: row.ID,
        data: {
          description: itemToDataDescription(instanceKey, row),
          codeSet: row.codeSet,
        },
      }))
      .sort((a: any, b: any) =>
        a.text > b.text ? 1 : b.text > a.text ? -1 : 0,
      );
    return res;
  }, [data, instanceKey]);

  const onChangeSearch = (value: string) => {
    setSearch(value);
  };

  return {
    options,
    isFetching,
    onChangeSearch,
    fetchNextPage,
    hasNextPage,
    search,
    // isPreloading,
  };
};

export default useDatabaseAutocomplete;
