import { Select, Toggle } from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import React, { useContext, useEffect, useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { MeContext } from '../../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import clinicService from '../../../services/clinic.service';
import Button, { ButtonColors } from '../../common/Button';
import { patientSettingReference } from './ClinicSettingsPatientPreferences';
import useConsents from '../../practice-resources/hooks/useConsents';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  intakeSurveys,
  surveyIds,
  functionalDisabilitySurveys,
} from '@chiroup/core/constants/SurveyIds';

export type PatientPreferencesForm = {
  allergySurvey?: boolean;
  backBournemouth?: boolean;
  neckBournemouth?: boolean;
  neckDisability?: boolean;
  quickDash?: boolean;
  oswestry?: boolean;
  ndi?: boolean;
  lefs?: boolean;
  ccs?: boolean;
  familyHistory?: boolean;
  healthHistory?: boolean;
  lifestyleHistory?: boolean;
  medicationHistory?: boolean;
  reviewOfSystems?: boolean;
  surgicalHistory?: boolean;
  photoID?: boolean;
  insurance?: boolean;
  demographics?: boolean;
  prefersEmail?: boolean;
  prefersText?: boolean;
  prefersNone?: boolean;
  disabilitySurveys?: string[];
  basicIntakeSurveys?: string[];
  detailedIntakeSurveys?: string[];
  ssn?: boolean;
};

type Props = {
  defaultValues: PatientPreferencesForm;
  ID?: string;
  clinicID?: number;
};

const ClinicSettingsPatientPreferencesForm: React.FC<Props> = ({
  defaultValues,
  ID,
  clinicID,
}) => {
  const { createToast } = useContext(ToastContext);
  const { me, updateMe, hasAccess } = useContext(MeContext);
  const { data: consents } = useConsents();
  const {
    value,
    onChange,
    errors,
    isSubmitting,
    registerSubmit,
    patchValueClean,
  } = useForm<PatientPreferencesForm>(defaultValues, {});

  useEffect(() => {
    patchValueClean(defaultValues);
  }, [defaultValues, patchValueClean]);

  const onSubmit = async (val: any) => {
    if (!clinicID || !ID) {
      return;
    }
    return clinicService.savePatientPreferences(clinicID, ID, { ...val });
  };

  const onSuccess = (val: any) => {
    if (me.selectedClinic) {
      updateMe('selectedClinic', {
        ...me.selectedClinic,
        settings: val,
      });
      updateMe(
        'clinics',
        me.clinics.map((clinic) => {
          if (clinic.ID === me.selectedClinic?.ID) {
            return { ...clinic, settings: val };
          }
          return clinic;
        }),
      );
    }
    createToast({
      title: 'Success!',
      description: <>Successfully saved report preferences!</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  const onFail = () => {
    createToast({
      title: 'Error!',
      description: <>Failed to save report preferences!</>,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const consentsData = useMemo(() => {
    let items: { title: string; id: string }[] = [];
    if (consents?.length) {
      items = [
        ...items,
        ...(consents?.map((consent) => ({
          title: consent.name,
          id: `consent::${consent.url}`,
        })) || []),
      ];
    }
    return items;
  }, [consents]);

  return (
    <form>
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0">
        <div className="space-y-4 sm:grid sm:grid-cols-12 sm:py-5">
          <div className="col-span-12">
            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              New patient intake surveys{' '}
              <InformationCircleIcon
                className="h-4 w-4"
                id="patientIntakeSurveys"
                data-tooltip-content="Surveys to be selected by default when creating a new patient."
              />
              <Tooltip anchorId="patientIntakeSurveys" />
            </h3>
            <div className="flex flex-col ">
              {Object.keys(patientSettingReference)
                .filter(
                  (key) =>
                    (patientSettingReference[key].section === 'intakeSurveys' &&
                      !hasAccess(FeatureFlags.ehr)) ||
                    (patientSettingReference[key].section === 'intakeSurveys' &&
                      key !== 'Chief complaint survey'),
                )
                .map((key: string, index: number) => (
                  <div
                    key={`patient-preference-${index}`}
                    className="mt-1 mr-1"
                  >
                    <Toggle
                      className="col-span-12 mt-2"
                      label={<div>{key}</div>}
                      value={
                        !!value[
                          patientSettingReference[key]
                            .uiProp as keyof PatientPreferencesForm
                        ]
                      }
                      onChange={(val) => {
                        onChange(
                          `${patientSettingReference[key].uiProp}` as keyof PatientPreferencesForm,
                        )(val);
                      }}
                      errors={
                        errors?.fieldErrors?.[
                          patientSettingReference[key]
                            .uiProp as keyof PatientPreferencesForm
                        ]
                      }
                    />
                  </div>
                ))}
            </div>
            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              New patient intake info{' '}
              <InformationCircleIcon
                className="h-4 w-4"
                data-tooltip-content="Information to be requested by default when creating a new patient."
                id="patientIntakeInfo"
              />
              <Tooltip anchorId="patientIntakeInfo" />
            </h3>
            <div className="flex flex-col col-span-6">
              {Object.keys(patientSettingReference)
                .filter(
                  (key) => patientSettingReference[key].section === 'intake',
                )
                .map((key: string, index: number) => (
                  <div
                    key={`patient-preference-${index}`}
                    className="mt-1 mr-1"
                  >
                    <Toggle
                      className="col-span-12 mt-2"
                      label={<div>{key}</div>}
                      value={
                        !!value[
                          patientSettingReference[key]
                            .uiProp as keyof PatientPreferencesForm
                        ]
                      }
                      onChange={(val) => {
                        onChange(
                          `${patientSettingReference[key].uiProp}` as keyof PatientPreferencesForm,
                        )(val);
                      }}
                      errors={
                        errors?.fieldErrors?.[
                          patientSettingReference[key]
                            .uiProp as keyof PatientPreferencesForm
                        ]
                      }
                    />
                  </div>
                ))}
            </div>
            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              Patient Demographics Survey
              <InformationCircleIcon
                className="h-4 w-4"
                data-tooltip-content="Optional fields to include on survey."
                id="patientDemographics"
              />
              <Tooltip anchorId="patientDemographics" />
            </h3>
            <div className="flex flex-col col-span-6">
              <Toggle
                className="col-span-12 mt-2"
                label="Social Security Number"
                value={
                  !!value[
                    patientSettingReference?.ssn
                      ?.uiProp as keyof PatientPreferencesForm
                  ]
                }
                onChange={(val) => {
                  onChange(
                    `${patientSettingReference.ssn?.uiProp}` as keyof PatientPreferencesForm,
                  )(val);
                }}
                errors={
                  errors?.fieldErrors?.[
                    patientSettingReference.ssn
                      ?.uiProp as keyof PatientPreferencesForm
                  ]
                }
              />
            </div>
            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              Notifications{' '}
              <InformationCircleIcon
                className="h-4 w-4"
                data-tooltip-content="Enabling this will prevent notifications from being sent to users who are not the patient's primary clinician."
                id="notifications"
              />
              <Tooltip anchorId="notifications" />
            </h3>
            <div className="flex flex-col col-span-6">
              {Object.keys(patientSettingReference)
                .filter(
                  (key) =>
                    patientSettingReference[key].section === 'notifications',
                )
                .map((key: string, index: number) => (
                  <div
                    key={`patient-notifications-${index}`}
                    className="mt-1 mr-1"
                  >
                    <Toggle
                      className="col-span-12 mt-2"
                      label={<div>{key}</div>}
                      value={
                        !!value[
                          patientSettingReference[key]
                            .uiProp as keyof PatientPreferencesForm
                        ]
                      }
                      onChange={(val) => {
                        onChange(
                          `${patientSettingReference[key].uiProp}` as keyof PatientPreferencesForm,
                        )(val);
                      }}
                      errors={
                        errors?.fieldErrors?.[
                          patientSettingReference[key]
                            .uiProp as keyof PatientPreferencesForm
                        ]
                      }
                    />
                  </div>
                ))}
            </div>
            {/* <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              New patient communication preference
              <InformationCircleIcon
                className="h-4 w-4"
                aria-hidden="true"
                id="communicationPreferences"
              />
              <Tooltip anchorId="communicationPreferences">
                <span>Default communication preference for new patients.</span>
              </Tooltip>
            </h3> */}
            {/* <Toggle
              className="col-span-12 mt-2"
              label={<div>Email</div>}
              value={
                !!value?.prefersEmail &&
                !value?.prefersText &&
                !value?.prefersNone
              }
              onChange={(val) => {
                onChange('prefersEmail')(val);
                onChange('prefersText')(!val);
                onChange('prefersNone')(!val);
              }}
              errors={errors?.fieldErrors?.prefersEmail}
            /> */}
            {/* <Toggle
              className="col-span-12 mt-2"
              label={<div>Text</div>}
              value={
                !!value?.prefersText &&
                !value?.prefersEmail &&
                !value?.prefersNone
              }
              onChange={(val) => {
                onChange('prefersText')(val);
                onChange('prefersEmail')(!val);
                onChange('prefersNone')(!val);
              }}
              errors={errors?.fieldErrors?.prefersText}
            /> */}
            {/* <Toggle
              className="col-span-12 mt-2"
              label={<div>None</div>}
              value={
                !!value?.prefersNone &&
                !value?.prefersEmail &&
                !value.prefersText
              }
              onChange={(val) => {
                onChange('prefersNone')(val);
                onChange('prefersEmail')(!val);
                onChange('prefersText')(!val);
              }}
              errors={errors?.fieldErrors?.prefersText}
            /> */}
            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              Automatic Chief Complaint Survey follow ups
              <InformationCircleIcon
                className="h-4 w-4"
                id="followUpPreferences"
              />
              <Tooltip anchorId="followUpPreferences">
                <span>
                  Selecting surveys here will enhance the Chief Complaint
                  <br />
                  Survey. When a patient selects areas that indicate that they
                  <br />
                  may have a specific issue on the Chief Complaint Survey, these
                  <br />
                  surveys will be automatically generated and added onto the the
                  <br />
                  patient's intake form.
                </span>
              </Tooltip>
            </h3>
            {Object.keys(patientSettingReference)
              .filter(
                (key) => patientSettingReference[key].section === 'ccsFollowup',
              )
              .map((key: string, index: number) => (
                <div key={`ccsFollowups-${index}`} className="mt-1 mr-1">
                  <Toggle
                    className="col-span-12 mt-2"
                    label={<div>{key}</div>}
                    value={
                      !!value[
                        patientSettingReference[key]
                          .uiProp as keyof PatientPreferencesForm
                      ]
                    }
                    onChange={(val) => {
                      onChange(
                        `${patientSettingReference[key].uiProp}` as keyof PatientPreferencesForm,
                      )(val);
                    }}
                    errors={
                      errors?.fieldErrors?.[
                        patientSettingReference[key]
                          .uiProp as keyof PatientPreferencesForm
                      ]
                    }
                  />
                </div>
              ))}

            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              Basic intake surveys
              <InformationCircleIcon
                className="h-4 w-4"
                id="basicIntakeSurveys"
              />
              <Tooltip anchorId="basicIntakeSurveys">
                <span>
                  Surveys to be selected when basic intake is selected.
                </span>
              </Tooltip>
            </h3>
            <Select
              name="basicIntakeSurveys"
              className="col-span-2"
              label="Basic intake surveys"
              value={value?.basicIntakeSurveys}
              onChange={onChange('basicIntakeSurveys')}
              options={[...intakeSurveys, ...consentsData]
                .filter(
                  (sur: any) =>
                    !hasAccess(FeatureFlags.ehr) ||
                    (hasAccess(FeatureFlags.ehr) && sur.id !== surveyIds.ccs),
                )
                .map((survey: any) => ({
                  text: survey.title,
                  value: survey.id,
                }))}
              alwaysReturnArray={true}
              autocomplete
              showLabel={false}
            />

            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              Detailed intake surveys
              <InformationCircleIcon
                className="h-4 w-4"
                id="detailedIntakeSurveys"
              />
              <Tooltip anchorId="detailedIntakeSurveys">
                <span>
                  Surveys to be selected when detailed intake surveys is
                  selected.
                </span>
              </Tooltip>
            </h3>
            <Select
              name="detailedIntakeSurveys"
              className="col-span-2"
              label="Detailed intake surveys"
              value={value?.detailedIntakeSurveys}
              onChange={onChange('detailedIntakeSurveys')}
              options={[...intakeSurveys, ...consentsData]
                .filter(
                  (sur: any) =>
                    !hasAccess(FeatureFlags.ehr) ||
                    (hasAccess(FeatureFlags.ehr) && sur.id !== surveyIds.ccs),
                )
                .map((survey: any) => ({
                  text: survey.title,
                  value: survey.id,
                }))}
              alwaysReturnArray={true}
              autocomplete
              showLabel={false}
            />
            <h3 className="text-gray-500 mt-4 flex flex-row gap-1">
              Available functional disability surveys
              <InformationCircleIcon
                className="h-4 w-4"
                id="functionalDisabilitySurvey"
              />
              <Tooltip anchorId="functionalDisabilitySurvey">
                <span>
                  Available functional disability surveys for users to send to
                  patients.
                </span>
              </Tooltip>
            </h3>
            <Select
              name="disabilitySurveys"
              className="col-span-2"
              label="Functional Disability Surveys"
              value={value.disabilitySurveys}
              onChange={onChange('disabilitySurveys')}
              options={(functionalDisabilitySurveys || []).map(
                (survey: any) => ({
                  text: survey.title,
                  value: survey.id,
                }),
              )}
              alwaysReturnArray={true}
              autocomplete
              showLabel={false}
            />
          </div>

          <div className="col-span-12 flex gap-2 justify-end">
            <Button
              color={ButtonColors.primary}
              text="Save"
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ClinicSettingsPatientPreferencesForm;
