import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import { MeContext } from '../../contexts/me.context';
import Button, { ButtonColors } from '../common/Button';
import Announcement from './Announcement';
import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';

const BillingBanner = () => {
  const location = useLocation();

  const { me } = useContext(MeContext);

  const announcementText = useMemo(() => {
    const pastDue = me?.selectedClinic?.subscription?.status === 'past_due';
    return pastDue
      ? `Your subscription is past due! Please update your payment method.`
      : null;
  }, [me?.selectedClinic?.subscription?.status]);

  return !!announcementText && !location.pathname.endsWith('billing') ? (
    <Announcement
      text={announcementText}
      icon={
        <svg
          className="h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      }
      rightSide={
        <Button
          color={ButtonColors.plain}
          text="Update Payment"
          className="text-red-600 cursor-pointer"
          onClick={async () => {
            const urlToGoTo = await ChiroUpAPI.post(
              'api',
              `/${me.selectedClinic?.ID}/billing/update-payment-method`,
              {},
            );
            window.location.href = urlToGoTo;
          }}
        />
      }
      error
    />
  ) : null;
};
export default BillingBanner;
