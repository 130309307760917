import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { HealthcareProvider } from '@chiroup/core/types/HealthcareProvider.type';

const healthcareProviderService = () => {
  const list = async (
    params: {
      search?: { searchTerm: string; facility?: boolean };
      skip?: number;
      limit?: number;
    },
    userId?: string,
    clinicId?: number,
  ) => {
    return await ChiroUpAPI.get('api', `/${clinicId}/${userId}/providers`, {
      queryParams: {
        search: params.search?.searchTerm,
        facility: params.search?.facility,
        skip: params.skip,
        limit: params.limit,
      },
    });
  };

  const listAllFacilities = async (userId?: string, clinicId?: number) => {
    return await ChiroUpAPI.get('api', `/${clinicId}/${userId}/providers`, {
      queryParams: {
        search: '',
        facility: true,
        skip: 0,
        limit: 1000,
      },
    });
  };

  const findOne = async (
    id?: string,
    selectedClinic?: number,
    userId?: string,
  ): Promise<HealthcareProvider> => {
    const res = await ChiroUpAPI.get(
      'api',
      `/${selectedClinic}/${userId}/provider/${id}`,
      {},
    );

    return res?.[0] || {};
  };

  const update = async (
    patient: Partial<HealthcareProvider>,
    selectedClinic?: number,
    userId?: string,
  ) => {
    const { ID, ...restOfHealthcareProvider } = patient;
    delete restOfHealthcareProvider.lastFirst;
    const res = await ChiroUpAPI.put(
      'api',
      `/${selectedClinic}/${userId}/provider/${ID}`,
      {
        body: restOfHealthcareProvider,
      },
    );
    return res?.[0] || {};
  };

  const del = (patientId: string, selectedClinic?: number, userId?: string) => {
    return ChiroUpAPI.del(
      'api',
      `/${selectedClinic}/${userId}/provider/${patientId}`,
      {},
    );
  };

  const create = async (
    val: Partial<HealthcareProvider>,
    selectedClinic?: number,
    userId?: string,
  ) => {
    const res = await ChiroUpAPI.post(
      'api',
      `/${selectedClinic}/${userId}/provider`,
      {
        body: val,
      },
    );
    return res?.[0] || {};
  };

  return { list, findOne, update, del, create, listAllFacilities };
};

export default healthcareProviderService();
