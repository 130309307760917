import { EmailValidator } from '@chiroup/hooks';
import { CreatePatient } from '../../patients/types/CreatePatient.type';
import { CommunicationMethodTypes } from '@chiroup/core/enums/CommunicationMethodTypes.enum';
import {
  getAgeInYears,
  is18OrOver,
  isRealDate,
} from '@chiroup/core/functions/ageInYears';

export const validators = {
  fname: {
    required: {
      message: 'First name is required.',
    },
  },
  lname: {
    required: {
      message: 'Last name is required.',
    },
  },
  dateOfBirth: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { dateOfBirth } = values;
        if (!dateOfBirth) {
          return false;
        }
        if (!isRealDate(dateOfBirth || '')) {
          return 'Please enter date of birth in the format MM/DD/YYYY';
        }
        if (
          dateOfBirth?.length === 10 &&
          Date.parse(dateOfBirth) > Date.now()
        ) {
          return 'Please enter a valid date of birth';
        }
        return false;
      },
    },
  },
  socialSecurityNumber: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { socialSecurityNumber } = values;
        const pattern = /^\d{3}-\d{2}-\d{4}$/;
        const valid = pattern.test(socialSecurityNumber?.trim() || '');
        if (socialSecurityNumber && !valid) {
          return 'SSN is not valid.';
        }
        return false;
      },
    },
  },
  guardian: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { dateOfBirth, guardian } = values;
        if (is18OrOver(dateOfBirth || '')) {
          return false;
        }
        if (!guardian) {
          return 'Guardian is required for patients under 18 years old.';
        }
        return false;
      },
    },
  },
  legalGuardianName: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { legalGuardianName, guardian, dateOfBirth } = values;
        if (!is18OrOver(dateOfBirth || '') && !legalGuardianName) {
          return 'Required for patients under 18 years old.';
        } else if (guardian && !legalGuardianName) {
          return 'Guardian name is required.';
        }
        return false;
      },
    },
  },
  email: {
    pattern: EmailValidator,
    function: {
      value: (values: Partial<CreatePatient>) => {
        const {
          requestInsuranceCard,
          requestInsurance,
          requestPhotoId,
          requestInfo,
          surveys,
          prefers,
          email,
        } = values;
        if (
          (requestInsuranceCard ||
            requestInsurance ||
            requestInfo ||
            requestPhotoId ||
            surveys?.length) &&
          prefers === CommunicationMethodTypes.email &&
          !email
        ) {
          return 'Email required if prefered communication method is email.';
        }
        return false;
      },
    },
  },
  phone: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { phone, dateOfBirth } = values;
        if (getAgeInYears(dateOfBirth ?? '') < 18) {
          return false;
        }
        const {
          requestInsuranceCard,
          requestInsurance,
          requestPhotoId,
          requestInfo,
          surveys,
          prefers,
        } = values;
        if (
          (requestInsuranceCard ||
            requestInsurance ||
            requestInfo ||
            requestPhotoId ||
            surveys?.length) &&
          prefers === CommunicationMethodTypes.text &&
          !phone
        ) {
          return 'Phone number required if prefered communication method is text message.';
        }
        if (!phone) {
          return false;
        }
        if (
          phone?.[1] === '1' &&
          phone?.split(' ')?.[0]?.length === 6 &&
          phone?.length < 13
        ) {
          return 'Phone number must be 7 digits.';
        } else if (phone?.[1] === '1' && phone?.length < 13) {
          return 'Phone number must be 10 digits.';
        }
        return false;
      },
    },
  },
  legalGuardianRelationship: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { legalGuardianRelationship, guardian, dateOfBirth } = values;
        if (
          !legalGuardianRelationship &&
          dateOfBirth &&
          getAgeInYears(dateOfBirth ?? '') < 18
        ) {
          return 'Required for patients under 18 years old.';
        } else if (guardian && !legalGuardianRelationship) {
          return 'Guardian relationship is required.';
        }
        return false;
      },
    },
  },
  primaryClinician: {
    required: {
      message: 'Primary clinican is required.',
    },
  },
  accountNumber: {
    pattern: {
      value: /^[A-Za-z0-9]+$/,
      message: 'Only letters and numbers allowed',
    },
  },
  // genderIdentity: {
  //   pattern: {
  //     value: /^[A-Za-z0-9]+$/,
  //     message: 'Only letters and numbers allowed',
  //   },
  // },
  // employer: {
  //   pattern: {
  //     value: /^[A-Za-z0-9]+$/,
  //     message: 'Only letters and numbers allowed',
  //   },
  // },
  zip: {
    function: {
      value: (value: Partial<CreatePatient>) => {
        const { zip, country } = value;
        //Zip is not a required field here right now so...
        // if (!zip)
        //   return country === 'USA'
        //     ? 'Zip code is required.'
        //     : 'Postal code is required.';
        if (country === 'USA' && !!zip) {
          if (zip.length !== 5 && zip.length !== 10) {
            return 'Zip code must be 5 or 9 digits.';
          }
        }
        return false;
      },
    },
  },
  creationDate: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const { creationDate } = values;
        if (!creationDate) {
          return false;
        }
        if (!isRealDate((creationDate as string) || '')) {
          return 'Please enter a valid creation date in the format MM/DD/YYYY';
        }
        if (
          (creationDate as string)?.length === 10 &&
          Date.parse(creationDate as string) > Date.now()
        ) {
          return 'Please enter a valid creation date';
        }
        return false;
      },
    },
  },
};

export const createPatientValidators = {
  ...validators,
  method: {
    function: {
      value: (values: Partial<CreatePatient>) => {
        const {
          method,
          requestInsurance,
          requestInsuranceCard,
          requestInfo,
          requestPhotoId,
          surveys,
          phone,
          email,
          dateOfBirth,
        } = values;
        if (
          !method &&
          (requestInsuranceCard ||
            requestInsurance ||
            requestInfo ||
            requestPhotoId ||
            surveys?.length)
        ) {
          return 'Destination is required when requesting info from patient.';
        }

        const requiredString = !is18OrOver(dateOfBirth) ? 'Guardian' : '';

        if (method === CommunicationMethodTypes.text && !phone) {
          return `${
            requiredString ? requiredString + ' cell' : 'Cell'
          } phone required.`;
        } else if (method === CommunicationMethodTypes.email && !email) {
          return `${
            requiredString ? requiredString + ' email' : 'Email'
          } required if destination is email.`;
        }
        return false;
      },
    },
  },
};
