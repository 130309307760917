import { Autocomplete } from '@chiroup/components';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import React, { forwardRef } from 'react';
import useDatabaseAutocomplete from '../../../hooks/useDatabaseAutocomplete';
import { classNames } from '@chiroup/core/functions/classNames';

type InputRef = {
  focus: () => void;
  blur: () => void;
  // Add any other methods or properties you want to expose
};

type Props = {
  instanceKey: string;
  value?: string | number;
  onChange: (value: string) => void;
  onEmptyOptions?: () => void;
  error?: FormError;
  searchRef?: React.RefObject<HTMLInputElement>;
  buttonRef?: React.RefObject<HTMLInputElement>;
  initialDisplayValue?: string;
  label?: string;
  className?: string;
  getInitialOptions?: boolean;
  readonly?: boolean;
  disabledClassName?: string;
  onChangeCallback?: (value: string) => void;
  loading?: boolean;
  clearable?: boolean;
  clearSearch?: boolean;
  placeholder?: string;
  disabled?: boolean;
  hideEndOfList?: boolean;
};

const DatabaseAutocomplete = forwardRef<InputRef, Props>(
  (
    {
      instanceKey,
      value,
      onChange,
      error,
      searchRef,
      buttonRef,
      onEmptyOptions,
      initialDisplayValue,
      label = '',
      className,
      getInitialOptions = false,
      readonly = false,
      disabledClassName = '',
      onChangeCallback = null,
      loading,
      clearable = false,
      clearSearch = true,
      placeholder = 'Type to search...',
      disabled = false,
      hideEndOfList = true,
    },
    ref,
  ) => {
    const {
      options,
      fetchNextPage,
      isFetching,
      // isPreloading,
      onChangeSearch,
      hasNextPage,
      search,
    } = useDatabaseAutocomplete(
      instanceKey,
      Number(value),
      getInitialOptions,
      initialDisplayValue,
    );

    const onSelect = (code: string) => {
      onChange(code);
      if (clearSearch) {
        onChangeSearch('');
      }
    };

    return (
      <Autocomplete
        className={classNames(
          className || 'col-span-8',
          readonly ? 'text-gray-800' : '',
        )}
        // label={InstanceSchema.billingCodes.code.label}
        label={label}
        name="recordId"
        onChange={onSelect}
        value={value}
        errors={error}
        onChangeSearch={onChangeSearch}
        options={options}
        onEmptyOptions={onEmptyOptions}
        serverSide
        fetchNextPage={fetchNextPage}
        isFetching={loading || isFetching}
        hasNextPage={hasNextPage}
        limit={1}
        searchTerm={search}
        clearable={clearable}
        // placeholder="Type to search..."
        initialDisplayValue={
          initialDisplayValue
            ? initialDisplayValue
            : value
              ? 'Loading...'
              : 'Type to search...'
        }
        noneText={placeholder}
        searchRef={searchRef}
        buttonRef={buttonRef}
        hideEndOfList={hideEndOfList}
        disabled={readonly || disabled}
        disabledClassName={disabledClassName}
        onChangeCallback={onChangeCallback}
      />
    );
  },
);

export default DatabaseAutocomplete;
