import { BusinessRuleSolutionEnum } from '@chiroup/core/constants/globals';
import { clog } from '@chiroup/core/functions/clog';
import {
  IntegrationInvoice,
  InvoiceStatusEnum,
  InvoiceGenerationEdiXref,
} from '@chiroup/core/types/Invoice.type';
import dayjs from 'dayjs';

export const invoiceStatusTransition = ({
  invoice,
  targetStatus,
  trace = false,
}: {
  invoice: IntegrationInvoice;
  targetStatus: InvoiceStatusEnum;
  trace?: boolean;
}) => {
  const issues = [];

  if (!invoice.transactionDate) {
    issues.push({
      text: `There must be a transaction date before processing any invoice.`,
    });
  } else {
    const nowIs = new Date(),
      serviceDate = dayjs(invoice.transactionDate),
      daysSinceService = dayjs(nowIs).diff(serviceDate, 'day'),
      isDelayRequired = daysSinceService >= 90;
    if (trace) {
      clog({
        invoice,
        targetStatus,
        nowIs,
        serviceDate,
        daysSinceService,
        isDelayRequired,
      });
    }
    if (isDelayRequired && !invoice.delayReason) {
      issues.push({
        text: `Transaction date is more than 90 days ago. A delay reason is required.`,
        name: BusinessRuleSolutionEnum.InvoiceDelayReason,
      });
    }
  }

  switch (invoice.status) {
    case InvoiceStatusEnum.Rejected: {
      const generation = invoice.generation,
        statusData = invoice.statusData || { items: [] },
        rejected = (statusData.items ?? []).find(
          (i) =>
            i.final === InvoiceStatusEnum.Rejected &&
            i.previous !== InvoiceStatusEnum.Rejected,
        ),
        generationCanBeOriginal = rejected
          ? rejected && rejected.previous !== InvoiceStatusEnum.Received
          : true;

      if (!InvoiceGenerationEdiXref[generation ?? '']) {
        issues.push({
          text: `Invalid generation ${generation} parameter.`,
        });
      }

      if (!generation || (generation === '1' && !generationCanBeOriginal)) {
        issues.push({
          text: `Invoice generation can't be original for invoices rejected by payors.`,
        });
      }
      break;
    }
  }

  switch (targetStatus) {
    case InvoiceStatusEnum.Submitted: {
      if (
        invoice.status !== InvoiceStatusEnum.Rejected &&
        invoice.status !== InvoiceStatusEnum.Unsubmitted &&
        invoice.status !== InvoiceStatusEnum.Pending
      ) {
        issues.push({
          text: `Invoice status must be pending or unsubmitted to transition to submitted.`,
        });
      }

      break;
    }
    default: {
      issues.push({ text: `Transition to ${targetStatus} not supported.` });
      break;
    }
  }
  return { count: issues.length, issues };
};
