import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import healthcareProviderService from '../services/healthcareProvider.service';
import { useContext } from 'react';
import { MeContext } from '../contexts/me.context';
import { ExtraFacilities } from '@chiroup/core/enums/ExtraFacilities.enum';
import { HealthcareProvider } from '@chiroup/core/types/HealthcareProvider.type';

const listQuery = (userId: string, clinicId = -1, disableFetch = false) => {
  return async (context: QueryFunctionContext) => {
    if (disableFetch) {
      return {};
    }
    return healthcareProviderService.listAllFacilities(userId, clinicId);
  };
};

type FacilityOption = { text: string; value: number };

type Props = {
  refetchOnMount?: boolean;
  disableFetch?: boolean;
};

// Disable fetch shouldn't really be a thing probably, but I don't want to mess with moving this stuff right now.
// This is mainly to not get facilities for no reason when pulling up the report. I'm not even sure how this works.
// I'm guessing the places where we need created providers we're just fetching them again...
const useFacilities = ({
  refetchOnMount = true,
  disableFetch = false,
}: Props = {}) => {
  const meContext = useContext(MeContext);
  const queryClient = useQueryClient();
  const { data, isFetching, isSuccess } = useQuery<{
    data: HealthcareProvider[];
  }>(
    ['facilities'],
    listQuery(
      meContext?.me?.ID,
      meContext?.me.selectedClinic?.ID,
      disableFetch,
    ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount,
    },
  );

  const transformedFacilities: FacilityOption[] =
    data?.data?.map((facility: HealthcareProvider) => ({
      text: facility?.facilityName ?? '',
      value: facility?.ID ?? -3,
    })) || [];

  transformedFacilities.unshift(
    { text: 'In-house', value: ExtraFacilities.InHouse },
    { text: 'Unspecified outside', value: ExtraFacilities.Unspecified },
  );

  const createProvider = async (provider: Partial<HealthcareProvider>) => {
    const res = await healthcareProviderService.create(
      provider,
      meContext.me.selectedClinic?.ID,
      meContext.me.ID,
    );

    if (provider?.facilityName) {
      queryClient.setQueryData(
        ['facilities'],
        (oldData: { data: HealthcareProvider[] } | undefined) => {
          if (!oldData) return { data: [res] };
          return {
            data: [...(oldData?.data || []), res],
          };
        },
      );
    }
  };

  return {
    data,
    isFetching,
    isSuccess,
    createProvider,
    facilityOptions: transformedFacilities,
  };
};

export default useFacilities;
