import { OpenClosedStates } from '@chiroup/components';
import React, { useContext, useMemo } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import Button, { ButtonColors } from '../common/Button';
import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import { tiers } from '../settings/billing/Billing';
import { formatCurrency } from '@chiroup/core/functions/format';
import { Plan, Prices } from '@chiroup/core/types/Me.type';

type Props = {
  setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
  selectedPlan: Plan;
};

const content = {
  plus: {
    title: "Unlock ChiroUp's Social Media Blurbs!",
    subtitle: 'Here’s what you get with ChiroUp’s Plus Subscription:',
    benefits: [
      'Evidence-based social media posts for every business day',
      'Automatic posting to Facebook and/or Instagram',
    ],
  },
  premium: {
    title: "Unlock ChiroUp's Premier Community Marketing!",
    subtitle: 'Here’s what you get with ChiroUp’s Premium Subscription:',
    benefits: [
      'Evidence-based social media posts with automatic posting',
      'Monthly patient newsletters',
      'Monthly MD newsletters',
      'Quarterly attorney newsletters',
    ],
  },
};

const UpgradeBilling: React.FC<Props> = ({ setState, selectedPlan }) => {
  const { me } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);

  const [savingUpdate, setSavingUpdate] = React.useState(false);

  const confirmChange = async () => {
    setSavingUpdate(true);
    try {
      await ChiroUpAPI.post('api', `/${me.selectedClinic?.ID}/billing/change`, {
        body: {
          plan: selectedPlan,
          locations: me?.selectedClinic?.subscription?.locations ?? 0,
        },
      });
      window.location.reload();
    } catch (err: any) {
      console.error({ err: err?.response?.data?.message });
      let msg: string;
      if (err?.response?.data?.message === 'Cannot update annual plans.') {
        msg =
          'Cannot update annual plans. Please contact support for further assistance.';
      } else {
        msg = 'Failed to change plans!';
      }
      setSavingUpdate(false);
      createToast({
        title: 'Error!',
        description: msg,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
    setSavingUpdate(false);
  };

  // Calculate the price to show
  const priceToShow = useMemo(() => {
    const planPrice =
      me?.selectedClinic?.prices?.[
        selectedPlan as 'basic' | 'plus' | 'premium'
      ];

    const locationsTotal =
      (me?.selectedClinic?.prices?.additionalLocations ?? 0) *
      ((me?.selectedClinic?.locations?.length ?? 0) - 1);

    if (typeof planPrice !== 'number') {
      return 'N/A';
    }

    return formatCurrency(planPrice + locationsTotal);
  }, [
    me.selectedClinic?.locations?.length,
    me.selectedClinic?.prices,
    selectedPlan,
  ]);

  // Calculate the current price
  const currentPrice = useMemo(() => {
    const subTotal =
      (me?.selectedClinic?.prices?.[
        me?.selectedClinic?.plan as keyof Prices
      ] as number) ??
      tiers.find((t) => t.value === me?.selectedClinic?.plan)?.price?.[
        me.selectedClinic?.country === 'CAN' ? 'CAN' : 'USA'
      ] ??
      0;

    const locationsTotal =
      (me?.selectedClinic?.prices?.additionalLocations ?? 0) *
      ((me?.selectedClinic?.locations?.length ?? 0) - 1);
    return formatCurrency(subTotal + locationsTotal);
  }, [
    me.selectedClinic?.country,
    me.selectedClinic?.locations?.length,
    me.selectedClinic?.plan,
    me.selectedClinic?.prices,
  ]);

  const selectedContent = content[selectedPlan as keyof typeof content] || {};

  return (
    <form>
      <div className="mt-3 sm:mt-5">
        <h3
          className="text-lg font-bold leading-6 text-gray-900 dark:text-darkGray-100"
          id="modal-headline"
        >
          {selectedContent.title || ''}
        </h3>
        <p className="pt-1 text-sm leading-5 text-gray-500 dark:text-darkGray-400">
          {selectedContent.subtitle || ''}
        </p>
        <ul className={'m-4'}>
          {selectedContent.benefits?.map((benefit, i) => (
            <li
              className="mt-2 text-sm leading-5 text-gray-500 dark:text-darkGray-400"
              key={i}
            >
              <span className="flex items-center">
                <CheckIcon
                  className={'text-primary-500 font-medium'}
                  height={18}
                  width={18}
                />
                <span className="ml-2">{benefit}</span>
              </span>
            </li>
          ))}
        </ul>

        <p className="mt-2 text-sm leading-5 text-gray-500 dark:text-darkGray-400">
          No contract. Cancel or downgrade at any time.
        </p>
        <hr className="my-4 border-gray-300 mt-6" />
        <div className={'py-4'}>
          <div className="flex justify-between gap-4">
            <div className="text-sm font-semibold leading-5 text-gray-500 dark:text-darkGray-400">{`Current Price: ${currentPrice}/month`}</div>
            <div className="pr-4 text-sm font-semibold leading-5 text-gray-500 dark:text-darkGray-400">{`New Price: ${priceToShow}/month`}</div>
          </div>
          <p className="mt-2 text-sm leading-5 text-gray-500 dark:text-darkGray-400">
            You will be billed the new amount on your next billing cycle.
          </p>
        </div>
      </div>
      <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
        <Button
          text="Cancel"
          onClick={() => setState(OpenClosedStates.Closed)}
          fullWidth
          color={ButtonColors.plainWithBorder}
        />
        <Button
          text="Upgrade"
          fullWidth
          loading={savingUpdate}
          color={ButtonColors.primary}
          onClick={confirmChange}
        />
      </div>
    </form>
  );
};

export default UpgradeBilling;
