import dayjs, { Dayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import useLocalStorage, { LSType } from '../../../hooks/useLocalStorage';
import patientService, {
  AppointmentDurationChangeParams,
} from '../../../services/patient.service';
import { isEmpty } from '@chiroup/core/functions/isEmpty';
import { createDayjs } from '@chiroup/core/functions/time';
import {
  Appointments,
  AppointmentForUI,
} from '@chiroup/core/types/Appointment.type';

dayjs.extend(utc);
dayjs.extend(tz);

export type AppointmentListResponse = {
  appointments: Appointments;
};

const getAppointmentsQuery = (
  clinicId: number,
  locationId: number,
  patientId?: string,
  complete?: boolean,
) => {
  return async (context: QueryFunctionContext) => {
    const searchTerm = context.queryKey[2] as {
      startDate: string;
      endDate: string;
      patientId: string;
      complete: boolean;
    };

    const queryParams = searchTerm;
    if (patientId) {
      return patientService.listAppointmentsByPatient(
        clinicId,
        patientId || '',
        complete,
      );
    } else {
      return patientService.listAppointmentsByDates(
        clinicId,
        locationId,
        queryParams,
      );
    }
  };
};

const useAppointments = (
  sessionId: string,
  patientId?: string,
  complete?: boolean,
) => {
  const { getItem } = useLocalStorage();
  const { startDate: lsStartDate, endDate: lsEndDate } =
    getItem(LSType.both, 'scheduleDateRange') || {};
  const { me, selectedLocationFull } = useContext(MeContext);

  const [searchQuery, setSearchQuery] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: lsStartDate || dayjs().startOf('week').format('YYYY-MM-DD'),
    endDate: lsEndDate || dayjs().endOf('week').format('YYYY-MM-DD'),
  });

  const queryClient = useQueryClient();

  const queryKey = ['appointments', 'list', searchQuery, patientId, complete];

  const { status, data, error, isFetching, refetch } = useQuery<
    AppointmentForUI[] | Appointments
  >(
    queryKey,
    getAppointmentsQuery(
      me.selectedClinic?.ID ?? -1,
      me.selectedLocation ?? -1,
      patientId,
      complete,
    ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const saveAppointmentsUI = (appointments: AppointmentForUI[]) => {
    appointments =
      appointments?.map((appointment) => {
        if (
          typeof appointment.startTime === 'string' ||
          typeof appointment.startTime === 'number'
        ) {
          appointment.startTime = createDayjs({
            datetime: appointment.startTime,
            timezone: selectedLocationFull.timezone as string,
          }) as Dayjs;
        }
        return appointment;
      }) || [];
    const newApptsByDayByClinician = appointments.reduce(
      (
        obj: {
          [key: string]: {
            [key: string]: AppointmentForUI[];
          };
        },
        appointment,
      ) => {
        const day = appointment.startTime.format('YYYY-MM-DD');
        if (!obj[day]) {
          obj[day] = {};
        }
        if (!obj[day][appointment.clinicianId]) {
          obj[day][appointment.clinicianId] = [];
        }
        obj[day][appointment.clinicianId].push(appointment);
        return obj;
      },
      {},
    );
    queryClient.setQueryData(queryKey, (prev?: Appointments) => {
      const prevAppointments = prev || {};
      const newObj = Object.entries(prevAppointments).reduce(
        (obj: any, [day, appointmentsByDay]) => {
          return {
            ...obj,
            [day]: Object.entries(appointmentsByDay).reduce(
              (innerObj, [clinicianId, appointmentsByClinician]) => {
                return {
                  ...innerObj,
                  [clinicianId]: {
                    ...appointmentsByClinician,
                    appointments: [
                      ...(appointmentsByClinician?.appointments || []),
                      ...(newApptsByDayByClinician?.[day]?.[clinicianId] || []),
                    ],
                  },
                };
              },
              {},
            ),
          };
        },
        {},
      );
      return newObj;
    });
  };

  const deleteAppointmentUI = (appointment: {
    id: string;
    startTime: number;
    clinicianId: string;
  }) => {
    const dayDayjs = createDayjs({
      datetime: appointment.startTime,
      timezone: selectedLocationFull.timezone as string,
    });
    if (!dayDayjs) return;
    const day = dayDayjs.format('YYYY-MM-DD');
    const userId = appointment.clinicianId;
    queryClient.setQueryData(queryKey, (prev?: Appointments) => {
      if (!prev) {
        return {};
      }
      const newAppointments = [...(prev?.[day]?.[userId]?.appointments || [])];
      const index = newAppointments.findIndex((a) => a.id === appointment.id);
      newAppointments.splice(index, 1);
      prev[day][userId].appointments = newAppointments || [];
      return { ...prev } as Appointments;
    });
  };

  const saveAppointment = async ({
    appointment,
  }: {
    appointment: Partial<AppointmentForUI>;
    excludeSession?: boolean;
  }) => {
    const newAppointment = await (appointment.id
      ? patientService.updateAppointment(
          me.selectedClinic?.ID,
          sessionId,
          appointment,
        )
      : patientService.addAppointment(
          me.selectedClinic?.ID,
          me.selectedLocation,
          sessionId,
          appointment,
        ));
    saveAppointmentsUI([newAppointment]);
    // queryClient.invalidateQueries({ queryKey: queryKey });
    return newAppointment;
  };

  const updateAppointmentUI = (appointment: AppointmentForUI) => {
    if (
      typeof appointment.startTime === 'string' ||
      typeof appointment.startTime === 'number'
    ) {
      // Came here from WS, so it is a string in the correct TZ
      appointment.startTime = createDayjs({
        datetime: appointment.startTime,
        timezone: selectedLocationFull.timezone as string,
      }) as Dayjs;
    }

    queryClient.setQueryData(
      ['appointments', 'detail', appointment.id],
      appointment,
    );

    const listCache = queryClient.getQueryCache().findAll(queryKey);
    const day = appointment.startTime.format('YYYY-MM-DD');
    let cacheWithAppointment = -1;
    let previousDay: string | null = null;
    let previousClinicianId: string | null = null;
    let cacheWithNewDay = -1;
    let previousRoom: string | null = null;
    if (listCache) {
      for (let i = 0; i < listCache.length; i++) {
        const item = listCache[i];
        const data = item.state.data as any;
        const dataAppointments = data || {};
        const days = Object.keys(dataAppointments || {});
        if (days.includes(day)) {
          cacheWithNewDay = i;
        }
        const itemEntries = Object.entries(dataAppointments || {}) as any;
        for (let j = 0; j < itemEntries.length; j++) {
          const [day, value] = itemEntries[j];
          const valueValues = Object.values(value);
          for (let k = 0; k < valueValues.length; k++) {
            const innerItem: any = valueValues[k];
            const isRoom = innerItem?.isRoom;
            const appointments = innerItem
              ? Array.isArray(innerItem.appointments)
                ? innerItem.appointments
                : []
              : null;

            if (appointments) {
              for (let l = 0; l < appointments.length; l++) {
                const appItem = appointments[l];
                if (appItem.id === appointment.id && !isRoom) {
                  cacheWithAppointment = i;
                  previousDay = day;
                  previousClinicianId = appItem.clinicianId;
                } else if (appItem.id === appointment.id && isRoom) {
                  previousRoom = appItem.roomId;
                }
              }
            }
          }
        }
      }
    }

    if (cacheWithAppointment > -1 && previousDay && previousClinicianId) {
      queryClient.setQueryData(
        listCache[cacheWithAppointment].queryKey,
        (prev: any) => {
          const newAppointments = prev[previousDay as string][
            previousClinicianId as string
          ].appointments?.filter(
            (item: AppointmentForUI) => item.id !== appointment.id,
          );
          prev[previousDay as string][
            previousClinicianId as string
          ].appointments = newAppointments || [];
          if (cacheWithNewDay === cacheWithAppointment) {
            const newAppointments = [
              ...prev[day][appointment.clinicianId].appointments,
            ];
            newAppointments.push(appointment);
            prev[day][appointment.clinicianId].appointments =
              newAppointments || [];
          }
          if (previousRoom) {
            const isSameRoom = previousRoom === String(appointment.roomId);
            const roomId = String(appointment.roomId);
            const newAppointments = prev[day][roomId].appointments?.filter(
              (item: AppointmentForUI) => item.id !== appointment.id,
            );
            if (isSameRoom) {
              newAppointments.push(appointment);
              prev[day][roomId].appointments = newAppointments || [];
            } else {
              prev[day][previousRoom].appointments = newAppointments || [];
              prev[day][roomId].appointments = [
                ...prev[day][roomId].appointments,
              ];
              prev[day][roomId].appointments.push(appointment);
            }
          }
          return { ...prev };
        },
      );
    }
    if (cacheWithNewDay > -1 && cacheWithNewDay !== cacheWithAppointment) {
      queryClient.setQueryData(
        listCache[cacheWithNewDay].queryKey,
        (prev: any) => {
          const newAppointments = [
            ...prev[day][appointment.clinicianId].appointments,
          ];
          newAppointments.push(appointment);
          prev[day][appointment.clinicianId].appointments =
            newAppointments || [];
          return { ...prev };
        },
      );
    }
  };

  const updateAppointment = async ({
    day,
    time,
    userId,
    previousDay,
    previousUserId,
    wholeAppointment,
    timezone,
  }: {
    day: string;
    time: number;
    userId: string;
    previousDay: string;
    previousUserId: string;
    wholeAppointment: AppointmentForUI;
    timezone: string;
  }): Promise<
    | {
        success: boolean;
        response?: AppointmentForUI;
        error?: any;
      }
    | undefined
  > => {
    if (!me.selectedClinic?.ID) return;
    const previousState = queryClient.getQueryData([
      'appointments',
      searchQuery,
    ]);

    const newStartTime = dayjs.tz(day, timezone).add(time, 'minute');
    updateAppointmentUI({
      ...wholeAppointment,
      startTime: newStartTime,
      clinicianId: userId,
    });
    try {
      const response = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          ...wholeAppointment,
          clinicianId: userId,
          startTime: newStartTime,
        },
        {
          type: 'updateAppointment',
          day,
          time,
          userId,
          previousDay,
          previousUserId,
          wholeAppointment,
        },
      );
      return { success: true, response };
    } catch (err: any) {
      console.error({ err });
      queryClient.setQueryData(queryKey, previousState);
      return { success: false, error: err };
    }
    // queryClient.invalidateQueries({ queryKey: queryKey });
  };

  const updateAppointmentTimeUI = ({
    day,
    userId,
    appointmentId,
    duration,
  }: AppointmentDurationChangeParams) => {
    queryClient.setQueryData(queryKey, (prev: any) => {
      const appointmentToUpdateIndex = prev?.appointments?.[day]?.[
        userId
      ]?.appointments?.findIndex((app: any) => app.id === appointmentId);
      if (isEmpty(appointmentToUpdateIndex) || appointmentToUpdateIndex < 0) {
        return prev;
      }
      return {
        ...prev,
        appointments: {
          ...prev,
          [day]: {
            ...prev[day],
            [userId]: {
              ...prev[day][userId],
              appointments: prev[day][userId].appointments.map(
                (appt: any, apptIndex: number) => {
                  if (apptIndex === appointmentToUpdateIndex) {
                    return {
                      ...appt,
                      duration,
                    };
                  }
                  return appt;
                },
              ),
            },
          },
        },
      };
    });
  };

  const updateAppointmentTime = async ({
    day,
    userId,
    appointmentId,
    duration,
  }: AppointmentDurationChangeParams): Promise<
    | {
        success: boolean;
        response?: AppointmentForUI;
        error?: any;
      }
    | undefined
  > => {
    if (!me.selectedClinic?.ID) return;
    const previousState = queryClient.getQueryData([
      'appointments',
      searchQuery,
    ]);
    updateAppointmentTimeUI({
      day,
      userId,
      appointmentId,
      duration,
    });
    try {
      const response = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          id: appointmentId,
          duration,
        },
        {
          type: 'updateAppointmentTime',
          day,
          userId,
          appointmentId,
          duration,
        },
      );

      return { success: true, response };
    } catch (err) {
      console.error({ err });
      queryClient.setQueryData(queryKey, previousState);
      return { success: false, error: err };
    }
    // queryClient.invalidateQueries({ queryKey: queryKey });
  };

  const onMessage = ({ type, data }: { type: string; data: any }) => {
    if (type === 'updateAppointment') {
      updateAppointmentUI(data);
    } else if (type === 'updateAppointmentTime') {
      updateAppointmentTimeUI(data);
    } else if (type === 'createAppointment') {
      saveAppointmentsUI(Array.isArray(data) ? data : [data]);
    } else if (type === 'deleteAppointment') {
      deleteAppointmentUI(data);
    }
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    updateAppointment,
    updateAppointmentUI,
    updateAppointmentTime,
    updateAppointmentTimeUI,
    searchQuery,
    setSearchQuery,
    saveAppointment,
    onMessage,
  };
};

export default useAppointments;
