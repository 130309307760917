import {
  AddCodingDetails,
  Button,
  ButtonColors,
  Loading,
  TrivialTooltip,
} from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import React, { useCallback, useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import { ToastTypes } from '../../../../../../contexts/toast.context';
import { TransactionContext } from '../../../../../../contexts/transaction.context';
import businessRulesService from '../../../../../../services/businessRules.service';
import { ServiceMagicEvent } from '../../../../../../services/businessRules.service/serviceMagic';
import {
  TransactionMagiceResponseType,
  TransactionMagicEvent,
} from '../../../../../../services/businessRules.service/transactionMagic';
import { databaseService } from '../../../../../settings/database/database.service';
import EncounterInsurance from '../insurances/EncounterInsurance';
import { InsuranceError } from './CodeEncounterDiagnosisServices';
import CodeEncounterServices from './CodeEncounterServices';
import SaltTransaction from './SaltTransaction';
import { PayorListType } from '@chiroup/core/constants/DatabaseFeatureCommon';
import {
  USE_STATE_PAIR,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpTransactionCommon } from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import {
  PatientTransactionItemType,
  TransactionAppendItemsType,
  PatientTransaction,
  TransactionItemSubtypeEnum,
  PatientTransactionItemDiagnosisType,
  TransactionItemTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { VisitPlanType } from '@chiroup/core/types/PatientVisit.type';
import { ReferenceCodeDisplay } from '@chiroup/core/types/ReferenceCode.type';

const isaAppointment = (parentIsa?: string) => {
  return parentIsa === 'appointment';
};

const isaVisit = (parentIsa?: string) => {
  return (
    parentIsa === 'visit' ||
    parentIsa === 'encounter' ||
    parentIsa === 'transaction'
  );
};

const parentTitleCase = (parentIsa?: string, plural?: boolean) => {
  if (isaAppointment(parentIsa)) {
    return 'Appointment' + (plural ? 's' : '');
  } else if (isaVisit(parentIsa)) {
    return 'Visit' + (plural ? 's' : '');
  }
  return 'Ooops!';
};

const parentLowerCase = (parentIsa?: string, plural?: boolean) => {
  if (isaAppointment(parentIsa)) {
    return 'appointment' + (plural ? 's' : '');
  } else if (isaVisit(parentIsa)) {
    return 'visit' + (plural ? 's' : '');
  }
  return 'Ooops!';
};

type Props = {
  omitTitle: boolean;
  listofServices: PatientTransactionItemType[];
  isFetchingService: boolean;
  setListofServices: React.Dispatch<
    React.SetStateAction<PatientTransactionItemType[]>
  >;
  readonly?: boolean;
  isBillingStarted?: boolean;
  locked: boolean;
  parentIsa?: string;
  unlockButtonText: string;
  lockButtonText: string;
  saveButtonText: string;
  loadFromPlanAndAssessment: (e: any) => Promise<{
    services: PatientTransactionItemType[];
    insurances: Partial<AppointmentInsuranceType[]>;
  }>;
  lockButtonClicked: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  saveButtonClicked: ({
    e,
    disableToast,
    services,
    insurancesToUse,
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    disableToast?: boolean | undefined;
    services?: PatientTransactionItemType[] | null | undefined;
    insurancesToUse?: AppointmentInsuranceType[] | null | undefined;
  }) => Promise<void>;
  saveCallback?: (args: TransactionAppendItemsType) => void;
  setInsurances: (ins: Partial<AppointmentInsuranceType>[]) => void;
  setPayors: React.Dispatch<React.SetStateAction<any[]>>;
  patientId: string;
  insuranceErrors: InsuranceError[];
  services: PatientTransactionItemType[];
  plan?: VisitPlanType;
  isRestActive: {
    loadPlan: boolean;
    lock: boolean;
    save: boolean;
  };
  setIsFetchingService: React.Dispatch<React.SetStateAction<boolean>>;
  remoteControl?: boolean;
  disciplineId?: number;
  policies: any[];
  isFetchingPolicies: boolean;
  handlePolicyChange: (e: any) => void;
  patientTransactionState?: USE_STATE_PAIR<PatientTransaction>;
  lockButtonComponent?: React.ReactNode;
  allowBillingPriorityChange: boolean;
  courtesyBilling?: boolean;
  superBill?: boolean;
  handleCourtesyBillingChange: (e: boolean) => void;
  handleSuperBillChange?: (e: boolean) => void;
  providerId?: string;
  handleBillingProfileChange: (e: number | null) => void;
  billingProfileId?: number;
  caseType?: number | null;
  caseTypes?: Partial<ClinicCaseType>[];
  importButtonText?: string;
  importOnlyMode?: boolean;
  afterImport?: () => void;
  // FYI: I could not define createToast this far down, a react artificat of some kind.
  // However, passing it in works. Because, this component needed another parameter.
  createToast?: (args: any) => void;
  hasPackage?: boolean;
  findThePackageCredit?: (params: {
    packageId?: number | null;
    items: PatientTransactionItemType[];
    insurances?: Partial<AppointmentInsuranceType>[];
  }) => any;
};

const CodeEncounterDiagnosisServicesWithDiagnosis: React.FC<Props> = ({
  omitTitle,
  listofServices,
  isFetchingService,
  setListofServices,
  readonly = false,
  isBillingStarted = false,
  locked,
  parentIsa,
  unlockButtonText,
  lockButtonText,
  saveButtonText,
  loadFromPlanAndAssessment,
  lockButtonClicked,
  saveButtonClicked,
  saveCallback,
  setInsurances,
  patientId,
  insuranceErrors,
  services,
  plan,
  isRestActive,
  setIsFetchingService,
  remoteControl = false,
  disciplineId,
  policies,
  isFetchingPolicies,
  handlePolicyChange,
  setPayors,
  patientTransactionState,
  lockButtonComponent = null,
  allowBillingPriorityChange = false,
  courtesyBilling = false,
  superBill = false,
  handleCourtesyBillingChange,
  handleSuperBillChange,
  providerId,
  handleBillingProfileChange,
  billingProfileId,
  caseType,
  caseTypes,
  importButtonText = 'Import from wizard',
  importOnlyMode = false,
  afterImport,
  createToast = () => {},
  hasPackage = false,
  findThePackageCredit,
}) => {
  const { me } = useContext(MeContext);
  const { insurances, isFetching: isFetchingInsurance } =
    useContext(TransactionContext);
  // const { createToast } = useContext(ToastContext);

  const updateTransactionWhenNeeded = useCallback(
    (services: PatientTransactionItemType[], doMagic?: boolean) => {
      let magicResponse: TransactionMagiceResponseType | null = null;
      patientTransactionState?.set?.((prev) => {
        const clone = ChiroUpJSON.clone(prev) as PatientTransaction;
        const itemsWithoutServices =
          clone?.items?.filter(
            (item) =>
              item?.subtype !== TransactionItemSubtypeEnum.Service &&
              item?.subtype !== TransactionItemSubtypeEnum.PatientService,
          ) || [];
        clone.services = services;
        clone.items = [...itemsWithoutServices, ...services];

        //if a package has been applied and then services are added the package credit needs to be removed and a new one calculated
        const wasPackagePreviouslyApplied = clone.items?.find(
          (item: PatientTransactionItemType) =>
            item.subtype === TransactionItemSubtypeEnum.Adjustment &&
            item?.packageId,
        );

        if (wasPackagePreviouslyApplied) {
          let newItems = [];
          //remove the package credit
          newItems = JSON.parse(
            JSON.stringify(
              clone.items.filter(
                (item) =>
                  !(
                    item.subtype === TransactionItemSubtypeEnum.Adjustment &&
                    item?.packageId
                  ),
              ),
            ),
          );

          const packageCredit = findThePackageCredit?.({
            packageId: wasPackagePreviouslyApplied?.packageId,
            items: newItems,
          });

          newItems.push(packageCredit);
          clone.items = newItems;
        }

        if (doMagic) {
          magicResponse = businessRulesService.transactionMagic({
            transaction: clone,
            event: TransactionMagicEvent.onChangeService,
          }) as TransactionMagiceResponseType;
          if (magicResponse?.actions?.length) {
            createToast({
              title: 'ChiroUp Bot',
              description: (
                <div>
                  {magicResponse?.actions.map((action, idx) =>
                    action.message ? <p key={idx}>{action.message}</p> : null,
                  )}
                </div>
              ),
              type: ToastTypes.Info,
              duration: 5000,
            });
            setListofServices(magicResponse.transaction.services as any);
          }
        }

        return magicResponse?.transaction ?? clone;
      });
    },
    [createToast, patientTransactionState, setListofServices],
  );

  const trivialTooltip = useMemo(() => {
    return {
      text: isBillingStarted ? ChiroUpTransactionCommon.billingStarted : '',
      id: 'main-tooltip',
    };
  }, [isBillingStarted]);

  const buttonColor = useMemo(
    () => (isBillingStarted ? ButtonColors.accent : ButtonColors.primary),
    [isBillingStarted],
  );

  const onChangeServices = (
    index: number,
    val: PatientTransactionItemType | null,
  ) => {
    const newServices = [...(listofServices || [])];
    if (val) {
      newServices[index] = val;
    } else {
      newServices.splice(index, 1);
    }
    setListofServices(newServices);
    updateTransactionWhenNeeded(newServices);
  };

  const onChangeBillableStatus = ({
    code,
    status,
  }: {
    code: string;
    status: boolean;
  }) => {
    const newServices = [...(listofServices || [])];
    newServices.map((service) => {
      if (service.code === code) {
        service.insuranceBillable = status;
        service.subtype =
          service?.subtype === TransactionItemSubtypeEnum.Service
            ? TransactionItemSubtypeEnum.PatientService
            : TransactionItemSubtypeEnum.Service;
      }
      return service;
    });
    setListofServices(newServices);
    updateTransactionWhenNeeded(newServices);
  };

  const onChangeBilledAmount = ({
    code,
    amount,
  }: {
    code: string;
    amount: string;
  }) => {
    const newServices = [...(listofServices || [])];
    newServices.map((service) => {
      if (service.code === code) {
        service.amount = amount;
      }
      return service;
    });
    setListofServices(newServices);
    updateTransactionWhenNeeded(newServices);
  };

  const onPromote = useCallback(
    async (e: ReferenceCodeDisplay, getRidOfService?: string) => {
      if (isFetchingService) return;

      setIsFetchingService(true);

      /**
       * Fire off the REST calls in parallel.
       */
      const proms: Promise<any>[] = [];
      let code: PayorListType = {};
      // let ins: Insurance[] = [];

      try {
        proms.push(
          databaseService
            .getCode({
              clinicId: me?.selectedClinic?.ID,
              code: e.code,
              payors: true,
            })
            .then((r: any) => {
              code = r.data as PayorListType;
              setPayors((prevPayors) => {
                const newPayors =
                  code.payors?.map((payor: any) => ({
                    payorID: payor.payorIntPk,
                    allowedAmount: +payor.allowedAmount,
                    code: e.code,
                    replacementCode: payor.replacementCode,
                  })) || [];

                return [...(prevPayors || []), ...(newPayors || [])];
              });
              const objs = (insurances || []).map(
                (insurance: Partial<AppointmentInsuranceType>) => {
                  const matchedPayor = code.payors?.find(
                    (payor: any) => payor.payorIntPk === insurance.payorID,
                  );
                  // console.log({ matchedPayor });
                  const allowedAmount = Number(
                    matchedPayor?.allowedAmount || code?.billedAmount || 0,
                  );
                  return {
                    ...insurance,
                    serviceAllowedAmounts: {
                      ...insurance.serviceAllowedAmounts,
                      [e.code]: {
                        allowedAmount,
                        payorID: insurance.payorID as number,
                      },
                    },
                  };
                },
              );
              setInsurances(objs);
              return r;
            }),
        );
        // proms.push(
        //   patientBillingService
        //     .listPatientInsurance(me?.selectedClinic?.ID, patientId)
        //     .then((r) => {
        //       ins = r.data;
        //       return r;
        //     }),
        // );
      } catch (e) {
        console.error({ e });
      }
      await Promise.all(proms);

      /**
       * [2024-10-14.0855 by Brian per Emily in 7519482792]
       *   Logic: Sort the insurances by billing priority. Then, for each
       *   insurance, add the modifiers in the order they appear. If no
       *   insurance-specific modifiers exist, then the defaults are used.
       *   Modifiers are only added once in the order they are found.
       */
      const sortedInsurances = ChiroUpJSON.clone(
          patientTransactionState?.value?.insurances ?? [],
        ).sort((a: any, b: any) => a.billingPriority - b.billingPriority),
        usedModifiders: STRING_BOOLEAN_HASH = {},
        orderedModifiers: string[] = [];

      /**
       * Business rule: Add the modifiers to the service in the order they
       * appear in the crazy payor-specific modifiers. At the end, if there
       * are no ordered modifiers at this point, then add the default modifiers.
       */
      while (sortedInsurances.length) {
        const ins = sortedInsurances.shift() as AppointmentInsuranceType,
          billingCode = code?.payors?.find(
            (p: any) => p.payorIntPk === ins.payorID,
          ) as any,
          hasPayorSpecificModifiers =
            !!billingCode?.modifier1 ||
            !!billingCode?.modifier2 ||
            !!billingCode?.modifier3 ||
            !!billingCode?.modifier4;

        if (hasPayorSpecificModifiers) {
          ['modifier1', 'modifier2', 'modifier3', 'modifier4'].forEach((s) => {
            if (billingCode[s] && !usedModifiders[billingCode[s]]) {
              orderedModifiers.push(billingCode[s]);
              usedModifiders[billingCode[s]] = true;
            }
          });
        }
      }

      if (!orderedModifiers.length) {
        if (code.modifier1) orderedModifiers.push(code.modifier1);
        if (code.modifier2) orderedModifiers.push(code.modifier2);
        if (code.modifier3) orderedModifiers.push(code.modifier3);
        if (code.modifier4) orderedModifiers.push(code.modifier4);
      }

      if (code) {
        const nobj = ChiroUpJSON.clone(code);

        nobj.diagnoses = [] as PatientTransactionItemDiagnosisType[];
        nobj.units = 1;
        nobj.type = TransactionItemTypeEnum.Debit;
        nobj.subtype = TransactionItemSubtypeEnum.Service;
        nobj.amount = code.billedAmount ? +code.billedAmount : 0;
        nobj.variableBilledAmount  = code.variableBilledAmount;
        nobj.modifier1 = orderedModifiers[0] ?? null;
        nobj.modifier2 = orderedModifiers[1] ?? null;
        nobj.modifier3 = orderedModifiers[2] ?? null;
        nobj.modifier4 = orderedModifiers[3] ?? null;
        nobj.insuranceBillable = true;

        const magicResponse = businessRulesService.serviceMagic({
          service: nobj,
          insurances: (patientTransactionState?.value?.insurances ||
            []) as AppointmentInsuranceType[],
          event: ServiceMagicEvent.onAddSingleService,
        });

        if (magicResponse?.actions?.length) {
          createToast({
            title: 'ChiroUp Bot',
            description: (
              <div>
                {magicResponse?.actions.map((action, idx) =>
                  action.message ? <p key={idx}>{action.message}</p> : null,
                )}
              </div>
            ),
            type: ToastTypes.Info,
            duration: 5000,
          });
        }

        const servicesToUse = ChiroUpJSON.clone(listofServices);
        const codeToFind = nobj.code;
        const matchingService =
          servicesToUse?.find(
            (service: PatientTransactionItemType) =>
              service.code === codeToFind,
          ) || null;
        if (matchingService) {
          nobj.insuranceBillable = matchingService.insuranceBillable;
          nobj.amount = matchingService.amount;
        }
        setListofServices((prev: PatientTransactionItemType[]) => {
          const newVal = [...(prev || [])];
          if (getRidOfService) {
            const serviceIndex = newVal.findIndex(
              (service) => service.code === getRidOfService,
            );
            if (serviceIndex > -1) {
              newVal[serviceIndex] = nobj;
            }
          } else {
            newVal.push(nobj);
          }
          return newVal as PatientTransactionItemType[];
        });
        const valToSend = servicesToUse;
        if (getRidOfService) {
          const serviceIndex = valToSend.findIndex(
            (service: any) => service.code === getRidOfService,
          );
          if (serviceIndex > -1) {
            valToSend[serviceIndex] = nobj;
          }
        } else {
          valToSend.push(nobj);
        }
        updateTransactionWhenNeeded(valToSend, true);
      }

      setIsFetchingService(false);
    },
    [
      isFetchingService,
      setIsFetchingService,
      me?.selectedClinic?.ID,
      setPayors,
      insurances,
      setInsurances,
      patientTransactionState?.value?.insurances,
      listofServices,
      setListofServices,
      updateTransactionWhenNeeded,
      createToast,
    ],
  );

  const onRemoveInsurance = useCallback(
    (policy: Partial<AppointmentInsuranceType>) => {
      const next = ChiroUpJSON.clone(insurances).filter(
        (p: Partial<AppointmentInsuranceType>) => {
          return p.insuranceID !== policy.insuranceID;
        },
      );
      setInsurances(next);
    },
    [insurances, setInsurances],
  );

  const activePolicies = useMemo(() => {
    return (policies || [])
      ?.filter((policy: Insurance) => policy.active && !policy.pending)
      ?.filter(
        (ap: Insurance) =>
          !(insurances || []).some((policy) => ap.id === policy.insuranceID),
      )
      ?.map((policy: Insurance) => ({
        text: policy?.name || '',
        value: policy,
        disabled: !policy.billable && isBillingStarted,
        color: policy.billable ? '' : 'text-red-500',
      }));
  }, [policies, insurances, isBillingStarted]);

  const doThis = useCallback(
    async (e: any) => {
      const { services, insurances } = await loadFromPlanAndAssessment(e);
      await saveButtonClicked({
        e: e as React.MouseEvent<HTMLButtonElement>,
        services,
        insurancesToUse: insurances as AppointmentInsuranceType[],
      });
      afterImport?.();
    },
    [loadFromPlanAndAssessment, saveButtonClicked, afterImport],
  );

  if (importOnlyMode) {
    return (
      <div>
        {plan && Object.keys(plan).length > 0 && (
          <div className="flex flex-row space-x-4">
            <Button
              text={importButtonText}
              loading={isRestActive.loadPlan || isRestActive.save}
              className="print:hidden"
              disabled={
                locked ||
                isFetchingInsurance ||
                isFetchingService ||
                isRestActive.loadPlan
              }
              onClick={doThis}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
            {/* <pre>{ChiroUpJSON.pretty({ insurances })}</pre> */}
            {/* <Button
              text={`${saveButtonText}`}
              loading={isRestActive.save}
              className="print:hidden"
              disabled={!!insuranceErrors.length}
              onClick={(e: unknown) => {
                saveButtonClicked({
                  e: e as React.MouseEvent<HTMLButtonElement>,
                });
              }}
            />{' '} */}
          </div>
        )}
        {/* <pre>{ChiroUpJSON.pretty(plan)}</pre> */}
      </div>
    );
  }

  return (
    <div className="w-full">
      {isFetchingService && (!listofServices || listofServices.length === 0) ? (
        <div className="flex justify-center m-4">
          <Loading color="text-gray-400" />
        </div>
      ) : null}
      {!readonly || (readonly && listofServices.length > 0) ? (
        <div
          className={classNames(
            omitTitle ? '' : 'mt-4 ',
            'relative block text-lg font-medium leading-5',
            isBillingStarted ? 'text-accent-600' : 'text-primary-600',
          )}
        >
          {!omitTitle ? `Services for this ${parentTitleCase(parentIsa)}` : ''}
          {!omitTitle ? (
            <TrivialTooltip
              text={`${parentTitleCase(
                parentIsa,
                true,
              )} may have any number of services. 
  A single service must have at least one diagnosis and may have up to four. 
  Add diagnoses in order: primary, secondary, tertiary, and quaternary.
  A maximum of 12 diagnoses may be associated with one appointment.
  The number of units and a billed amount are required.`}
              tipClassName="w-96 text-sm"
            />
          ) : null}
        </div>
      ) : null}
      <CodeEncounterServices
        services={listofServices}
        readonly={readonly || locked}
        isBillingStarted={isBillingStarted}
        onChangeValue={onChangeServices}
        onPromote={onPromote}
        onChangeBillableStatus={onChangeBillableStatus}
        onChangeBilledAmount={onChangeBilledAmount}
      />
      <div className="w-full p-0">
        {!readonly && !locked && (
          <div className="flex flex-col space-y-4">
            <SaltTransaction
              target={
                {
                  ...patientTransactionState?.value,
                  insurances,
                } as PatientTransaction
              }
              parentIsa={parentIsa}
              callback={(txn: PatientTransaction) => {
                setListofServices(
                  (txn.items || []).filter(
                    (i) =>
                      i.subtype === TransactionItemSubtypeEnum.Service ||
                      i.subtype === TransactionItemSubtypeEnum.PatientService,
                  ),
                );
                setInsurances(txn.insurances || []);
                setPayors(txn.payors || []);
                if (typeof saveCallback === 'function') {
                  saveCallback({ newTransaction: txn });
                }
              }}
            />

            <AddCodingDetails
              label="Services"
              value={listofServices}
              codeSet={[CodeSets.CPT, CodeSets.SERVICE]}
              noCodesMessage={`No services are associated with this ${parentLowerCase(
                parentIsa,
              )}.`}
              noneText="Type to search and add service codes..."
              onPromote={onPromote}
              isPromoting={isFetchingService}
              promoteTitle={`Add this service to the ${parentLowerCase(
                parentIsa,
              )}.`}
              // repeatTitle={`This service is already associated with the ${parentLowerCase(
              //   parentIsa,
              // )}.`}
              autoCompleteContainerClassName="mr-0.5 flex flex-row gap-4"
              clinicId={me.selectedClinic?.ID as number}
              isBillingStarted={isBillingStarted}
            />
          </div>
        )}
      </div>
      {caseTypes?.find((c) => c.id === caseType)?.name !== 'Cash' && (
        <>
          <EncounterInsurance
            activePolicies={activePolicies}
            allowBillingPriorityChange={allowBillingPriorityChange}
            billingProfileId={billingProfileId}
            courtesyBilling={courtesyBilling}
            disciplineId={disciplineId}
            handleBillingProfileChange={handleBillingProfileChange}
            handleCourtesyBillingChange={handleCourtesyBillingChange}
            handlePolicyChange={handlePolicyChange}
            handleSuperBillChange={handleSuperBillChange}
            insuranceErrors={insuranceErrors}
            insurances={insurances}
            isBillingStarted={isBillingStarted}
            isFetchingPolicies={isFetchingPolicies}
            onRemoveInsurance={onRemoveInsurance}
            parentIsa={parentIsa}
            patientId={patientId}
            policies={policies}
            providerId={providerId}
            readOnly={readonly}
            services={(services || []).filter(
              (item) => item.subtype === TransactionItemSubtypeEnum.Service,
            )}
            setInsurances={setInsurances}
            superBill={superBill}
            billingKey={patientTransactionState?.value?.billingKey as string}
          />
          <InsuranceErrors errors={insuranceErrors} />{' '}
        </>
      )}
      <div className="flex flex-row justify-end space-x-4">
        {patientTransactionState?.value?.services?.length && !readonly ? (
          lockButtonComponent ? (
            lockButtonComponent
          ) : (
            <Button
              text={locked ? unlockButtonText : lockButtonText}
              loading={isRestActive.save}
              className="mt-5 print:hidden"
              onClick={lockButtonClicked}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
          )
        ) : null}
        {!readonly && !remoteControl && (
          <>
            {plan && Object.keys(plan).length > 0 && (
              <Button
                text={importButtonText}
                loading={isRestActive.loadPlan}
                className="mt-5 print:hidden"
                disabled={locked || isFetchingService || isRestActive.loadPlan}
                onClick={async (e: any) => {
                  await loadFromPlanAndAssessment(e);
                }}
                color={buttonColor}
                trivialTooltip={trivialTooltip}
              />
            )}
            <Button
              text={`${saveButtonText}`}
              loading={isRestActive.save}
              className="mt-5 print:hidden"
              disabled={!!insuranceErrors.length}
              onClick={(e: unknown) => {
                saveButtonClicked({
                  e: e as React.MouseEvent<HTMLButtonElement>,
                });
              }}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
          </>
        )}
      </div>
    </div>
  );
};

type InsuranceErrorsProps = {
  errors?: InsuranceError[];
  className?: string;
};

export const InsuranceErrors: React.FC<InsuranceErrorsProps> = ({
  errors,
  className = '',
}) => {
  if (!errors || errors.length === 0) return null;

  return (
    <div className="border border-red-600 px-4 py-2 rounded-md">
      {errors.map((error, index) => (
        <div
          key={`cedswd-errors-${index}`}
          className={['my-2 text-sm text-red-600', className].join(' ')}
        >
          {error.message}
        </div>
      ))}
    </div>
  );
};

export default CodeEncounterDiagnosisServicesWithDiagnosis;
