import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import React, { SetStateAction } from 'react';

export const TransactionContext = React.createContext<{
  status: string | null;
  data: any;
  isFetching: boolean;
  refetch: () => void;
  insurances: Partial<AppointmentInsuranceType>[];
  payors: any[];
  snapshot: any;
  locked: boolean;
  services: any;
  setServices: (services: any) => void;
  setSnapshot: (snapshot: any) => void;
  setInsurances: SetStateAction<Partial<AppointmentInsuranceType>[]> | any;
  setLocked: (locked: any) => void;
  setPayors: (payors: any) => void;
  setCourtesyBilling: (courtesyBilling: any) => void;
  setSuperBill: (superBill: any) => void;
  allowBillingPriorityChange: boolean;
  courtesyBilling: boolean;
  superBill: boolean;
  transactionReadonly: number | boolean;
  listofServices: any[];
  setListofServices: SetStateAction<any[]> | any;
}>({
  status: null,
  data: {},
  isFetching: false,
  refetch: () => {},
  insurances: [],
  payors: [],
  snapshot: {},
  locked: false,
  services: {},
  setServices: () => {},
  setSnapshot: () => {},
  setInsurances: () => {},
  setLocked: () => {},
  setPayors: () => {},
  setCourtesyBilling: () => {},
  setSuperBill: () => {},
  allowBillingPriorityChange: false,
  courtesyBilling: false,
  superBill: false,
  transactionReadonly: false,
  listofServices: [],
  setListofServices: () => {},
});
