import { MeContext } from '../../../contexts/me.context';
import React, { useContext, useState } from 'react';
import HealthComLogo from '../../../images/healthcom.png';
import InfoItem from '../../common/info/InfoItem';
import patientService from '../../../services/patient.service';
import { formatPhone } from '@chiroup/core/functions/format';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import { Patient } from '@chiroup/core/types/Patient.type';

type Props = {
  patient: Partial<Patient> | null;
  onClickResetPassword?: () => void;
  caseTypes: Partial<ClinicCaseType>[];
};

const PatientData: React.FC<Props> = ({
  patient,
  onClickResetPassword,
  caseTypes,
}) => {
  const { me } = useContext(MeContext);
  const [patientSSN, setPatientSSN] = useState<string | undefined>(undefined);
  const [isFetchingSSN, setIsFetchingSSN] = useState<boolean>(false);
  const unHideFunction = async () => {
    setIsFetchingSSN(true);
    try {
      const res = await patientService.getPatientSSN({
        clinicId: me?.selectedClinic?.ID,
        patientId: patient?.ID,
      });
      setPatientSSN(res);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetchingSSN(false);
    }
  };
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <InfoItem
        label={patient?.guardian ? `Legal guardian email` : `Email`}
        value={patient?.email}
        star={patient?.prefers === 'email'}
      />
      <InfoItem
        label={
          patient?.guardian ? `Legal guardian mobile number` : `Mobile number`
        }
        value={formatPhone(patient?.phone)}
        star={patient?.prefers === 'text'}
        elementTitle={patient?.prefers === 'text' ? 'Prefers text' : null}
      />
      <InfoItem label="Date of birth" value={patient?.dateOfBirth} />
      {patient?.guardian && (
        <>
          <InfoItem label="Guardian Name" value={patient?.legalGuardianName} />
          <InfoItem
            label="Legal Guardian Relationship"
            value={patient?.legalGuardianRelationship}
          />
        </>
      )}
      <InfoItem
        label={'SSN'}
        value={
          patient?.lastFour || patientSSN
            ? `${patientSSN ? patientSSN : `###-##-${patient?.lastFour}`}`
            : undefined
        }
        unHide={patientSSN ? false : true}
        unHideFunction={unHideFunction}
        isFetchingHidden={isFetchingSSN}
      />

      <InfoItem label="Status" value={patient?.status ?? 'N/A'} />
      <InfoItem
        label="Address"
        value={
          <>
            {patient?.address1}
            {!!patient?.address2 && <br />}
            {patient?.address2}
            <br />
            {patient?.city ? `${patient.city}, ` : ''} {patient?.state}{' '}
            {patient?.zip}
          </>
        }
        show={!!patient?.address1}
      />
      <InfoItem label="ID Number" value={patient?.idNumber} />
      <InfoItem label="Sex" value={patient?.gender} />
      <InfoItem label="Occupation" value={patient?.occupation} />
      <InfoItem
        label="Emergency Contact Name"
        value={patient?.emergencyContactName}
      />
      <InfoItem
        label="Emergency Contact Phone"
        value={formatPhone(patient?.emergencyContactPhone)}
      />
      <InfoItem
        label="Primary healthcare provider"
        value={patient?.primaryProviderText}
      />
      <InfoItem
        label="Prior chiropractic care"
        value={
          (patient?.previouslyTreated && patient?.previouslyTreated === true) ||
          patient?.previouslyTreated === false
            ? patient?.previouslyTreated
              ? 'Yes'
              : 'No'
            : ''
        }
      />
      <InfoItem
        label="Referred by"
        value={
          <>
            {patient?.heardAboutUs}
            {!!patient?.heardAboutUsDetail && (
              <span className="font-light text-gray-500 dark:text-darkGray-500 ml-1">
                ({patient?.heardAboutUsDetail})
              </span>
            )}
          </>
        }
        show={!!patient?.heardAboutUs}
      />
      <div className="sm:col-span-2">
        <div className="flex gap-2 items-end">
          <dt className="text-sm leading-5 font-medium text-gray-500 dark:text-darkGray-400">
            HealthCom
          </dt>
        </div>

        <dd className="mt-1 text-sm leading-5 text-gray-900 dark:text-darkGray-100">
          <ul className="border border-gray-300 dark:border-darkGray-600 rounded-md bg-white dark:bg-darkGray-700">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
              <div className="flex flex-row gap-2">
                <img
                  src={HealthComLogo}
                  className="h-10 w-10"
                  alt="HealthCom"
                />
                <div className="flex flex-col">
                  <span className="font-bold">Username</span>
                  <span>
                    {patient?.username ? patient?.username : 'Not in HealthCom'}
                  </span>
                </div>
              </div>
              {onClickResetPassword &&
                !me?.selectedClinic?.noPatientCommunication && (
                  <span
                    onClick={onClickResetPassword}
                    className="font-medium text-primary-600 hover:text-primary-400 transition duration-150 ease-in-out cursor-pointer"
                  >
                    {patient?.isInCognito
                      ? 'Reset password'
                      : 'Add to Healthcom'}
                  </span>
                )}
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  );
};

export default PatientData;
