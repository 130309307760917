import { Patient } from '@chiroup/core/types/Patient.type';
import { useDebounce } from '@chiroup/hooks';
import { useEffect } from 'react';
import { useCallback, useContext, useState } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import useLocalStorage, { LSType } from '../../../hooks/useLocalStorage';
import patientService from '../../../services/patient.service';

const getPatientsQuery = (disableList: boolean) => {
  return async (context: QueryFunctionContext) => {
    if (disableList) {
      return { data: [], skip: 0 };
    }
    const clinicId = context.queryKey[1] as string;
    const searchTerm = context.queryKey[2] as string;
    const search = searchTerm || '';
    return patientService.list(
      { skip: context.pageParam, limit: 20, search },
      Number(clinicId),
    );
  };
};

const usePatientsSearch = ({
  disableList = false,
}: {
  disableList?: boolean;
}) => {
  const { me } = useContext(MeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery);
  const { getItem, setItem } = useLocalStorage();
  const [recentPatients, setRecentPatients] = useState([]);
  const {
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<{ data: Patient[]; skip: number }>(
    ['patients', me.selectedClinic?.ID, debouncedSearch],
    getPatientsQuery(disableList),
    {
      getNextPageParam: (lastGroup) => {
        return lastGroup.data?.length === 20 ? lastGroup?.skip : undefined;
      },
      refetchOnWindowFocus: false,
      staleTime: 300000, // 5 minutes
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const addRecentPatient = useCallback(
    (patient: Patient) => {
      if (!patient) {
        return;
      }
      if (!patient.clinic && me.selectedClinic?.ID) {
        patient.clinic = me.selectedClinic?.ID;
      }
      const recentPatients = getItem(LSType.clinic, 'recentPatients');
      const existing: Patient[] = recentPatients
        ? typeof recentPatients === 'string'
          ? JSON.parse(recentPatients)
          : recentPatients
        : [];
      const existingWithoutNew = existing
        ? existing.filter((item) => item.ID !== patient.ID)
        : [];
      const existingCutDown = existingWithoutNew.slice(0, 11);
      const newRecentlyViewed = [patient, ...existingCutDown];
      setItem(LSType.clinic, 'recentPatients', newRecentlyViewed);
    },
    [getItem, setItem, me.selectedClinic?.ID],
  );

  const removeRecentPatient = (patientId: string) => {
    const recentPatientsItem = getItem(LSType.clinic, 'recentPatients');
    if (recentPatientsItem) {
      // Is it ever set as JSON.stringify?
      const existing: Patient[] = recentPatientsItem
        ? typeof recentPatientsItem === 'string'
          ? JSON.parse(recentPatientsItem)
          : recentPatientsItem
        : [];
      const recentPatients = existing.filter(
        (patient: Patient) => patient.ID !== patientId,
        1,
      );
      setItem(LSType.clinic, 'recentPatients', recentPatients);
    }
  };

  useEffect(() => {
    const res = getItem(LSType.clinic, 'recentPatients');
    setRecentPatients(res ? res : []);
  }, [me.selectedClinic?.ID, getItem]);

  useEffect(() => {
    refetch();
  }, [me.selectedClinic?.ID, refetch]);

  const onSearch = (params: string) => {
    setSearchQuery(params);
  };

  return {
    isFetching,
    data,
    searchQuery,
    onSearch,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    addRecentPatient,
    recentPatients,
    removeRecentPatient,
    refetch,
  };
};

export default usePatientsSearch;
