import React, { useEffect, useState } from 'react';
import { DndServiceDiagnosisType } from './CodeEncounterDiagnosisServices';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import CodeEncounterDiagnosis from './CodeEncounterDiagnosis';
import { PatientTransactionItemDiagnosisType } from '@chiroup/core/types/PatientTransaction.type';

const bgColors = [' bg-gray-50', ''];

type Props = {
  diagnoses: DndServiceDiagnosisType[];
  onAddAllClicked?: (
    e: React.MouseEvent<HTMLDivElement>,
    val: PatientTransactionItemDiagnosisType,
  ) => void;
  readonly?: boolean;
  isBillingStarted?: boolean;
};

const CodeEncounterDiagnoses: React.FC<Props> = ({
  diagnoses,
  onAddAllClicked,
  readonly = false,
  isBillingStarted = false,
}) => {
  const [diagnosesWithId, setDiagnosesWithId] = useState<any>([]);

  useEffect(() => {
    setDiagnosesWithId(
      diagnoses.map((diagnosis) => ({
        id: diagnosis.data.code as string,
        ...diagnosis,
      })),
    );
  }, [diagnoses]);

  if (!diagnoses?.length) return null;

  return (
    <SortableContext
      id="diagnosis"
      items={diagnosesWithId}
      strategy={verticalListSortingStrategy}
    >
      {/* <pre>{ChiroUpJSON.pretty(diagnoses)}</pre> */}
      {diagnoses
        .sort((a: any, b: any) => {
          // Unused diags always on the bottom.
          const aord = a?.services?.length ? a.ord : 1000 + a.ord,
            bord = b?.services?.length ? b.ord : 1000 + b.ord;
          if (aord < bord) return -1;
          if (aord > bord) return 1;
          return 0;
        })
        .map((d, i) => (
          <CodeEncounterDiagnosis
            key={`${d.data.code}-${i}`}
            diagnosis={d}
            color={bgColors[i % 2]}
            onAddAllClicked={onAddAllClicked}
            readonly={readonly}
            isBillingStarted={isBillingStarted}
            parentIndex={i}
            parentLength={diagnoses.length}
          />
        ))}
    </SortableContext>
  );
};

export default CodeEncounterDiagnoses;
