import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { MeClinic } from '@chiroup/core/types/Me.type';

interface GreaterPlanResult {
  greaterPlan: string;
  isFutureDate: boolean;
  isPlanMatching: boolean;
}

export const enum MarketingPlan {
  Plus = 'plus',
  Premium = 'premium',
}

export const getGreaterPlan = (selectedClinic: MeClinic): GreaterPlanResult => {
  dayjs.extend(isSameOrAfter);
  const now = dayjs().endOf('day');
  const plusDate = selectedClinic.plusUntil
    ? dayjs(selectedClinic.plusUntil).endOf('day')
    : null;
  const premiumDate = selectedClinic.premiumUntil
    ? dayjs(selectedClinic.premiumUntil).endOf('day')
    : null;

  const isPlusFutureDate = plusDate?.isSameOrAfter(now) ?? false;
  const isPremiumFutureDate = premiumDate?.isSameOrAfter(now) ?? false;

  let greaterPlan;
  if (selectedClinic.plan === MarketingPlan.Premium || isPremiumFutureDate) {
    greaterPlan = MarketingPlan.Premium;
  } else if (isPlusFutureDate) {
    greaterPlan = MarketingPlan.Plus;
  } else {
    greaterPlan = selectedClinic.plan;
  }

  const isFutureDate = isPlusFutureDate || isPremiumFutureDate;
  const isPlanMatching = selectedClinic.plan === greaterPlan;

  return { greaterPlan, isFutureDate, isPlanMatching };
};
