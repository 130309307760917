import { titleCase } from '@chiroup/core/functions/titleCase';

type Props = {
  plan: string;
};
const PendingMessage: React.FC<Props> = ({ plan }) => (
  <span>
    <strong className="font-semibold">{`Thank you for upgrading to ${titleCase(
      plan,
    )}`}</strong>
    <svg
      viewBox="0 0 2 2"
      className="mx-2 inline fill-current h-0.5 w-0.5"
      aria-hidden="true"
    >
      <circle cx={1} cy={1} r={1} />
    </svg>
    Check back here for your materials within the next business day.&nbsp;
  </span>
);

export default PendingMessage;
