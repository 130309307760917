import { OpenClosedStates } from '@chiroup/components';
import React from 'react';
import Modal from '../common/Modal';
import UpgradeBilling from './UpgradeBilling';
import { Plan } from '@chiroup/core/types/Me.type';

type Props = {
  state: OpenClosedStates;
  setState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
  selectedPlan: Plan;
  isAdmin: boolean;
};

const MarketingBillingModal: React.FC<Props> = ({
  state,
  setState,
  selectedPlan,
  isAdmin,
}) => {
  return (
    <Modal
      isOpen={state === OpenClosedStates.Open}
      close={() => setState(OpenClosedStates.Closed)}
    >
      {isAdmin ? (
        <UpgradeBilling setState={setState} selectedPlan={selectedPlan} />
      ) : (
        <p>Only admin users can upgrade your account.</p>
      )}
    </Modal>
  );
};

export default MarketingBillingModal;
