import {
  Button,
  ButtonColors,
  ConfirmModal,
  OpenClosedStates,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import React, { useContext, useMemo, useState } from 'react';
import useDisciplines from '../../../../../components/settings/clinic/useDisciplines';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import { getDefaultValues } from '../helpers';
import useInsuranceList from '../hooks/useInsuranceList';
import PatientInsuranceBenefitForm from './PatientInsuranceBenefitForm';
import PatientInsuranceCard from './PatientInsuranceCard';
import PatientInsuranceFormFields from './PatientInsuranceFormFields';
import { MeContext } from '../../../../../contexts/me.context';
import { ClinicBillingSettings } from '@chiroup/core/types/ClinicBillingSettings.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import {
  Insurance,
  PatientInsuranceTypes,
} from '@chiroup/core/types/PatientInsurance.type';
import { UserRoles } from '@chiroup/core/types/User.type';

type Props = {
  editSlideOverState: OpenClosedStates;
  updateSlideOverState: React.Dispatch<React.SetStateAction<OpenClosedStates>>;
  patient: Partial<Patient>;
  close: () => void;
  setSelectedInsurance?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  primaryId?: string;
  secondaryId?: string;
  selectedInsurance?: string;
  claimDefaults?: ClinicBillingSettings;
  insurance: Partial<Insurance>;
  isFetching: boolean;
  del: (id: string) => Promise<void>;
  isDeleting: boolean;
  save: (val: any) => Promise<void>;
  isAddOnlyModal?: boolean;
};

const PatientInsuranceEditPanelInner: React.FC<Props> = ({
  updateSlideOverState,
  patient,
  close,
  setSelectedInsurance,
  selectedInsurance,
  insurance,
  del,
  isDeleting,
  save,
  claimDefaults,
  isAddOnlyModal = false,
}) => {
  const { hasRole } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const { data: disciplines } = useDisciplines();
  const [emailError, setEmailError] = useState<boolean>(false);
  const [deleteModalState, setDeleteModalState] = useState<boolean>(false);
  const { data: insuranceList } = useInsuranceList({
    patientId: patient?.ID || '',
  });

  const ids = useMemo(() => {
    return {
      primary: insuranceList?.data?.find(
        (insurance: Insurance) =>
          insurance.type === 'primary' && insurance.active,
      )?.id,
      secondary: insuranceList?.data?.find(
        (insurance: Insurance) =>
          insurance.type === 'secondary' && insurance.active,
      )?.id,
    };
  }, [insuranceList]);

  const validation = useMemo(() => {
    return {
      payor: {
        required: {
          message: 'Required.',
        },
      },
      memberId: {
        required: {
          message: 'Required.',
        },
      },
      name: {
        required: {
          message: 'Required.',
        },
      },
      active: {
        function: {
          value: (values: Partial<Insurance>) => {
            const { active, type } = values;
            if (!active) {
              return false;
            }
            if (
              active &&
              type === PatientInsuranceTypes.Primary &&
              ids.primary &&
              ids.primary !== insurance?.id
            ) {
              return 'A Patient may only have one active primary insurance. Please deactivate the other primary insurance before activating this one.';
            }
            if (
              active &&
              type === PatientInsuranceTypes.Secondary &&
              ids.secondary &&
              ids.secondary !== insurance?.id
            ) {
              return 'A Patient may only have one active secondary insurance. Please deactivate the other secondary insurance before activating this one.';
            }

            return false;
          },
        },
      },
    };
  }, [ids, insurance?.id]);

  const {
    onChange,
    registerSubmit,
    isSubmitting,
    errors,
    value,
    setValue,
    patchValue,
    setErrors,
  } = useForm<Insurance>(insurance, validation);

  const onSubmit = async (insurance: Partial<Insurance>) => {
    if (emailError) {
      return;
    }
    return save(insurance);
  };

  const onClickDelete = async (ins: Partial<Insurance>) => {
    setDeleteModalState(false);
    if (ins?.id) await del(ins.id || '');
    setSelectedInsurance?.('add');
    setValue(getDefaultValues(claimDefaults));
    setErrors({});
    close();
  };

  const onSuccess = (
    title = 'Success',
    description = 'The insurance has been saved.',
  ) => {
    createToast({
      title,
      description,
      type: ToastTypes.Success,
      duration: 5000,
    });
    close();
    if (selectedInsurance === 'add') {
      setValue(getDefaultValues(claimDefaults));
    }
  };

  const onFail = (description = 'Failed to save insurance!') => {
    createToast({
      title: 'Error!',
      description,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  return (
    <>
      <div className="flex flex-col justify-between">
        <form className="my-6 px-4 sm:px-4">
          <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-300">
            <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
              <PatientInsuranceFormFields
                value={value}
                patchValue={patchValue}
                onChange={onChange}
                errors={errors}
              />
            </div>
          </div>
          <PatientInsuranceCard
            patient={patient}
            value={value}
            onChange={onChange}
          />
          <PatientInsuranceBenefitForm
            value={value}
            patchValue={patchValue}
            onChange={onChange}
            disciplines={disciplines}
            setEmailError={setEmailError}
          />
        </form>

        <div className="flex-shrink-0 px-4 border-t border-gray-300 dark:border-darkGray-600 py-5 sm:px-6 dark:bg-darkGray-700">
          <div className="space-x-3 flex justify-end">
            <Button
              text="Close"
              onClick={() => {
                setSelectedInsurance?.('add');
                updateSlideOverState(OpenClosedStates.Closed);
                setValue(getDefaultValues(claimDefaults));
                setErrors({});
              }}
              color={ButtonColors.plain}
            />
            {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
              <>
                {!isAddOnlyModal && (
                  <Button
                    text="Delete"
                    onClick={() => setDeleteModalState(true)}
                    color={ButtonColors.red}
                    disabled={isSubmitting || isDeleting}
                    loading={isDeleting}
                  />
                )}
                <Button
                  text="Save"
                  onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
                  disabled={isSubmitting || isDeleting || emailError}
                  loading={isSubmitting}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={deleteModalState}
        confirm={() => onClickDelete(insurance)}
        close={() => setDeleteModalState(false)}
      />
    </>
  );
};

export default PatientInsuranceEditPanelInner;
