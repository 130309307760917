export type HealthcareProvider = {
  ID?: number;
  address?: string;
  city?: string;
  clinicID?: number;
  degree?: string;
  email?: string;
  facility: boolean;
  facilityName?: string;
  fax?: string;
  fname?: string;
  mname?: string;
  lastFirst?: string;
  lname?: string;
  suffix?: string;
  name?: string;
  mi?: string;
  notes?: string;
  phone?: string;
  practice?: string;
  speciality?: string;
  state?: string;
  title?: string;
  country?: string;
  zip?: string;
  npiNumber?: string;
  stateLicenseNumber?: string;
  commercialNumber?: string;
  upin?: number;
};

/**
 * How do we edit these fields no matter where they are used.
 *
 * @param val
 * @returns
 */
const twentyDigitAlpha = (val: string) => {
  return val
    .toLocaleUpperCase()
    .replace(/[^A-Z0-9-]+/g, '')
    .substring(0, 20);
};

export const ProviderEditMi = (val: string) => {
  return val.toUpperCase().substring(0, 1);
};

/**
 * Reference for format:
 *   https://www.cms.gov/medicare/regulations-guidance/administrative-simplification.
 *
 * @param val
 * @returns
 */
export const ProviderEditNpiNumber = (val: string) => {
  return val.replace(/\D/g, '').substring(0, 10);
};

export const ProviderEditStateLicenseNumber = twentyDigitAlpha;

export const ProviderEditCommercialNumber = twentyDigitAlpha;
