import { useSortable } from '@dnd-kit/sortable';
import React from 'react';
import { DndServiceDiagnosisType } from './CodeEncounterDiagnosisServices';
import { AddAllButton } from '@chiroup/components';
import { PatientTransactionItemDiagnosisType } from '@chiroup/core/types/PatientTransaction.type';
import { classNames } from '@chiroup/core/functions/classNames';

type Props = {
  diagnosis: DndServiceDiagnosisType;
  color: string;
  onAddAllClicked?: (
    e: React.MouseEvent<HTMLDivElement>,
    val: PatientTransactionItemDiagnosisType,
  ) => void;
  readonly?: boolean;
  isBillingStarted?: boolean;
  parentIndex: number;
  parentLength: number;
};

const CodeEncounterDiagnosis: React.FC<Props> = ({
  diagnosis,
  color,
  onAddAllClicked,
  readonly = false,
  isBillingStarted = false,
  parentIndex,
  parentLength,
}) => {
  const clickHandler = onAddAllClicked
    ? onAddAllClicked
    : (e: any, val: PatientTransactionItemDiagnosisType) => {
        console.warn('Make sure you add a click handler!', e);
      };

  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: String(diagnosis.data.code),
    data: {
      type: 'diagnosis',
    },
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  return (
    <div
      className={classNames(
        'overflow-hidden',
        isDragging ? 'opacity-50' : '',
        parentIndex === 0 ? `rounded-t-md` : '',
        parentIndex === parentLength - 1 ? `rounded-b-md` : '',
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <div className={classNames(`sm:grid sm:grid-cols-12 p-2`, color)}>
        <div className="ml-2 text-sm font-medium leading-5 sm:col-span-2 pr-4">
          {diagnosis.data.code}
        </div>
        <div className=" relative mt-1 flex text-sm leading-5 sm:col-span-10 sm:mt-0 mr-4">
          <span className="flex-grow pr-8">{diagnosis.data.description}</span>
          <span className="absolute right-0 top-0">
            <AddAllButton
              onClick={(e: any) => {
                if (readonly) return;
                clickHandler(e, diagnosis.data);
              }}
              title={`Add diagnosis ${diagnosis.data.code} to all services.`}
              disabled={readonly}
              isBillingStarted={isBillingStarted}
            />
          </span>
        </div>
        <div className="ml-2 text-sm font-medium leading-5 sm:col-span-2 text-gray-400 pr-4">
          Services
        </div>
        <div className="mt-1 flex text-sm leading-5 sm:col-span-10 sm:mt-0 mr-4">
          <span className="flex-grow">
            {diagnosis?.services?.length ? (
              diagnosis?.services?.join(', ')
            ) : (
              <cite className="text-gray-500">- none -</cite>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CodeEncounterDiagnosis;
