import {
  Discipline,
  DisciplineTreatment,
} from '@chiroup/core/types/Discipline.type';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import clinicService from '../../../services/clinic.service';

const query = () => {
  return async (context: QueryFunctionContext) => {
    const clinicId = Number(context.queryKey[1]);
    return clinicService.getDisciplines(clinicId);
  };
};

const useDisciplines = () => {
  const queryClient = useQueryClient();
  const meContext = useContext(MeContext);
  const { data, isFetching } = useQuery<Discipline[]>(
    ['disciplines', meContext?.me.selectedClinic?.ID],
    query(),
    {
      refetchOnWindowFocus: false,
      // staleTime = 24 hours
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  const addDiscipline = async (discipline: Partial<Discipline>) => {
    const res = await clinicService.addDiscipline(
      meContext?.me.selectedClinic?.ID,
      discipline,
    );

    if (res) {
      queryClient.setQueryData(['disciplines'], (data || []).concat(res));
    }

    return res;
  };
  const updateDiscipline = async (discipline: Partial<Discipline>) => {
    const res = await clinicService.updateDiscipline(
      meContext?.me.selectedClinic?.ID,
      discipline,
    );
    if (res) {
      queryClient.setQueryData(
        ['disciplines'],
        (data || []).map((item) => {
          if (item.ID === res.ID || item.ID === res.parentID) {
            return res;
          }
          return item;
        }),
      );
    }

    return res;
  };

  const saveDiscipline = async (discipline: Partial<Discipline>) => {
    if (discipline.ID) {
      return updateDiscipline(discipline);
    }
    return addDiscipline(discipline);
  };

  const addTreatment = async (treatment: Partial<DisciplineTreatment>) => {
    const res = await clinicService.addTreatment(
      meContext?.me.selectedClinic?.ID,
      treatment,
    );

    if (res) {
      queryClient.setQueryData(
        ['disciplines'],
        (data || []).map((item) => {
          if (item.ID === treatment.disciplineID) {
            item.treatments = (item.treatments || []).concat(res);
          }
          return item;
        }),
      );
    }

    return res;
  };

  const updateTreatment = async (
    treatment: Partial<DisciplineTreatment>,
    previousTreatment: Partial<DisciplineTreatment>,
  ) => {
    const res = await clinicService.updateTreatment(
      meContext?.me.selectedClinic?.ID,
      treatment,
    );

    if (res) {
      const disciplineChanged =
        previousTreatment.disciplineID !== treatment.disciplineID;
      queryClient.setQueryData(
        ['disciplines'],
        (data || []).map((discItem) => {
          if (
            discItem.ID === treatment.disciplineID ||
            discItem.parentID === treatment.disciplineID
          ) {
            // This is the discipline that the treatment is now in
            const treatmentIndex = (discItem.treatments || []).findIndex(
              (treatment) =>
                treatment.ID === res.ID || treatment.ID === res.parentID,
            );
            if (treatmentIndex > -1) {
              discItem.treatments[treatmentIndex] = res;
            } else {
              discItem.treatments = (discItem.treatments || []).concat(res);
            }
          }
          if (
            disciplineChanged &&
            discItem.ID === previousTreatment.disciplineID
          ) {
            // This is the discipline that the treatment was in before
            discItem.treatments = (discItem.treatments || []).filter(
              (treatment) => treatment.ID !== previousTreatment.ID,
            );
          }
          return discItem;
        }),
      );
    }

    return res;
  };

  const saveTreatment = async (
    treatment: Partial<DisciplineTreatment>,
    previousTreatment: Partial<DisciplineTreatment> | null,
  ) => {
    if (treatment.ID && previousTreatment) {
      return updateTreatment(treatment, previousTreatment);
    }
    return addTreatment(treatment);
  };

  return {
    data,
    isFetching,
    saveDiscipline,
    saveTreatment,
  };
};

export default useDisciplines;
