import {
  Autocomplete,
  DateTime,
  Input,
  NotFound,
  OpenClosedStates,
  Select,
  Textarea,
  getNearest,
  getNearestTime,
} from '@chiroup/components';
import { SubmitReturnType } from '@chiroup/hooks';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import Button, { ButtonColors } from '../common/Button';
import ClinicianSelect from '../common/fields/selects/ClinicianSelect';
import PatientCreateModal from '../patients/create/PatientCreateModal';
import usePatients from '../patients/hooks/usePatients';
import LegacyEhrUser from '../common/icons/LegacyEhrUser';
import useSurveys from '../../hooks/useSurveys';
import useConsents from '../practice-resources/hooks/useConsents';
import { transformPreferences } from '../settings/clinic/ClinicSettingsPatientPreferences';
import { PatientPreferencesForm } from '../settings/clinic/ClinicSettingsPatientPreferencesForm';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  ChiroUpAppointmentCommon,
  ChiroUpDayJsCommon,
} from '@chiroup/core/constants/stringConstants';
import { surveyIds } from '@chiroup/core/constants/SurveyIds';
import { CommunicationMethodTypes } from '@chiroup/core/enums/CommunicationMethodTypes.enum';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  AppointmentForUI,
  disableStatusesInPast,
} from '@chiroup/core/types/Appointment.type';
import {
  Discipline,
  DisciplineTreatment,
} from '@chiroup/core/types/Discipline.type';
import { FormErrors } from '@chiroup/core/types/ErrorResponse.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { durationDisplay } from '@chiroup/core/functions/durationDisplay';

type Props = {
  appointment?: AppointmentForUI | null;
  onClose: () => void;
  saveAppointment: ({
    appointment,
    notify,
    removeRecurringId,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean;
    removeRecurringId?: boolean;
  }) => Promise<AppointmentForUI | undefined>;
  timezone: string;
  newAppointment?: boolean;
  disabled?: boolean;
  editingOneOfRecurring?: boolean;
  setEditType?: (type: string) => void;
  setShowForm?: (show: boolean) => void;
  value: Partial<AppointmentForUI>;
  onChange: (key: keyof AppointmentForUI) => (val: any) => void;
  errors: FormErrors;
  isDirty: boolean;
  isSubmitting: boolean;
  setValue: (values: Partial<AppointmentForUI>) => void;
  registerSubmit: (
    fnSubmit: (
      values: Partial<AppointmentForUI>,
    ) => SubmitReturnType<AppointmentForUI, void> | undefined,
    {
      onSuccess,
      onFail,
    }: {
      onSuccess?: ((response: any) => void) | undefined;
      onFail?: ((errors: any) => void) | undefined;
    },
  ) => (e?: any) => void;
  patchValue: (values: Partial<AppointmentForUI>) => void;
  patient?: Patient;
  passedDisciplines?: Discipline[];
  startTimeInterval?: number;
  dontNavigate?: boolean;
};

const ScheduleAppointmentForm: React.FC<Props> = ({
  appointment,
  onClose,
  saveAppointment,
  timezone,
  newAppointment = false,
  disabled = false,
  editingOneOfRecurring = false,
  setEditType,
  setShowForm,
  onChange,
  errors,
  value,
  isDirty,
  isSubmitting,
  setValue,
  registerSubmit,
  patchValue,
  patient,
  passedDisciplines,
  startTimeInterval = 15,
  dontNavigate = false,
}) => {
  const [newPatientModalState, setNewPatientModalState] =
      useState<OpenClosedStates>(OpenClosedStates.Closed),
    queryClient = useQueryClient();
  const [localErrors, setLocalErrors] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const { disciplines } = useContext(ScheduleContext);
  const { me, hasAccess } = useContext(MeContext);
  const { search } = useLocation();
  const { selectedLocationFull } = useContext(MeContext);
  const [okayToSave] = useState(true);
  const [cancelClone, setCancelClone] = useState<AppointmentForUI>(
    {} as AppointmentForUI,
  );
  const { data: surveys } = useSurveys();
  const { data: consents } = useConsents();
  const navigate = useNavigate();
  const [requestInfoItems, setRequestInfoItems] = useState<any[]>();
  const [createdNewPatient, setCreatedNewPatient] = useState(false);

  const newPatientOpen = () => {
    setNewPatientModalState(OpenClosedStates.Open);
  };

  const newPatientClose = (patient: any) => {
    setNewPatientModalState(OpenClosedStates.Closed);
    if (patient) {
      queryClient.setQueryData(['patients', searchQuery], (old: any) => {
        return {
          ...old,
          pages: old.pages.map((page: any) => {
            return {
              ...page,
              data: [...page.data, patient],
            };
          }),
        };
      });
      patchValue({ patientId: patient.ID });
      setCreatedNewPatient(true);
    }
  };

  const {
    data,
    isFetching: isFetchingPatients,
    fetchNextPage,
    hasNextPage,
    onSearch,
    searchQuery,
    setSearchQuery,
  } = usePatients(false);
  const { createToast } = useContext(ToastContext);

  useEffect(() => {
    return () => {
      setCreatedNewPatient(false);
    };
  }, []);

  useEffect(() => {
    if (appointment) {
      patchValue(appointment);
    } else {
      const {
        treatment,
        clinician,
        startTime,
        startDate,
        duration,
        patient,
        patientName,
      } = qs.parse(search) as any;
      const apptSettings = me?.selectedClinic?.settings?.find(
        (setting) => setting.setting === 'Appointment Settings',
      );
      if (patientName && patientName?.length) {
        setSearchQuery(patientName);
      }
      patchValue({
        patientId: patient,
        treatmentId: treatment
          ? Number(treatment)
          : apptSettings?.jsonValue?.appointmentDefaultTreatment,
        clinicianId: clinician,
        startTime: dayjs.tz(
          startTime
            ? Number(startTime)
            : startDate
              ? getNearestTime(startDate)
              : getNearest(timezone),
          timezone,
        ),
        duration: duration
          ? Number(duration)
          : apptSettings?.jsonValue?.appointmentDefaultDuration,
      });
    }
  }, [
    appointment,
    patchValue,
    search,
    me?.selectedClinic?.settings,
    timezone,
    setSearchQuery,
  ]);

  useEffect(() => {
    if (patient) {
      if (!qs.parse(search).clinician && patient?.primaryClinician) {
        patchValue({
          clinicianId: patient?.primaryClinician,
        });
      }
    }
  }, [patient, patchValue, setSearchQuery, search]);

  useEffect(() => {
    if (patient) {
      setSearchQuery(`${patient?.lname}, ${patient?.fname}`);
      patchValue({
        patientId: patient?.ID,
      });
    }
  }, [patient, patchValue, setSearchQuery, search]);

  useEffect(() => {
    if (editingOneOfRecurring) {
      const clonedAppointment = JSON.parse(JSON.stringify(appointment));
      clonedAppointment.startTime = value.startTime;
      setCancelClone(clonedAppointment);
      setIsEditing(true);
    }
  }, [appointment, editingOneOfRecurring, value.startTime]);

  const patientInfo = useMemo(() => {
    const patientInfoArr = [
      { name: 'Basic intake group', group: 'Groups', id: 'basicIntakeGroup' },
      {
        name: 'Detailed intake group',
        group: 'Groups',
        id: 'detailedIntakeGroup',
      },
      {
        name: 'Photo ID',
        group: 'Intake & history surveys',
        id: 'requestPhotoId',
      },
      {
        name: 'Patient demographics',
        group: 'Intake & history surveys',
        id: 'requestInfo',
      },
    ];

    if (hasAccess(FeatureFlags.billingInsurance)) {
      patientInfoArr.push({
        name: 'Insurance',
        group: 'Intake & history surveys',
        id: 'requestInsurance',
      });
    } else {
      patientInfoArr.push({
        name: 'Insurance card',
        group: 'Intake & history surveys',
        id: 'requestInsuranceCard',
      });
    }

    return patientInfoArr;
  }, [hasAccess]);

  useEffect(() => {
    if (surveys && !requestInfoItems?.length) {
      const hiddenSurveys: string[] =
        me?.selectedClinic?.settings?.find(
          (clinic) => clinic.setting === 'Hidden surveys',
        )?.jsonValue || [];

      const filteredData: {
        name: string;
        group: string;
        id: string;
      }[] =
        surveys?.data?.filter((item) => !hiddenSurveys?.includes(item.id)) ||
        [];

      let items = [...(patientInfo || []), ...(filteredData || [])];
      if (consents?.length) {
        items = [
          ...items,
          ...(consents?.map((consent) => ({
            name: consent.name,
            group: 'Consents & Acknowledgement surveys',
            id: `consent::${consent.url}`,
          })) || []),
        ];
      }
      items.sort((a, b) => {
        if (a.group === 'Groups' && b.group !== 'Groups') {
          return -1;
        } else if (a.group !== 'Groups' && b.group === 'Groups') {
          return 1;
        }
        const groupComparison = a.group.localeCompare(b.group);
        if (groupComparison !== 0) {
          return groupComparison;
        }
        return a.name.localeCompare(b.name);
      });
      setRequestInfoItems(items);
    }
  }, [
    surveys,
    consents,
    me?.selectedClinic?.settings,
    patientInfo,
    requestInfoItems,
  ]);

  const flatPatients = useMemo(() => {
    return data?.pages?.map((page) => page?.data).flat() || [];
  }, [data]);

  const patientOptions = useMemo(() => {
    return flatPatients?.map((pt) => ({
      text: (
        <div className="flex">
          <div className="flex flex-col leading-4">
            <span>
              {pt.lname}, {pt.fname}
            </span>
            <span className="text-xs opacity-50">
              {pt.dateOfBirth
                ? ` ${dayjs(pt.dateOfBirth).format('MM/DD/YYYY')}`
                : ''}
            </span>
          </div>
          {!!pt?.legacyEHRId && <LegacyEhrUser />}
        </div>
      ),
      value: pt.ID,
    }));
  }, [flatPatients]);

  const onSubmit = async (values: Partial<AppointmentForUI>) => {
    if (disabled) return;
    if (
      value.policies?.length &&
      !value.policies?.every((policy) => policy.billingPriority)
    ) {
      return;
    }
    if (appointment?.recurringAppointmentId) {
      return saveAppointment({
        appointment: values,
        removeRecurringId: true,
      });
    }
    return saveAppointment({
      appointment: values,
    }).then((resp: AppointmentForUI | undefined) => {
      if (resp) patchValue(resp as AppointmentForUI);
      return resp;
    });
  };

  const onSubmitNotify = async (values: Partial<AppointmentForUI>) => {
    if (disabled) return;
    return saveAppointment({
      appointment: values,
      notify: true,
    });
  };

  const onSuccess = (val: any) => {
    setIsEditing(false);
    const searchParams = qs.parse(search);
    if (newAppointment) {
      if (val.id) {
        const params =
          searchParams.startDate && searchParams.endDate
            ? `/schedule?open=${val.id}&startDate=${searchParams.startDate}&endDate=${searchParams.endDate}`
            : `/schedule?open=${val.id}`;
        if (!dontNavigate) {
          navigate(`${params}`);
        } else {
          //Coming from appointment tab in patient
          onClose();
        }
      }
    }
  };

  const onFail = () => {
    createToast({
      type: ToastTypes.Fail,
      title: 'Error!',
      description: <>Failed to schedule appointment!</>,
    });
  };

  const treatmentHash = (passedDisciplines || disciplines)?.reduce(
    (arr: any, discipline) => {
      const options = discipline?.treatments?.map((treatment) => ({
        text: treatment.name,
        value: treatment.ID,
      }));

      const durations = discipline?.treatments?.map(
        (treatment) => treatment.treatmentDuration,
      );
      const treatments = discipline?.treatments?.reduce(
        (
          res: { [key: number]: DisciplineTreatment },
          treatment: DisciplineTreatment,
        ) => {
          return {
            ...res,
            [treatment.ID]: treatment,
          };
        },
        {},
      );
      return {
        options: [...arr.options, ...options],
        objs: { ...arr.objs, ...treatments },
        durations: [
          ...new Set([...arr.durations, ...durations, value.duration]),
        ].sort((a, b) => a - b),
      };
    },
    { options: [], objs: {}, durations: [] },
  );

  const disabledEvent = useMemo(() => {
    if (!appointment) return false;
    const inPast = appointment.startTime < dayjs();
    const disabledEvent =
      !!appointment.recurringAppointmentId ||
      (inPast && disableStatusesInPast.includes(appointment.status));
    return disabledEvent;
  }, [appointment]);

  const customIntakes = useMemo(() => {
    const customSettings = me.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Custom intakes',
    );
    return {
      basicIntakeGroup: customSettings?.jsonValue?.basicIntakeSurveys,
      detailedIntakeGroup: customSettings?.jsonValue?.detailedIntakeSurveys,
    };
  }, [me.selectedClinic?.settings]);

  const onChangeSurveys = useCallback(
    (val: string[]) => {
      if (val?.includes('basicIntakeGroup')) {
        if (customIntakes?.basicIntakeGroup) {
          val = val.concat(customIntakes.basicIntakeGroup);
        } else {
          val = val.concat([
            surveyIds.healthHistory,
            surveyIds.reviewOfSystems,
          ]);
        }
      }
      if (val?.includes('detailedIntakeGroup')) {
        if (customIntakes?.detailedIntakeGroup) {
          val = val.concat(customIntakes.detailedIntakeGroup);
        } else {
          val = val.concat([
            surveyIds.healthHistory,
            surveyIds.reviewOfSystems,
            surveyIds.lifestyleHistory,
            surveyIds.familyHistory,
            surveyIds.medicationHistory,
            surveyIds.surgicalHistory,
            'requestInfo',
          ]);
        }
      }

      val = val.filter(
        (surveyId) =>
          surveyId !== 'basicIntakeGroup' && surveyId !== 'detailedIntakeGroup',
      );
      onChange('surveys')(val);
    },
    [customIntakes, onChange],
  );

  const surveyOptions = useMemo(() => {
    return requestInfoItems?.map((survey) => ({
      text: survey.name,
      value: survey.id,
      group: survey.group,
    }));
  }, [requestInfoItems]);

  const patientPreferencesTranslations: { [key: string]: string } =
    useMemo(() => {
      return {
        allergySurvey: surveyIds.allergy,
        'Back Bournemouth Questionnaire': surveyIds.backBournemouth,
        lefs: surveyIds.lefs,
        'Neck Bournemouth Questionnaire': surveyIds.neckBournemouth,
        ndi: surveyIds.ndi,
        quickDash: surveyIds.dash,
        oswestry: surveyIds.oswestry,
        ccs: surveyIds.ccs,
        familyHistory: surveyIds.familyHistory,
        healthHistory: surveyIds.healthHistory,
        lifestyleHistory: surveyIds.lifestyleHistory,
        medicationHistory: surveyIds.medicationHistory,
        reviewOfSystems: surveyIds.reviewOfSystems,
        surgicalHistory: surveyIds.surgicalHistory,
        photoID: 'requestPhotoId',
        insurance: hasAccess(FeatureFlags.billingInsurance)
          ? 'requestInsurance'
          : 'requestInsuranceCard',
        newInsurance: 'requestInsurance',
        demographics: 'requestInfo',
      };
    }, [hasAccess]);

  useEffect(() => {
    if (me?.selectedClinic?.settings && createdNewPatient) {
      console.log({
        settings: me.selectedClinic?.settings,
        patientPreferencesTranslations,
      });
      const transformedSettings = Array.isArray(me.selectedClinic?.settings)
        ? transformPreferences(me.selectedClinic?.settings)
        : me.selectedClinic?.settings || {};
      console.log({ transformedSettings });
      const newPatientVals = Object.keys(patientPreferencesTranslations).reduce(
        (
          obj: {
            ccsFollowups?: string[];
            surveys?: string[];
            prefers?: CommunicationMethodTypes;
            requestInfo?: boolean;
            requestInsuranceCard?: boolean;
            requestInsurance?: boolean;
            requestPhotoId?: boolean;
          },
          setting: any,
        ) => {
          if (
            ['demographics', 'photoID', 'insurance'].includes(setting) &&
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            obj.surveys?.push(patientPreferencesTranslations[setting]);
          } else if (
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            const translation = patientPreferencesTranslations[setting];
            if (
              setting === 'ndi' ||
              setting === 'lefs' ||
              setting === 'quickDash' ||
              setting === 'oswestry'
            ) {
              obj.ccsFollowups?.push(translation);
            } else if (
              (translation && !hasAccess(FeatureFlags.ehr)) ||
              (translation &&
                translation !== surveyIds.ccs &&
                hasAccess(FeatureFlags.ehr))
            ) {
              obj.surveys?.push(translation);
            }
          }
          return obj;
        },
        { surveys: [] },
      );

      patchValue(newPatientVals);
    }
  }, [
    hasAccess,
    me.selectedClinic?.settings,
    patchValue,
    patientPreferencesTranslations,
    createdNewPatient,
  ]);

  return (
    <>
      <div
        className={classNames(
          'p-4 border border-gray-300 rounded-lg dark:border-darkGray-600 dark:bg-darkGray-900 dark:text-gray-300',
          disabled
            ? 'cursor-not-allowed'
            : disabledEvent
              ? 'cursor-not-allowed'
              : 'cursor-pointer hover:bg-gray-100 hover:border-gray-300 dark:hover:bg-darkGray-800 ',
          disabledEvent ? 'bg-orange-50 ' : 'bg-gray-50',
        )}
        title={disabledEvent ? ChiroUpAppointmentCommon.cannotModify : ''}
        onClick={() => {
          if (disabled || disabledEvent) return;
          const appointmentClone = JSON.parse(
            JSON.stringify(appointment || {}),
          );
          appointmentClone.startTime = value.startTime;
          setCancelClone(appointmentClone);
          setIsEditing(true);
        }}
      >
        {isEditing || newAppointment ? (
          <form
            onSubmit={registerSubmit(onSubmit, {
              onSuccess,
              onFail,
            })}
          >
            {!appointment?.id && (
              <Autocomplete
                name="patientId"
                label="Patient *"
                value={value.patientId}
                onChange={(val) => {
                  const fullPatient = flatPatients?.find(
                    (pt: Patient) => pt.ID === val,
                  );
                  if (fullPatient && !value.clinicianId) {
                    patchValue({
                      patientId: val,
                      clinicianId: fullPatient.primaryClinician,
                    });
                  } else if (fullPatient) {
                    patchValue({
                      patientId: val,
                    });
                  }
                }}
                options={patientOptions}
                limit={1}
                isFetching={isFetchingPatients}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                onChangeSearch={onSearch}
                searchTerm={searchQuery}
                errors={errors.fieldErrors?.patientId}
                addTitle="Create new patient..."
                add={newPatientOpen}
                autoFocus={!qs.parse(search)?.patient}
                serverSide
              />
            )}
            <ClinicianSelect
              name="clinicianId"
              value={value.clinicianId}
              onChange={onChange('clinicianId')}
              label="Clinician *"
              errors={errors.fieldErrors?.clinicianId}
              limit={1}
              disabled={appointment?.encounterSigned}
              tooltip={
                appointment?.encounterSigned
                  ? 'Provider cannot be changed after the note is signed'
                  : ''
              }
            />
            <Select
              name="treatmentId"
              label="Treatment *"
              value={value.treatmentId}
              onChange={(val) => {
                /**
                 * Business rule: If the chosen treatment has a duration, use that.
                 * Otherwise, use the duration from the appointment if it exists or
                 * punt. The implication here is that treatments without
                 * durations _may_ look like they do if a duration was set before
                 * the treatment was changed.
                 */
                patchValue({
                  treatmentId: val,
                  duration:
                    (treatmentHash.objs[val] || {}).treatmentDuration ||
                    value.duration,
                });
              }}
              options={treatmentHash?.options || []}
              limit={1}
              errors={errors.fieldErrors?.treatmentId}
            />
            <DateTime
              label="Date *"
              timeLabel="Start time *"
              value={value.startTime}
              onChange={onChange('startTime')}
              errors={errors.fieldErrors?.startTime}
              timezone={selectedLocationFull?.timezone || ''}
              localErrors={localErrors}
              setLocalErrors={setLocalErrors}
              startTimeInterval={startTimeInterval}
            />
            <div className="flex">
              {value.customDuration === 0 ? (
                <Select
                  name="duration"
                  label="Duration *"
                  value={value.duration}
                  onChange={onChange('duration')}
                  options={
                    treatmentHash?.durations.map((duration: number) => ({
                      text: `${duration} minutes`,
                      value: duration,
                    })) || []
                  }
                  limit={1}
                  errors={errors.fieldErrors?.duration}
                  className="min-w-1/3 w-full"
                  hint="Custom duration"
                  hintOnClick={() => onChange('customDuration')(1)}
                />
              ) : (
                <Input
                  name="duration"
                  label="Duration *"
                  value={value.duration}
                  onChange={onChange('duration')}
                  errors={errors.fieldErrors?.duration}
                  className="min-w-1/3 w-full"
                  type="number"
                  hint="in minutes"
                  min="0"
                />
              )}
            </div>
            {!!newAppointment && (
              <Select
                name="surveys"
                className="col-span-2"
                label="Request info"
                value={value.surveys}
                onChange={onChangeSurveys}
                errors={errors.fieldErrors?.surveys}
                options={surveyOptions}
                autocomplete
              />
            )}
            <Textarea
              name="notes"
              label="Notes"
              value={value.notes}
              onChange={onChange('notes')}
              errors={errors.fieldErrors?.notes}
            />
            {/* <AppointmentServices
              appointment={value}
              patchValue={patchValue}
              setOkayToSave={setOkayToSave}
            /> */}
            <hr className="bg-gray pb-4" />
            <div className="flex-shrink-0 pl-4 border-t border-gray-300  py-5  mt-4">
              <div className="space-x-3 flex justify-end">
                {!newAppointment && (
                  <Button
                    text="Cancel"
                    onClick={(event) => {
                      setShowForm?.(false);
                      setEditType?.('');
                      setIsEditing(false);
                      patchValue(cancelClone);
                      event.stopPropagation();
                    }}
                    color={ButtonColors.plain}
                  />
                )}

                <Button
                  text="Save"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !okayToSave ||
                    !!localErrors?.length
                  }
                  loading={isSubmitting}
                  type="submit"
                />
                <Button
                  text="Save & Notify"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !okayToSave ||
                    !!localErrors?.length
                  }
                  loading={isSubmitting}
                  type="button"
                  onClick={() => {
                    registerSubmit(onSubmitNotify, {
                      onSuccess,
                      onFail,
                    })();
                  }}
                />
              </div>
            </div>
          </form>
        ) : appointment ? (
          <div className="pb-5 pt-5 sm:pt-0">
            <dl className="space-y-8 sm:space-y-6">
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                  Clinician
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                  <p>{appointment?.displayValues?.clinicianName}</p>
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                  Treatment
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                  {appointment?.displayValues?.treatmentName}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                  Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                  {appointment?.startTime
                    ? appointment.startTime.format(
                        ChiroUpDayJsCommon.format.datetime,
                      )
                    : ''}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                  Duration
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                  {durationDisplay(appointment?.duration)}
                </dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-darkGray-200">
                  Notes
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                  {appointment?.notes}
                </dd>
              </div>
            </dl>
            {/* <AppointmentServices
              appointment={value}
              patchValue={patchValue}
              setOkayToSave={setOkayToSave}
              readonly={true}
            /> */}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
      <div className="text-right font-light text-xs text-black">
        {disabledEvent ? ChiroUpAppointmentCommon.cannotModify : ''}
      </div>
      <PatientCreateModal
        isOpen={newPatientModalState === OpenClosedStates.Open}
        close={newPatientClose}
        lastName={``}
        stayOnRoute={true}
        closeSearch={() => {}}
        appointmentPanel
      />
    </>
  );
};

export default ScheduleAppointmentForm;
