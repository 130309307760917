import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import { createDayjs } from '@chiroup/core/functions/time';
import {
  AppointmentForUI,
  Appointment,
  RecurringAppointmentData,
  AppointmentStatuses,
} from '@chiroup/core/types/Appointment.type';

dayjs.extend(timezone);

const getAppointmentsQuery = (
  timezone: string,
  clinicId: number,
  appointmentId: string | null,
) => {
  return async (context: QueryFunctionContext) => {
    if (!appointmentId) {
      return null;
    }
    return patientService.getAppointment(timezone, clinicId, appointmentId);
  };
};

const useAppointment = ({
  appointmentId,
  sessionId,
}: {
  appointmentId: string | null;
  sessionId: string;
}) => {
  const { me, selectedLocationFull } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);

  const queryClient = useQueryClient();
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const { status, data, error, isFetching, refetch } =
    useQuery<AppointmentForUI | null>(
      ['appointments', 'detail', appointmentId],
      getAppointmentsQuery(
        selectedLocationFull.timezone as string,
        me.selectedClinic?.ID ?? -1,
        appointmentId,
      ),
      {
        refetchOnWindowFocus: false,
      },
    );

  const saveAppointmentUI = (appointment: AppointmentForUI) => {
    queryClient.setQueryData(
      ['appointments', 'detail', appointmentId],
      appointment,
    );
    const listCache = queryClient
      .getQueryCache()
      .findAll(['appointments', 'list']);

    if (
      typeof appointment.startTime === 'string' ||
      typeof appointment.startTime === 'number'
    ) {
      // Came here from WS, so it is a string in the correct TZ
      appointment.startTime = createDayjs({
        datetime: appointment.startTime,
        timezone: selectedLocationFull.timezone as string,
      }) as Dayjs;
    }

    const day = appointment.startTime.format('YYYY-MM-DD');
    let cacheWithAppointment = -1;
    let previousDay: string | null = null;
    let previousClinicianId: string | null = null;
    let cacheWithNewDay = -1;
    if (listCache) {
      for (let i = 0; i < listCache.length; i++) {
        const item = listCache[i];
        const data = item.state.data as any;
        const dataAppointments = data || {};
        const days = Object.keys(dataAppointments || {});
        if (days.includes(day)) {
          cacheWithNewDay = i;
        }
        const itemEntries = Object.entries(dataAppointments || {}) as any;
        for (let j = 0; j < itemEntries.length; j++) {
          const [day, value] = itemEntries[j];
          const valueValues = Object.values(value);
          for (let k = 0; k < valueValues.length; k++) {
            const innerItem: any = valueValues[k];
            const appointments = innerItem?.appointments;
            if (appointments?.length) {
              for (let l = 0; l < appointments?.length; l++) {
                const appItem = appointments[l];
                if (appItem.id === appointment.id) {
                  cacheWithAppointment = i;
                  previousDay = day;
                  previousClinicianId = appItem.clinicianId;
                }
              }
            }
          }
        }
      }
    }

    try {
      if (cacheWithAppointment > -1 && previousDay && previousClinicianId) {
        setIsSubmitting(true);
        queryClient.setQueryData(
          listCache[cacheWithAppointment].queryKey,
          (prev: any) => {
            //in order for the dependency to work in Schedule component prev needs to have deep clone made and then the startTimes need to be made to be dayjs objects
            const newValue = JSON.parse(JSON.stringify(prev));
            //the day js objects need to be put on the newValue object
            Object.entries(
              prev as Record<string, Record<string, { appointments: any[] }>>,
            ).forEach(([day, value]) => {
              Object.entries(value).forEach(([clinicianId, value]) => {
                if (value?.appointments) {
                  newValue[day][clinicianId].appointments =
                    prev[day][clinicianId].appointments;
                }
              });
            });

            const newAppointments = newValue[previousDay as string][
              previousClinicianId as string
            ].appointments?.filter(
              (item: Appointment) => item.id !== appointment.id,
            );

            newValue[previousDay as string][
              previousClinicianId as string
            ].appointments = newAppointments || [];
            if (cacheWithNewDay === cacheWithAppointment) {
              const newAppointments = [
                ...newValue[day][appointment.clinicianId].appointments,
              ];
              newAppointments.push(appointment);
              newValue[day][appointment.clinicianId].appointments =
                newAppointments || [];
            }
            return newValue;
          },
        );
      }

      if (cacheWithNewDay > -1 && cacheWithNewDay !== cacheWithAppointment) {
        queryClient.setQueryData(
          listCache[cacheWithNewDay].queryKey,
          (prev: any) => {
            const newValue = JSON.parse(JSON.stringify(prev));
            Object.entries(
              prev as Record<string, Record<string, { appointments: any[] }>>,
            ).forEach(([day, value]) => {
              Object.entries(value).forEach(([clinicianId, value]) => {
                if (value?.appointments) {
                  newValue[day][clinicianId].appointments =
                    prev[day][clinicianId].appointments;
                }
              });
            });
            const newAppointments = [
              ...prev[day][appointment.clinicianId].appointments,
            ];
            newAppointments.push(appointment);
            newValue[day][appointment.clinicianId].appointments =
              newAppointments || [];
            return newValue;
          },
        );
      }
      queryClient.invalidateQueries(['dashboardAppointments']);
    } catch (err) {
      console.error({ err });
    } finally {
      setIsSubmitting(false);
      setIsChangingStatus(false);
    }
  };

  const checkRecurringAvailability = async (
    appointmentUsedToCreateRecurringTimeStamp: number,
    appointment: Partial<AppointmentForUI>,
  ) => {
    const res = await patientService.checkRecurringAvailability(
      appointmentUsedToCreateRecurringTimeStamp,
      me.selectedClinic?.ID || -1,
      appointment,
      me.selectedLocation || -1,
    );
    return res;
  };
  const updateRecurringAppointments = async (
    dataToSend: RecurringAppointmentData,
    originalAppointment: AppointmentForUI,
    locationId: number,
    sessionId: string,
    notify: boolean,
  ) => {
    const res = await patientService.updateRecurringAppointments(
      dataToSend,
      originalAppointment,
      me.selectedClinic?.ID || -1,
      sessionId,
      locationId,
      notify,
    );

    queryClient.refetchQueries(['appointments', 'list']);
    return res;
  };

  const saveRecurringAppointments = async (
    dataToSend: RecurringAppointmentData,
    locationId: number,
    sessionId: string,
    notify: boolean,
  ) => {
    const res = await patientService.createRecurringAppointments(
      dataToSend,
      me.selectedClinic?.ID || -1,
      sessionId,
      locationId,
      notify,
    );

    queryClient.refetchQueries(['appointments', 'list']);
    queryClient.invalidateQueries(['dashboardAppointments']);
    return res;
  };

  const deleteRecurringAppointments = async (appointment: AppointmentForUI) => {
    const res = await patientService.deleteRecurringAppointments(
      appointment,
      me.selectedClinic?.ID || -1,
      sessionId,
    );
    queryClient.refetchQueries(['appointments', 'list']);
    return res;
  };

  const deleteAppointmentUI = (appointment: {
    id: string;
    startTime: Dayjs;
    clinicianId: string;
  }) => {
    queryClient.setQueryData(
      ['appointments', 'detail', appointmentId],
      undefined,
    );
    const listCache = queryClient
      .getQueryCache()
      .findAll(['appointments', 'list']);

    if (
      typeof appointment.startTime === 'string' ||
      typeof appointment.startTime === 'number'
    ) {
      // Came here from WS, so it is a string in the correct TZ
      appointment.startTime = createDayjs({
        datetime: appointment.startTime,
        timezone: selectedLocationFull.timezone as string,
      }) as Dayjs;
    }

    const day = appointment?.startTime?.format('YYYY-MM-DD');

    let cacheWithAppointment = -1;
    let previousDay: string | null = null;
    let previousClinicianId: string | null = null;
    let cacheWithNewDay = -1;
    if (listCache) {
      for (let i = 0; i < listCache.length; i++) {
        const item = listCache[i];
        const data = item.state.data as any;
        const dataAppointments = data || {};
        const days = Object.keys(dataAppointments || {});
        if (days.includes(day)) {
          cacheWithNewDay = i;
        }
        const itemEntries = Object.entries(dataAppointments || {}) as any;
        for (let j = 0; j < itemEntries.length; j++) {
          const [day, value] = itemEntries[j];
          const valueValues = Object.values(value);
          for (let k = 0; k < valueValues.length; k++) {
            const innerItem: any = valueValues[k];
            const appointments = innerItem.appointments;
            if (appointments) {
              for (let l = 0; l < appointments.length; l++) {
                const appItem = appointments[l];
                if (appItem.id === appointment.id) {
                  cacheWithAppointment = i;
                  previousDay = day;
                  previousClinicianId = appItem.clinicianId;
                }
              }
            }
          }
        }
      }
    }
    if (cacheWithAppointment > -1 && previousDay && previousClinicianId) {
      queryClient.setQueryData(
        listCache[cacheWithAppointment].queryKey,
        (prev: any) => {
          const newAppointments = prev[previousDay as string][
            previousClinicianId as string
          ].appointments?.filter(
            (item: Appointment) => item.id !== appointment.id,
          );
          prev[previousDay as string][
            previousClinicianId as string
          ].appointments = newAppointments || [];
          if (cacheWithNewDay === cacheWithAppointment) {
            const newAppointments = prev[day][
              appointment.clinicianId
            ].appointments?.filter(
              (item: Appointment) => item.id !== appointment.id,
            );
            prev[day][appointment.clinicianId].appointments =
              newAppointments || [];
          }
          return { ...prev };
        },
      );
    }
    if (cacheWithNewDay > -1 && cacheWithNewDay !== cacheWithAppointment) {
      queryClient.setQueryData(
        listCache[cacheWithNewDay].queryKey,
        (prev: any) => {
          const newAppointments = prev[day][
            appointment.clinicianId
          ].appointments?.filter(
            (item: Appointment) => item.id !== appointment.id,
          );
          prev[day][appointment.clinicianId].appointments =
            newAppointments || [];
          return { ...prev };
        },
      );
    }
    queryClient.invalidateQueries(['dashboardAppointments']);
  };

  const saveAppointment = async ({
    appointment,
    notify,
    removeRecurringId,
    fee,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean;
    removeRecurringId?: boolean;
    fee?: {
      amount: number;
      subtype: string;
      description: string;
      name: string;
    };
  }) => {
    if (!selectedLocationFull?.timezone) return;
    const newAppointment = await (appointment.id
      ? patientService.updateAppointment(
          me.selectedClinic?.ID,
          sessionId,
          appointment,
          null,
          notify,
          removeRecurringId,
          fee,
        )
      : patientService.addAppointment(
          me.selectedClinic?.ID,
          me.selectedLocation,
          sessionId,
          appointment,
          notify,
        ));

    if (appointment.deleted) {
      deleteAppointmentUI({
        id: appointment.id as string,
        startTime: appointment.startTime as Dayjs,
        clinicianId: appointment.clinicianId as string,
      });
    } else {
      saveAppointmentUI(newAppointment);
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        newAppointment,
      );
    }
    if (fee) {
      queryClient.setQueryData(['transaction', appointmentId], (prev: any) => {
        return {
          ...prev,
          items: [fee],
        };
      });
    }
    queryClient.invalidateQueries(['dashboardAppointments']);
    return newAppointment;
  };

  const approvePatient = async () => {
    if (!data) return;
    try {
      const res = await patientService.approve(
        me.selectedClinic?.ID || -1,
        data.patientId,
      );
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        (prev: Appointment | undefined) => {
          if (!prev) return {} as Appointment;
          return {
            ...prev,
            patientId: res.ID,
            displayValues: {
              ...prev.displayValues,
              pendingPatient: false,
            },
          };
        },
      );
    } catch (err) {
      console.error({ err });
    }
  };

  const checkIn = async () => {
    if (!appointmentId || !selectedLocationFull?.timezone) return;
    setIsCheckingIn(true);
    try {
      const newAppointment = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          id: appointmentId,
          status: AppointmentStatuses.CheckedIn,
        },
      );
      saveAppointmentUI(newAppointment);
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        newAppointment,
      );
      setIsCheckingIn(false);
      queryClient.invalidateQueries(['dashboardAppointments']);
      return newAppointment;
    } catch (err) {
      setIsCheckingIn(false);
      throw err;
    }
  };

  const markAsScheduled = async () => {
    if (!appointmentId || !selectedLocationFull?.timezone) return;
    try {
      setIsChangingStatus(true);
      const newAppointment = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          id: appointmentId,
          status: AppointmentStatuses.Scheduled,
        },
      );
      saveAppointmentUI(newAppointment);
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        newAppointment,
      );
      queryClient.invalidateQueries(['dashboardAppointments']);
      queryClient.invalidateQueries(['transaction', appointmentId]);

      return newAppointment;
    } catch (err: any) {
      createToast({
        title: 'Error',
        description: err?.response?.data?.message || 'Error saving appointment',
        type: ToastTypes.Fail,
        duration: 5000,
      });
    }
  };

  const markAsNoShow = async (fee?: {
    amount: number;
    subtype: string;
    description: string;
    name: string;
  }) => {
    if (!appointmentId || !selectedLocationFull?.timezone) return;
    const newAppointment = await patientService.updateAppointment(
      me.selectedClinic?.ID,
      sessionId,
      {
        id: appointmentId,
        status: AppointmentStatuses.NoShow,
      },
      null,
      false,
      false,
      fee,
    );
    saveAppointmentUI(newAppointment);
    queryClient.setQueryData(
      ['appointments', 'detail', appointmentId],
      newAppointment,
    );
    queryClient.invalidateQueries(['dashboardAppointments']);
    queryClient.invalidateQueries(['transaction', appointmentId]);
    return newAppointment;
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    saveAppointment,
    checkRecurringAvailability,
    checkIn,
    markAsScheduled,
    isCheckingIn,
    markAsNoShow,
    approvePatient,
    saveRecurringAppointments,
    deleteRecurringAppointments,
    updateRecurringAppointments,
    isSubmitting,
    isChangingStatus,
  };
};

export default useAppointment;
