import {
  Input,
  Phone,
  RadioGroup,
  Select,
  Textarea,
  ZipCodeInput,
} from '@chiroup/components';
import React, { useContext, useEffect, useState } from 'react';
import {
  COUNTRIES,
  DEGREES,
  MEDICAL_SPECIALTIES,
  SUFFIX,
  TITLES,
} from '../../../../../constants';
import { MeContext } from '../../../../../contexts/me.context';
import { COUNTRY_CODES } from '@chiroup/core/constants/countries';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  CANADIAN_PROVINCES,
  STATES,
} from '@chiroup/core/constants/UsStatesAndTerritories';
import { FormFieldErrors } from '@chiroup/core/types/ErrorResponse.type';
import {
  HealthcareProvider,
  ProviderEditMi,
  ProviderEditNpiNumber,
  ProviderEditStateLicenseNumber,
  ProviderEditCommercialNumber,
} from '@chiroup/core/types/HealthcareProvider.type';
import { ValueOf } from '@visx/scale';

type Props = {
  fieldErrors?: FormFieldErrors;
  value: Partial<HealthcareProvider>;
  onChange: (
    key: keyof Partial<HealthcareProvider>,
  ) => (val: ValueOf<HealthcareProvider>) => void;
  patchValue: (values: Partial<HealthcareProvider>) => void;
  formErrors?: { message: string }[];
  providerOnly?: boolean;
};

const HealthcareProviderGeneralFormElements: React.FC<Props> = ({
  fieldErrors,
  value,
  onChange,
  patchValue,
  formErrors,
  providerOnly,
}) => {
  const { hasAccess } = useContext(MeContext);
  const [specialtyDisabled, setSpecialtyDisabled] = useState(false);
  const { me } = useContext(MeContext);

  useEffect(() => {
    if (value.degree && !['MD', 'DO', 'NP', 'PA'].includes(value.degree)) {
      setSpecialtyDisabled(true);
      patchValue({ speciality: '' });
    } else {
      setSpecialtyDisabled(false);
    }
  }, [value.degree, patchValue]);

  useEffect(() => {
    if (!Object.keys(value).includes('facility')) {
      patchValue({ facility: false });
    }
  }, [value, patchValue]);

  const onTypeChange = (facility: string) => {
    if (facility === 'false') {
      patchValue({
        facility: false,
        facilityName: '',
        country: value?.country ? value.country : 'USA',
      });
    } else {
      patchValue({
        facility: true,
        fname: '',
        lname: '',
        title: '',
        degree: '',
        speciality: '',
        email: '',
        country: value?.country ? value.country : 'USA',
      });
    }
  };

  return (
    <>
      <>
        {!providerOnly && (
          <RadioGroup
            className="col-span-4"
            name="type"
            options={[
              { text: 'Facility', value: true },
              { text: 'Provider', value: false },
            ]}
            onChange={onTypeChange}
            value={value.facility || false}
          />
        )}

        {value.facility && (
          <Input
            name="facilityName"
            className="col-span-4"
            label="Facility name *"
            value={value.facilityName}
            onChange={onChange('facilityName')}
            errors={fieldErrors?.facilityName}
          />
        )}
      </>
      {!value.facility && (
        <>
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-12 sm:gap-4 col-span-4">
            <Input
              name="fname"
              className="col-span-4"
              label="First name *"
              value={value.fname}
              onChange={onChange('fname')}
              errors={fieldErrors?.fname}
            />
            <Input
              name="mi"
              className="col-span-2"
              label="M. I."
              value={value.mi}
              onChange={(val: string) => {
                onChange('mi')(ProviderEditMi(val));
              }}
              labelTitle="Middle Initial"
              errors={fieldErrors?.mi}
            />
            <Input
              name="lname"
              className="col-span-6"
              label="Last name *"
              value={value.lname}
              onChange={onChange('lname')}
              errors={fieldErrors?.lname}
            />
          </div>
          <Select
            name="suffix"
            className="col-span-2"
            label="Suffix"
            value={value.suffix}
            onChange={onChange('suffix')}
            errors={fieldErrors?.suffix}
            options={SUFFIX}
            limit={1}
          />
          <Select
            name="title"
            className="col-span-2"
            label="Title"
            value={value.title}
            onChange={onChange('title')}
            errors={fieldErrors?.title}
            options={TITLES}
            limit={1}
          />
          <Select
            name="degree"
            className="col-span-2"
            label="Degree"
            value={value.degree}
            onChange={onChange('degree')}
            errors={fieldErrors?.degree}
            options={DEGREES}
            limit={1}
            native
          />
          <Select
            name="speciality"
            className="col-span-2"
            label="Specialty"
            value={value.speciality}
            onChange={onChange('speciality')}
            errors={fieldErrors?.speciality}
            options={MEDICAL_SPECIALTIES}
            limit={1}
            disabled={specialtyDisabled}
            native
          />
          {hasAccess(FeatureFlags.ehr) && (
            <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-12 sm:gap-4 col-span-4">
              <Input
                name="npiNumber"
                className="col-span-6"
                label="NPI number"
                value={value.npiNumber}
                onChange={(val: string) => {
                  onChange('npiNumber')(ProviderEditNpiNumber(val));
                }}
                errors={fieldErrors?.npiNumber}
              />
              <Input
                name="stateLicenseNumber"
                className="col-span-6"
                label="State license number"
                value={value.stateLicenseNumber}
                onChange={(val: string) => {
                  onChange('stateLicenseNumber')(
                    ProviderEditStateLicenseNumber(val),
                  );
                }}
                errors={fieldErrors?.stateLicenseNumber}
              />
              <Input
                name="commercialNumber"
                className="col-span-6"
                label="Commercial number"
                value={value.commercialNumber}
                onChange={(val: string) => {
                  onChange('commercialNumber')(
                    ProviderEditCommercialNumber(val),
                  );
                }}
                errors={fieldErrors?.commercialNumber}
              />
              <Input
                name="upin"
                className="col-span-6"
                label="UPIN"
                maxLength={6}
                value={value.upin}
                onChange={onChange('upin')}
                errors={fieldErrors?.upin}
              />
            </div>
          )}
          <Input
            name="email"
            className="col-span-4"
            label="Email"
            value={value.email}
            onChange={onChange('email')}
            errors={fieldErrors?.email}
          />
        </>
      )}
      <Input
        name="address"
        className="col-span-4"
        label="Address"
        value={value.address}
        onChange={onChange('address')}
        errors={fieldErrors?.address}
      />
      <Input
        className="col-span-2"
        name="city"
        label="City"
        value={value.city}
        onChange={onChange('city')}
        errors={fieldErrors?.city}
      />
      {['USA', 'CAN'].includes(value?.country || '') ? (
        <Select
          name="state"
          className="col-span-3"
          label={`${value?.country === 'CAN' ? 'Province' : 'State'}`}
          value={value.state}
          onChange={onChange('state')}
          errors={fieldErrors?.state}
          options={value?.country === 'CAN' ? CANADIAN_PROVINCES : STATES}
          limit={1}
          autocomplete
        />
      ) : (
        <Input
          name="state"
          className="col-span-3"
          label="State/Province"
          value={value.state}
          onChange={onChange('state')}
          errors={fieldErrors?.state}
        />
      )}
      <ZipCodeInput
        className="col-span-2"
        name="zip"
        label="Zip/Post code"
        value={value.zip}
        onChange={onChange('zip')}
        errors={fieldErrors?.zip}
        country={value.country ?? ''}
      />
      <Select
        name="country"
        className="col-span-2"
        label="Country"
        options={COUNTRIES}
        value={value.country}
        onChange={onChange('country')}
        errors={fieldErrors?.country}
        limit={1}
        autocomplete
      />
      <Phone
        name="phone"
        className="col-span-2"
        label="Phone"
        value={value.phone}
        onChange={onChange('phone')}
        errors={fieldErrors?.phone}
        countryCode={COUNTRY_CODES[me.selectedClinic?.country || 'USA']}
      />
      <Phone
        name="fax"
        className="col-span-2"
        label="Fax"
        value={value.fax}
        onChange={onChange('fax')}
        errors={fieldErrors?.fax}
        countryCode={COUNTRY_CODES[me.selectedClinic?.country || 'USA']}
      />

      {!value.facility && (
        <Input
          className="col-span-4"
          name="practice"
          label="Practice name"
          value={value.practice}
          onChange={onChange('practice')}
          errors={fieldErrors?.practice}
        />
      )}
      <Textarea
        className="col-span-4"
        name="notes"
        label="Notes"
        value={value.notes}
        onChange={onChange('notes')}
        errors={fieldErrors?.notes}
      />
      {!!formErrors?.length && (
        <div className="col-span-4">
          {formErrors.map((error, i) => (
            <div key={i} className="text-red-500">
              {error.message}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default HealthcareProviderGeneralFormElements;
