import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';

const patientUploadService = () => {
  const upload = async ({
    clinicId,
    patientId,
    file,
  }: {
    clinicId?: number;
    patientId?: string;
    file?: any;
  }) => {
    const type = file.type.split('/')[1];
    const res = await ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/patients/${patientId}/upload?fileType=${type}`,
      {},
    );

    const form = new FormData();
    Object.entries(res.fields).forEach(([key, val]: any) => {
      form.append(key, val);
    });
    form.append('file', file);
    // This doesn't get a response...
    await fetch(res.url, { method: 'POST', body: form });

    return res.fields.key;
  };

  return {
    upload,
  };
};

export default patientUploadService();
